import { React, useState,useEffect } from 'react'
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { message } from 'antd';
import AccountMenu from '../Ui/AccountDropDownUi'
import { SettingsReducer } from "../../../redux/Setting"
const SettingActions = SettingsReducer.actions;

export default function AccountDropDown(props) {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const selectedTranslations = useSelector((state) => state.settings.selectedTranslations);

    const handleClickListItem = (event) => {
        if (anchorEl != null){
            handleClose();
        }
        else
        {
          setAnchorEl(event.currentTarget);
        }
    };

    const handleClickAway = () => {
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
          console.log("firebase.auth():",firebase.auth())
            await firebase.auth().signOut();
            const selectedTranslationsWithFirst2Values = selectedTranslations.slice(0, 2);
            dispatch(SettingActions.resetSettings());//Reset all redux state to initial values
            dispatch(SettingActions.updateSettings({ selectedTranslations: selectedTranslationsWithFirst2Values }));
            dispatch(SettingActions.updateSettings({ firebaseToken: null }));
            navigate("/");
            message.success(props.t('logged-out-successfully'));
        } catch (error) {
            message.warning(props.t('error-in-logging-out'));
        }
    };

      useEffect(() => {
        const handleClickOutside = (e) => {
          if (anchorEl && !e.target.closest('.clickAway')) {
            handleClose();
          }
        };
    
        window.addEventListener('click', handleClickOutside);
        return () => {
          window.removeEventListener('click', handleClickOutside);
        };
      }, [anchorEl]);

    return (
        <AccountMenu nname="Ammar Siddique bin Kousar Mahmood Siddique" t={props.t} anchorEl={anchorEl} handleClickListItem={handleClickListItem} 
        handleClickAway={handleClickAway} handleLogout={handleLogout} user={props.user} navigate={props.navigate} />

    )
}
