import  { React ,useState } from "react";
import { useSelector } from "react-redux";
import RightMenuUi from "../UI/RightMenuUi"
import HistoryIcon from "../../../assets/images/HistoryIcon.svg";
import HistoryIconWhite from "../../../assets/images/HistoryIconWhite.svg";
import AppearanceIcon from "../../../assets/images/AppearanceIcon.svg";
import AppearanceIconWhite from "../../../assets/images/AppearanceIconWhite.svg";
import SettingIcon from "../../../assets/images/SettingIcon.svg";
import SettingIconWhite from "../../../assets/images/SettingIconWhite.svg";

function RightMenu(props) {
  
  const [selectedMenu, setSelectedMenu] = useState("");
  const userTheme = useSelector((state) => state.settings.userTheme);
  const historyIcon = userTheme === 'Dark' ? HistoryIconWhite : HistoryIcon;
  const appearanceIcon = userTheme === 'Dark' ? AppearanceIconWhite : AppearanceIcon;
  const settingIcon = userTheme === 'Dark' ? SettingIconWhite : SettingIcon;

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  const handleClickAway_CloseMenu = () => {
    setSelectedMenu("");
  };


  return (
   <RightMenuUi  t={props.t} handleClickAway_CloseMenu={handleClickAway_CloseMenu} handleMenuClick={handleMenuClick} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}
   historyIcon={historyIcon} appearanceIcon={appearanceIcon} settingIcon={settingIcon}  />
   );
}

export default RightMenu;
