import React from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FooterUi from "../Ui/FooterUi"
import instaLight from '../../../assets/images/instagram-footer-light.svg'
import instaDark from '../../../assets/images/instagram-footer.svg'
import facebookLight from '../../../assets/images/facebook-footer-light.svg'
import facebookDark from '../../../assets/images/facebook-footer.svg'

export default function FooterCode() {
  const { t } = useTranslation();
  const userTheme = useSelector((state) => state.settings.userTheme);
  const facebookIcon = userTheme === 'Dark' ? facebookLight :facebookDark ;
  const instagramIcon = userTheme === 'Dark' ?  instaLight : instaDark;
  return (
    <FooterUi t={t} facebookIcon={facebookIcon} instagramIcon={instagramIcon}/>
  );
}

