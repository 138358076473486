import React from 'react'
import PlusWhite from "../../../assets/images/plus_white.684d7bf6.svg";
export default function AddNewCollectionmodal(props) {
    return (
  
        <div className="modal show" onClick={(e) => e.target.classList.contains('modal') && props.onClose()} tabIndex="-1">
            <div className="dark__overlay" onClick={(e) => e.target.classList.contains('dark__overlay') && props.onClose()} />
            <div className={` modal-dialog modal-dialog-centered   justify-content-center align-items-center'}`} >
                <div className={`border-white-color modal-content round-collection dark-mode-modal-border`} style={{width: '40.46875vw', height:'51.6302521vh'}}>
                    <div className={`darkBackground modal-header round-collection-header  dark-mode-modal-border-bottom-without-blue-background  `}>
                        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '3rem' }}>
                            <label className={`text-white-black-theme model-title-text  text-uppercase align-self-center `} >
                            {props.t('add-new-collection')}
                            </label>
                            <img className=' model-cross-icon align-self-center ' alt='' onClick={() => props.onClose()} src={props.crossIcon} />
                        </div>
                    </div>
                    <div className={`darkBackground modal-body round-collection`} >
                    <div className="d-flex flex-column mt-3 padding-x-4 ">
                            <label className={`text-white-black-theme font-size-14-400 text-capitalize mb-2 px-2 `} >
                                {props.t('type-collection-name')}
                            </label>
                            <input
                                className="  add_new_collection_name_input font-size-14-400 px-2 w-100"
                                placeholder={props.t('type-here')}
                                type="text"
                                value={props.inputValue}
                                onChange={props.handleInputChange}
                                required
                            />
                            </div>
                            <div className="d-flex flex-column ">

                            <div className="d-flex flex-row mt-4">
                                {props.colorOptions.map((bgColor, index) => (
                                    <div
                                        key={index + 'C'}
                                        className={`collection_color cursor_pointer m-left-1 e ${props.selectedColorIndex === index && 'collection_color_border'} `}
                                        style={{ background: bgColor }}
                                        onClick={() => props.setSelectedColorIndex(index)}
                                    />
                                ))}
                            </div>
                            <button
                                onClick={
                                    props.clickedCollection   ? Object.keys(props.clickedCollection).length === 8
                                    ? props.UpdateCollection
                                    : props.SaveNewCollection
                                    : props.SaveNewCollection
                                }                                
                                type="button"
                                className={`btn_add_collection align-self-center m-0 mt-4 text-capitalize cursor_pointer  model_button`}

                            >
                                <img
                                    alt=''
                                    className=" align-self-center mr-2"
                                    src={PlusWhite}
                                />
                                    {props.clickedCollection  && Object.keys(props.clickedCollection).length === 8
                                    ? props.t('update-collection')
                                    : props.t('add-new-collection')}
                            </button>
                        </div>
                    </div>

                    <div className={`darkBackground modal-footer `} style={{borderTop: 'unset'}}>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}
