import React, { forwardRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

//forwardRef, For calling child function in Parent component
const RecitationComponent = forwardRef((props, ref) => {

  return (
      <div className={`mb-auto audio_footer text-center darkBackground `}  >
        <div className='row ml-l-small-device '>
          <div className="col-1">
            {props.recitations && props.recitations.length > 0 && (
              <ReactAudioPlayer
                key={props.audioSrc}
                ref={props.audioRef}
                id="audioPlayer"
                autoPlay={!props.pauseThis}
                onPlay={() => props.playVerse(props.playingSurah,props.playingVerse, true,false)}
                onPause={() => props.Pause()}
                onEnded={() => {
                  if ((props.shuffle === 2 || props.shuffle === 3) && ((props.playingVerse === props.recitations.length && parseInt(props.selectedSurah) < 114) || (parseInt(props.selectedSurah) >1 && parseInt(props.selectedSurah) <= 114 && props.playingVerse===1111) )) {
                    let src = props.firstVerse.filter(item => item.verseNo === 1 && item.chapNo === 1);
                    if (src) {
                      props.setAudioSrcToTHis(src[0].url);
                    }
                  else if ((props.shuffle === 2 || props.shuffle === 3) && props.playingVerse === props.recitations.length) {
                    props.playNextVerse();
                  } 
                  } else {
                    props.playNextVerse();

                  }
                  
                }}
                src={props.audioSrc}
              // onError={(error) => {
              //   console.log("error in ReactAudioPlayer ");
              // }}
              />
            )}
          </div>
          <div className="col-1">
            <img
              className="verse_option_icon cursor_pointer mr-3"
              src={props.shuffle_Icon}
              alt=""
              aria-describedby="popup-37"
              onClick={() => props.setOpenShuffle(o => !o)}

            />
          </div>
          <div className="col-1">
            <img
              className="verse_option_icon cursor_pointer mx-3"
              src={props.movePrevious}
              onClick={() => props.playPreviousVerse()}
              alt=""
            />
          </div>
          <div className="col-1">
            <img
              className="verse_option_icon cursor_pointer mx-3"
              onClick={() => props.playVerse(props.playingSurah,props.playingVerse, !props.playState,false)}
              src={ props.playState ? props.pauseBlackWhite : props.playBlackWhite }
              id="playThisVerse"
              alt=""
            />
          </div>
          <div className="col-1">

            <img
              className="verse_option_icon cursor_pointer mx-3"
              onClick={() => props.playNextVerse()}
              src={props.moveNext}
              alt=""
            />
          </div>

          <div
            className=" col-7 d-flex flex-row cursor_pointer reciter-col"
            aria-describedby="popup-38"
            onClick={() => props.setOpen(o => !o)} 
          >
            <label className={`font-size-14-400 text-pink-blue paddingx-3-0 cursor_pointer text-truncate reciter-label-width`}>
              <span id="reciter-text-label">Reciter:</span>{props.selectedReciterName}  {props.playState &&  '('+props.selectedSurah +':'+ props.selectedVerse+')' }
            </label>
            <img
              className="verse_option_icon mx-1 p-1 mt-1 cursor_pointer"
              src={props.arrowDownBlue}
              alt=""
            />
          </div>
          <Popup className='reciter-popup' position="bottom center"
            open={props.open} closeOnDocumentClick onClose={props.closeModal}>
            <div className={`popup-reciter align-items-start  p-1 darkBackground `}  >
              <div id="ReciterScroll" className="reciters_div-audiobar px-4 py-2">
                {props.reciterNames.length > 0 ? (
                  props.reciterNames.map((reciter) => (
                    <div key={reciter.id + "reciters"} className="py-1 checkbox-text">
                      <input
                        className="radio-button"
                        type="radio"
                        id={`reciter${reciter.id}`}
                        value={reciter.id}
                        checked={parseInt(props.selectedReciterId) === parseInt(reciter.id)}
                        onChange={() => props.handleReciterChange(reciter.id, reciter.reciter)}
                      />
                      <label className={`p-left-2 radio-label radio-label-audiobar text-white-black-theme `} htmlFor={`reciter${reciter.id}`} onClick={() => props.handleReciterChange(reciter.id, reciter.reciter)}>
                        {reciter.reciter}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className={`text-white-black-theme`} >No reciter available.</p>
                )}
              </div>
            </div>
          </Popup>
          <Popup
            className='shuffle-popup'
            position="top center"
            offsetY={10}
            contentStyle={{
              display: 'flex',
              flexDirection: 'column',
              width: '240px',
              padding: '0.5rem',
              borderRadius: '10px',
              alignItems: 'center',
              background: '#FCFCFC',
              border: '1px solid #DFDFDF',
            }}
            open={props.openShuffle} closeOnDocumentClick onClose={props.closeModalShuffle}>
            <div className={`popup-shuffle align-items-start darkBackground `} >
              <div className="py-1 checkbox-text">
                <input
                  className="radio-button"
                  type="radio"
                  id="repeatSingle"
                  value="1"
                  checked={parseInt(props.shuffle) === 1}
                  onChange={() => props.handleShuffleChange(1)}
                />
                <label className={`px-2 radio-label radio-label-audiobar text-white-black-theme`} htmlFor="repeatSingle" onClick={() => props.handleShuffleChange(1)}>
                  Repeat single verse
                </label>
              </div>
              <div className="py-1 checkbox-text">
                <input
                  className="radio-button"
                  type="radio"
                  id="repeatPage"
                  value="2"
                  checked={parseInt(props.shuffle) === 2}
                  onChange={() => props.handleShuffleChange(2)}
                />
                <label className={`px-2 radio-label radio-label-audiobar text-white-black-theme `} htmlFor="repeatPage" onClick={() => props.handleShuffleChange(2)}>
                  Repeat Page
                </label>
              </div>
              <div className="py-1 checkbox-text">
                <input
                  className="radio-button"
                  type="radio"
                  id="norepeatPage"
                  value="3"
                  checked={parseInt(props.shuffle) === 3}
                  onChange={() => props.handleShuffleChange(3)}
                />
                <label className={`px-2 radio-label radio-label-audiobar text-white-black-theme`} htmlFor="norepeatPage" onClick={() => props.handleShuffleChange(3)}>
                  No Repeat
                </label>
              </div>
            </div>
          </Popup>
        </div>
      </div>
  );
});


export default RecitationComponent;
