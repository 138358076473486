import React from 'react'
import Loading from '../Loading';
import { Link } from "react-router-dom";
import DeleteConfirmationModal from '../../../Modals/Logic/DeleteConfirmationNotes'
import NotesModal from '../../../Modals/Logic/Notes'
import FooterCode from "../../Logic/FooterLogic"

export default function AllNotesUi(props) {
    return (
        <div className="width100s">

            <div className='height-custom padding-top-35 page-wrap'>
                <div className="d-flex flex-column ">
                    {props.loading && <Loading />}
                    {props.notes.length > 0 ?
                        <div className="padding-notes-left-right">
                            {props.notes.map((singleNotes, index) => {
                                return (
                                    <div key={index} className="notes-detail-container w-100 mt-3">
                                        {/* <div className="notes-detail-header  px-4 w-100">
                                            <div className="">
                                                <label className="font-size-18-600 text-white-black-theme ml-3 p-0 m-0">{singleNotes.verse.chapter.alphaArabicName} ({singleNotes.verse.chapterNo}:{singleNotes.verse.verseNo})</label>
                                            </div>
                                            <div className="">
                                                <Link className='link-without-underline' key={index} to={"/" + singleNotes.verse.chapterNo + "/" + singleNotes.verse.verseNo}>
                                                    <img
                                                        className="size-20-25 mr-3"
                                                        alt="..."
                                                        title={props.t('jump-to-verse')}
                                                        src={props.Jumpto}
                                                    />
                                                </Link>
                                                <img
                                                    className="size-20-25 mr-3 cursor-pointer"
                                                    alt="..."
                                                    src={props.Edit}
                                                    title={props.t('edit')}
                                                    onClick={() => props.EditNotes(singleNotes)}
                                                />
                                                <img
                                                    className="size-25-20 cursor-pointer"
                                                    alt="..."
                                                    src={props.Delete}
                                                    title={props.t('delete')}
                                                    onClick={() => props.DeleteNotes(singleNotes.id)}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="notes-detail-header px-4 w-100">
  {/* Part 1: Title */}
  <div className="title-section">
    <label className="font-size-18-600 text-white-black-theme ml-3 p-0 m-0">
      {singleNotes.verse.chapter.alphaArabicName} ({singleNotes.verse.chapterNo}:{singleNotes.verse.verseNo})
    </label>
  </div>

  {/* Part 2: Actions */}
  <div className="actions-section">
    <Link className='link-without-underline' key={index} to={"/" + singleNotes.verse.chapterNo + "/" + singleNotes.verse.verseNo}>
      <img className="size-20-25 mr-3" alt="..." title={props.t('jump-to-verse')} src={props.Jumpto} />
    </Link>
    <img className="size-20-25 mr-3 cursor-pointer" alt="..." src={props.Edit} title={props.t('edit')} onClick={() => props.EditNotes(singleNotes)} />
    <img className="size-25-20 cursor-pointer" alt="..." src={props.Delete} title={props.t('delete')} onClick={() => props.DeleteNotes(singleNotes.id)} />
  </div>
</div>

                                        <div className="px-4 py-2 darkBackground border-radius-12-12">
                                            <div className="w-100 py-4 text-right text-white-black-theme mb-4"
                                                style={{ fontSize: props.arabicFontSize + "rem", }}
                                            >
                                                {singleNotes.verse[props.scriptStyle]}
                                            </div>
                                            <div className=" w-100  d-flex flex-column border-top-line  ">
                                                <div className=" w-100  d-flex flex-column  ">
                                                    <label
                                                        className="m-0  w-100  translation_text text-white-black-theme py-2 "
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        {singleNotes.note}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                );
                            })

                            }
                        </div>
                        : props.loading===false &&
                        <div className="w-100 padding-bookmarks-notes-top padding-collection-left-right ">
                            <div className="collectionsContainer darkBackground-collection d-flex flex-column   align-items-center justify-content-center">
                                <img alt='' src={props.BookIcon} />
                                <label className="font-size-16-600 text-uppercase text-white-black-theme cursor_pointer py-0 mt-4">
                                    {(props.t('no-notes-found'))}
                                </label>
                                <label className="font-size-12-400  text-white-black-2-theme cursor_pointer py-0 my-0 ml-2 mr-2 text-center">
                                    {(props.t('no-notes-found_msg'))}
                                </label>
                            </div>
                        </div>
                    }
                    {props.isDeleteConfirmationsModalOpen && <DeleteConfirmationModal setDeletedSuccess={props.setDeletedSuccess} previousPage={"AllNotes"} t={props.t} DeleteNotesId={props.deleteId} setIsDeleteConfirmationsModalOpen={props.setIsDeleteConfirmationsModalOpen} onClose={props.onClose} setLoading={props.setLoading} />}
                    {props.isNotesModalOpen && <NotesModal t={props.t} NotesData={props.deleteId} previousPage={"AllNotes"} setDeletedSuccess={props.setDeletedSuccess} setLoading={props.setLoading} setIsNotesModalOpen={props.setIsNotesModalOpen} closeNotesModal={props.closeNotesModal} />}

                </div>
            </div>
            <FooterCode />
        </div>
    )
}
