import React from 'react'
import CrossWhite from "../../../assets/images/cross_white.svg";
import { LinkedinShareButton,LinkedinIcon,FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, FacebookMessengerShareButton, FacebookMessengerIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';


export default function ShareSocialMediaModalUi(props) {
    return (
        <div className="modal show" tabIndex="-1" onClick={(e) => e.target.classList.contains('modal') && props.closeSocialMediaModal()} id="myModal" role="dialog" aria-labelledby="myModalLabel">
            <div className="dark__overlay" onClick={(e) => e.target.classList.contains('dark__overlay') && props.closeSocialMediaModal()} />
            <div className="modal-dialog modal-dialog-centered width-large-50 float-to-center">
                <div className="modal-content darkBackground dark-mode-modal-border ">
                    <div className="modal-header dark-mode-modal-header dark-mode-modal-border-bottom">
                        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '3rem' }}>
                            <h5 className="modal-title model-title-text align-self-center">
                                {props.chapters.chapters[props.singleVerse.chapterNo - 1].alphaArabicName + ' (' + props.chapters.chapters[props.singleVerse.chapterNo - 1].chapterNo + ":" + props.singleVerse.verseNo + ")"}
                            </h5>
                            <img className=' model-cross-icon align-self-center '
                                alt='' onClick={() => props.closeSocialMediaModal()} src={CrossWhite} />
                        </div>
                    </div>
                    <div className="modal-body " >

                        <div className="row justify-content-center align-items-center">
                            <div className='col-1-share'>
                            
                                <FacebookShareButton
                                    url={props.shareUrl}
                                    quote={props.quote}
                                    hashtag="#AlQuranApp"
                                    description={props.quote}
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                            </div>
                            <div className='col-1-share'>
                                <FacebookMessengerShareButton
                                    url={'https://www.google.com'}
                                >
                                    <FacebookMessengerIcon size={32} round />
                                </FacebookMessengerShareButton>
                            </div>
                            <div className='col-1-share'>
                                <TwitterShareButton
                                    url={props.shareUrl}
                                    title={props.quote}
                                    // hashtags={['bccompsciclub']}
                                >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                            </div>
                            <div className='col-1-share'>
                                <WhatsappShareButton
                                    url={props.shareUrl}
                                    title={props.quote}
                                    
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>
                            <div className='col-1-share'>
                                <LinkedinShareButton
                                    url={props.shareUrl}
                                    quote={props.quote}
                                >
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                            </div>
                            
                        </div>
                    </div>
                    <div className="modal-footer dark-mode-modal-border-only-top">
                        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '0rem' }}>
                            <div className={` darkBackground model_button cursor_pointer px-4 mx-2 `} title={(props.t('cancel'))} onClick={props.closeSocialMediaModal}>
                                <label className={` text-white-blue-2-theme cursor_pointer`} style={{ "color": "rgb(106, 106, 106)" }}>{(props.t('cancel'))}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
    )
}
