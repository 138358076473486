import React from 'react';
import CollectionIcon from '../../../../../assets/images/collection.eea18a82.svg'

export default function DisplayCollectionUi(props) {
  return (
      <div onClick={() => props.AddToCollections(props.singleVerse_id)} className="d-flex flex-row  w-100 mt-2 px-2 cursor_pointer">
        <img alt="" className=" verse_option-_icon" src={CollectionIcon} />
        <span className="login_popup_description text-left my-0 ml-2"> {props.t("collections")}</span>
      </div>
  )
}


