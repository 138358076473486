import i18n from 'i18next';
const getCurrentLanguageName = () => {
  const languageNames = {
    'en-US': 'English',
    'en': 'English',
    'fr': 'French',
    'ar': 'Arabic',
    'zh-CN': 'Chinese (Simplified)',
    'zh-TW': 'Chinese (Traditional)',
    'ru': 'Russian',
    'hi': 'Hindi',
    'id': 'Indonesian',
    'fa': 'Persian',
    'ms': 'Malay',
    'ur': 'Urdu',
    'bn': 'Bengali',
  };
  const currentLanguageCode = i18n.language;
  // Return the corresponding language name or fallback to code
  return languageNames[currentLanguageCode] || currentLanguageCode;
};

export { getCurrentLanguageName };
