import React from 'react'
import { getTimeAgo } from "../../Logic/Bookmarks/CalculateTimeAgo"
import { Link } from "react-router-dom";
import Loading from '../Loading';
import FooterCode from "../../Logic/FooterLogic"


export default function AllBookmarksUi(props) {
    return (
        <div className="width100s">
            <div className='height-custom padding-top-35 page-wrap'>
                <div className="d-flex  justify-content-center h-100 ">{/* py-4 */}
                    {props.loading && <Loading />}
                    {props.bookmarks.length > 0 ?
                        <div className="bookmark_grid ">
                            {props.bookmarks.map((singleBookmark, index) => {
                                return (
                                    <Link className='bookmark-container-link  link-without-underline mt-4' key={index} to={"/" + singleBookmark.chapterNo + "/" + singleBookmark.verseNo}>
                                        <div className="bookmark-container darkBackground d-flex flex-column ">
                                            <div className="bookmark-container-top">
                                                <div className="">
                                                    <img alt={"surah name"} className={"vw-12 SurahNameBookmarks"} height="90px" width="150px" style={{
                                                        filter: "invert(20%) sepia(98%) saturate(2403%) hue-rotate(180deg) brightness(93%) contrast(95%)",
                                                        fill: "#006B9E",
                                                    }} src={require(`../../../../assets/images/chapters/${singleBookmark.chapterNo}.svg`)} />
                                                </div>
                                            </div>
                                            <span className="font-size-16-500   txt-color-blue mt-4 ml-2" />
                                            <div className="  ml-2">
                                                <label className="cursor-pointer bookmarks-Label text-greenish-blue m-0 ml-2">{singleBookmark.verse.chapter.alphaArabicName}</label>
                                                <label className="cursor-pointer font-size-15-600  m-0 text-white-black-theme ">Verse: <span className="gray3Color px-2 py-0">{singleBookmark.verse.verseNo}</span></label>

                                            </div>
                                            <span className="font-size-13-500 gray3Color mt-1  ml-2">
                                                {getTimeAgo(singleBookmark.updatedAt)}
                                            </span>
                                        </div>
                                    </Link>
                                );
                            })

                            }
                        </div>
                        : props.loading===false &&
                        <div className="w-100 padding-bookmarks-notes-top padding-collection-left-right ">
                            <div className="collectionsContainer darkBackground-collection d-flex flex-column align-items-center justify-content-center">
                                <img alt='' src={props.BookIcon} />
                                <label className="font-size-16-600 text-uppercase text-white-black-theme cursor_pointer py-0  mt-4">
                                    {(props.t('no-bookmark-found'))}
                                </label>
                                <label className="font-size-12-400  text-white-black-2-theme cursor_pointer py-0 my-0 ml-2 mr-2 text-center">
                                    {(props.t('no-bookmark-found_msg'))}
                                </label>


                            </div>

                        </div>
                    }
                </div>
            </div>
            <FooterCode />

        </div>

    )
}
