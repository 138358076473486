import { React, useState, useEffect } from 'react'
import CollectionDetailsUi from "../../Ui/AllCollections/CollectionDetailsUi"
import { useTranslation } from "react-i18next";
import { FindAllTranslations,findCollectionDetail } from "../../../../apis/FetchData";
import JumptoB from "../../../../assets/images/collection/jump_to.svg"
import BinB from "../../../../assets/images/collection/bin.svg"
import CollectionPopupB from "../../../../assets/images/collection/collection_popup_icon.svg"
import MoveToB from "../../../../assets/images/collection/move_to.svg"
import JumptoPink from "../../../../assets/images/collection/jump_toPink.svg"
import BinPink from "../../../../assets/images/collection/binPink.svg"
import CollectionPopupPink from "../../../../assets/images/collection/collection_popup_iconPink.svg"
import MoveToPink from "../../../../assets/images/collection/move_toPink.svg"
import { useParams,useNavigate  } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { SettingsReducer } from "../../../../redux/Setting"
const SettingActions = SettingsReducer.actions;
export default function CollectionsDetails() {
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const userTheme = useSelector((state) => state.settings.userTheme);
  const [loading, setLoading] = useState(false);
  const [collectionDetail, setCollectionDetail] = useState([]);
  const scriptStyle = useSelector((state) => state.settings.script);
  const arabicFontSize = useSelector((state) => state.settings.arabicFontSize);
  const translationFontSize = useSelector((state) => state.settings.translationFontSize);
  const selectedShowTranslation = useSelector((state) => state.settings.showTranslationCollectionDetails);
  const selectedShowNotes = useSelector((state) => state.settings.showNotesCollectionDetails);
  const selectedBeforAfter = useSelector((state) => state.settings.beforeAfterVerseCollectionDetails);
  const selectedReciterId = useSelector((state) => state.settings.selectedReciterId);
  const selectedTranslations = useSelector((state) => state.settings.selectedTranslations);
  const [isDeleteCollectionVerseModalOpen, setIsDeleteCollectionVerseModalOpen] = useState(false);
  const [deleteVerseIdofCollection, setDeleteVerseIdofCollection] = useState(false);
  const [deleteVerseCollectionId, setDeleteVerseCollectionId] = useState(false);
  const [isAddNewCollectionModalOpen, setIsAddNewCollectionModalOpen] = useState(false);
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const Jumpto = userTheme === 'Dark' ? JumptoPink : JumptoB;
  const Bin = userTheme === 'Dark' ? BinPink : BinB;
  const CollectionPopup = userTheme === 'Dark' ? CollectionPopupPink : CollectionPopupB;
  const MoveTo = userTheme === 'Dark' ? MoveToPink : MoveToB;

  


  
  const getAllTranslations = async (surah,verse,verseData) => {
    let result = await FindAllTranslations(surah, verse, selectedReciterId, selectedTranslations);
    if (result && result.status===200) {
      let translation_obj=result.data.verse[0].translations
        const updatedObject = {
          ...verseData,
          translations: [...verseData.translations, translation_obj]
      };
      return updatedObject
  }
}

  const fetchCollectionsDetails = async () => {
    setDeleted(false)
    setLoading(true);
    let result = await findCollectionDetail(collectionId);
    if (result && result.status === 200) {
      let dataToShow=result.data[0]
      if (dataToShow.collectionVerse.length>=1 && Array.isArray(dataToShow.collectionVerse)) {
        for (let i = 0; i <dataToShow.collectionVerse.length; i++) {   
          let verseData =dataToShow.collectionVerse[i].verse;
          let updatedObject= await  getAllTranslations(verseData.chapterNo,verseData.verseNo,verseData)           
          dataToShow.collectionVerse[i].verse = updatedObject;
          setCollectionDetail(dataToShow)
        }
      } 
      else {
        navigate('/collections/');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
      fetchCollectionsDetails();

     
  },
  // eslint-disable-next-line
  [deleted,selectedTranslations]);



  
  const HandleBeforAfter= (value) =>{
    dispatch(SettingActions.updateSettings({ beforeAfterVerseCollectionDetails: value }));

  }
  const ShowNotes= () =>{
    dispatch(SettingActions.updateSettings({ showNotesCollectionDetails: !selectedShowNotes }));

  }
  const ShowTranslation= () =>{
    dispatch(SettingActions.updateSettings({ showTranslationCollectionDetails: !selectedShowTranslation }));
  }
  const DeleteCollection = (id) => {
    setIsDeleteCollectionVerseModalOpen(true)
    setDeleteVerseIdofCollection(id)
  }

  const onCloseDelete = () => {
    setIsDeleteCollectionVerseModalOpen(false)
  }

  const MoveToCollection = (verseId,VerseCollectionId) => {
    setIsCollectionModalOpen(true)
    setDeleteVerseIdofCollection(verseId)
    setDeleteVerseCollectionId(VerseCollectionId)
  }

  const onCloseMoveToCollection = () => {
    setIsCollectionModalOpen(false)
  }
  const closeAddNewCollectionModal = () => {
    setIsAddNewCollectionModalOpen(false)
  }
 
 

  
 

  
  return (
    <CollectionDetailsUi t={t} setLoading={setLoading} loading={loading} selectedTranslations={selectedTranslations}
    collectionDetail={collectionDetail} HandleBeforAfter={HandleBeforAfter}
    selectedBeforAfter={selectedBeforAfter} ShowNotes={ShowNotes} selectedShowNotes={selectedShowNotes}
    ShowTranslation={ShowTranslation} selectedShowTranslation={selectedShowTranslation}
    scriptStyle={scriptStyle} arabicFontSize={arabicFontSize} translationFontSize={translationFontSize}
    DeleteCollection={DeleteCollection} isDeleteCollectionVerseModalOpen={isDeleteCollectionVerseModalOpen}
    onCloseDelete={onCloseDelete} deleteVerseIdofCollection={deleteVerseIdofCollection} setDeleted={setDeleted}
    isCollectionModalOpen={isCollectionModalOpen} MoveToCollection={MoveToCollection}
    onCloseMoveToCollection={onCloseMoveToCollection} deleteVerseCollectionId={deleteVerseCollectionId}
    setIsCollectionModalOpen={setIsCollectionModalOpen} isAddNewCollectionModalOpen={isAddNewCollectionModalOpen}
    setIsAddNewCollectionModalOpen={setIsAddNewCollectionModalOpen} closeAddNewCollectionModal={closeAddNewCollectionModal}
    Jumpto={Jumpto} Bin={Bin} CollectionPopup={CollectionPopup} MoveTo={MoveTo}
    />
  );
}