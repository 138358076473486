import { createSlice } from "@reduxjs/toolkit";

export const ChaptersReducer = createSlice({
  name: "chaptersSlice",
  initialState: {
    chapters: [],
    reciters: [],
    scripts: [],
    translators: [],
  },
  reducers: {
    updateChapterList: (state, action) => {
      state.chapters = action.payload.chapters;
    },
    updateRecitersList: (state, action) => {
      state.reciters = action.payload.reciters;
    },
    updateScriptsList: (state, action) => {
      state.scripts = action.payload.scripts;
    },
    updateTranslatorsList: (state, action) => {
      state.translators = action.payload.translators;
    }
  }
});


