import { React } from "react";

import Settings from "../Logic/Settings";
import History from "../Logic/History";
import Apperance from "../Logic/Apperance";


function RightMenu(props) {
  return (
    <div>
      <img
        src={ props.historyIcon }
        title={props.t(("history"))}
        alt="History"
        className="cursor-pointer"
        onClick={() => props.setSelectedMenu("history")}
      />
      <img
        src={props.appearanceIcon }
        alt="Appearance"
        title={props.t(("display-apperance"))}
        className="px-3 cursor-pointer"
        onClick={() => props.setSelectedMenu("appearance")}
      />
      <img
        src={props.settingIcon }
        alt="Setting"
        title={props.t(("settings"))}
        className="cursor-pointer"
        onClick={() => props.handleMenuClick("setting")}
      />
      <div className="vw-100 position-absolute right-menu d-flex justify-content-end ">
        <History t={props.t} handleClickAway_CloseMenu={props.handleClickAway_CloseMenu} selectedMenu={props.selectedMenu} closeSettings={props.handleClickAway_CloseMenu} />
        <Apperance t={props.t} handleClickAway_CloseMenu={props.handleClickAway_CloseMenu} selectedMenu={props.selectedMenu} closeSettings={props.handleClickAway_CloseMenu} />
        <Settings t={props.t} handleClickAway_CloseMenu={props.handleClickAway_CloseMenu} selectedMenu={props.selectedMenu} closeSettings={props.handleClickAway_CloseMenu} />

      </div>
    </div>
  );
}

export default RightMenu;
