import React from 'react'
import CrossWhite from "../../../assets/images/cross_white.svg";

export default function GrammerModal(props) {
    return (
        <div className="modal  show" onClick={(e) => e.target.classList.contains('modal') && props.onClose()} tabIndex="-1">
            <div className="dark__overlay" onClick={(e) => e.target.classList.contains('dark__overlay') && props.onClose()} />
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered ">
                <div className="modal-content darkBackground dark-mode-modal-border">
                    <div className="modal-header dark-mode-modal-header dark-mode-modal-border-bottom">
                        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '3rem' }}>
                            <h5 className="modal-title model-title-text align-self-center">
                                {props.chapters.chapters[props.Grammers[0].chapterNo - 1].alphaArabicName + ' (' + props.chapters.chapters[props.Grammers[0].chapterNo - 1].chapterNo + ":" + props.grammerModalVerseId + ")"}
                            </h5>
                            <img className=' model-cross-icon align-self-center '
                                alt='' onClick={() => props.onClose()} src={CrossWhite} />
                        </div>
                    </div>
                    <div className="modal-body" style={{ height: '50vh' }}>
                        {props.Grammers[props.grammerModalVerseId - 1].versewordbywordTranslation.map((word, index) => {
                            return (
                                <div
                                    key={index + 'G1'} className={` d-flex flex-column  py-4 ${index < props.Grammers[props.grammerModalVerseId - 1].versewordbywordTranslation.length - 1 ? "dark-mode-modal-border-only-bottom" : ""}`}>

                                    <div className="d-flex  justify-content-between   w-100">
                                        <label className={`word_count_text px-1 darkBackground text-white-black-theme specialAreaColor `} >
                                            {word.chapterNo}:{word.verseNo}:{word.wordNo}
                                        </label>
                                    </div>

                                    <div className="d-flex  justify-content-between mt-2  w-100">
                                        <b className={`grammer_translation_text  `}>
                                            {word.translation}
                                        </b>
                                        <b className={`grammer_verse_text_arabic  simple_script_font  text-white-black-theme `} >
                                            {word.word}
                                        </b>
                                    </div>
                                    <div className="d-flex  flex-column mt-2  w-100">
                                        {word.englishGrammar ? word.englishGrammar.split('<br/>').map((grammer, index) => {
                                            return (
                                                <div key={index + 'G2'}>
                                                    <b className=" grammer_PN_text   ">
                                                        {grammer.split('-')[0]}
                                                    </b>
                                                    <b className={`grammer_verse_text ml-2 text-white-black-theme `}>
                                                        {'| ' + grammer.split('-')[1]}
                                                    </b>
                                                </div>
                                            )
                                        }
                                        )
                                            : ''}
                                        <div className="d-flex  justify-content-between   w-100">
                                            {word.root &&
                                                <div className="cursor-pointer"
                                                    onClick={() => props.OnRootWordClick(word.root)}>
                                                    <b className=" grammer_root_text-root-word  mt-2">
                                                        {(props.t('root-word'))}:
                                                    </b>
                                                    <span className="grammer_root_text ml-3  ">
                                                        {word.root}
                                                    </span>
                                                </div>
                                            }
                                            {word.arabicGrammar ? word.arabicGrammar.split('<br/>').map((grammer, index) => {
                                                return (
                                                    <div className="row-grammer-small-device">
                                                        <span key={index + 'G3'} className="grammer_arabic_text text-right itemRight  w-100 ">
                                                            {grammer}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                            )
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                    <div className="modal-footer modal-footer-height-grammer dark-mode-modal-border-only-top">
                        <div className="flex-container custom-column-yes-no justify-content-center align-items-center w-100" style={{ height: '0rem' }}>
                            <div className={`model_button_yes_no mb-1 darkBackground model_button cursor_pointer px-4 py-3 mx-2 ${props.grammerModalVerseId > 1 ? '' : 'disabled-button'}`}
                                title={(props.t('previous'))}
                                onClick={() => {
                                    if (props.grammerModalVerseId > 1) {
                                        props.onPreviousClick();
                                    }
                                }}
                            >
                                <label className={`text-white-blue-2-theme cursor_pointer  ${props.grammerModalVerseId > 1 ? '' : 'disabled-button'}`} >{(props.t('previous'))}</label>
                            </div>
                            <div className={`model_button_yes_no mb-1 darkBackground model_button cursor_pointer px-4 py-3 ${props.grammerModalVerseId < props.Grammers.length ? '' : 'disabled-button'}`}
                                title={(props.t('next'))}
                                onClick={() => {
                                    if (props.grammerModalVerseId < props.Grammers.length) {
                                        props.onNextClick();
                                    }
                                }}
                            >
                                <label className={`text-white-blue-2-theme cursor_pointer ${props.grammerModalVerseId < props.Grammers.length ? '' : 'disabled-button'}`}>{(props.t('next'))}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
