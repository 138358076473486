import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import { useTranslation } from "react-i18next";
import { auth } from "../../../apis/firebase_authentications";
import { useDispatch } from "react-redux";
import NavbarUi from "../Ui/NavbarUi"
import { getCurrentLanguageName } from '../../languageUtils';
import SearchLogoBlue from "../../../assets/images/search.svg"
import SearchLogoDark from "../../../assets/images/searchGray.svg"
import DropDownBlack from '../../../assets/images/arrow-down.svg'
import DropDownWhite from '../../../assets/images/arrow-down-white.svg'
import LanguageWhite from "../../../assets/images/languageWhite.svg"
import Language from "../../../assets/images/language.svg"
import ProfileImage from '../../../assets/images/Profile.svg'
import ProfileImageWhite from '../../../assets/images/ProfileWhite.svg'
import lastReadB from '../../../assets/images/last_read_blue.efca7f61.svg'
import CollectionB from '../../../assets/images/collection_blue.svg'
import NotesB from '../../../assets/images/notes_blue.svg'
import BookmarksB from '../../../assets/images/bookmark_blue.svg'
import lastReadPink from '../../../assets/images/last_read_Pink.svg'
import CollectionPink  from '../../../assets/images/collection_Pink.svg'
import NotesPink from '../../../assets/images/notes_Pink.svg'
import BookmarksPink  from '../../../assets/images/bookmark_Pink.svg'
import { convertToSearchableChapters,convertToSearchableVerses } from './SearchableChapters'; 
import { SettingsReducer } from "../../../redux/Setting"
const SettingActions = SettingsReducer.actions;

export default function Navbar(props) {
  const dispatch = useDispatch()
  const chapters = useSelector((state) => state.chapters);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [selectedOptionChapter, setSelectedOptionChapter] = useState(null);
  const [selectedOptionVerse, setSelectedOptionVerse] = useState(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [user, setUser] = useState(auth.currentUser);
  const [searchKeyword, setSearchKeyword] = useState('');
  const rootWordsCount = useSelector((state) => state.settings.rootWordsCount);
  const rootWord = useSelector((state) => state.settings.rootWord);
  const lastRead = useSelector((state) => state.settings.lastRead);
  const [isModalHide, setIsModalHide] = useState(true);
  const userTheme = useSelector((state) => state.settings.userTheme);
  const profileIcon = userTheme === 'Dark' ? ProfileImageWhite : ProfileImage;
  const languageIcon = userTheme === 'Dark' ? LanguageWhite : Language;
  const lastReadBlue = userTheme === 'Dark' ? lastReadPink : lastReadB;
  const CollectionBlue = userTheme === 'Dark' ? CollectionPink : CollectionB;
  const NotesBlue = userTheme === 'Dark' ? NotesPink : NotesB;
  const BookmarksBlue = userTheme === 'Dark' ? BookmarksPink : BookmarksB;
  const SearchLogo = userTheme === 'Dark' ? SearchLogoDark : SearchLogoBlue;




  const toggleLoginModal = () => {
    setIsModalHide(!isModalHide);
  };
  
  const onHideLoginModal = () => {
    setIsModalHide(true);
  };
  

  const toggleDropdown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };
  const currentUrl = location.pathname
  const changeLanguageHandler = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language); // Store selected language
    setIsDropDownOpen(false);
  };

  const handleVerseChange = (e) => {
    navigate("/" + props.selectedSurah + "/" + e.value);
  };

  const handleChapterChange = (e) => {
    navigate("/" + e.value + "/1");
    document.getElementById('chapterContent').scrollTop = 0;
  }
  
const languageOptions = [
  { value: "ar", label: "Arabic" },
  { value: "bn", label: "Bengali" },
  { value: "zh-CN", label: "Chinese (Simplified)" },
  { value: "zh-TW", label: "Chinese (Traditional)" },
  { value: "en", label: "English" },
  { value: "fr", label: "French" },
  { value: "hi", label: "Hindi" },
  { value: "id", label: "Indonesian" },
  { value: "ms", label: "Malay" },
  { value: "fa", label: "Persian" },
  { value: "ru", label: "Russian" },
  { value: "ur", label: "Urdu" },
];

const defaultOption = languageOptions.find((option) => option.label === getCurrentLanguageName());

const CustomDropdownIndicator = () => {
  return (
    <div className="custom-dropdown-indicator">
      <div className="custom-arrow">
      <img alt='' src={ props.userTheme === "Dark" ? DropDownWhite : DropDownBlack } />

      </div>
    </div>
  );
};

const  customStylesLanguage ={
  option: (provided, state) => ({
    ...provided,
    color:  state.isSelected ? "white" : "#707070",
    backgroundColor:  props.userTheme === "Dark" ? state.isSelected ? "#0d6efd" : "#212529" : state.isSelected ? "#0d6efd" : "white",
    marginTop: -5, 
    marginBottom: -5, 
    "&:hover": {
      backgroundColor: "skyblue", 
    },
    
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: "12px",
    // width: "140px",
    width: "fitContent",
    right: '0px',
    border: "none",
    boxShadow: "none",
    background: "transparent",
    cursor: "pointer",
    color: "#707070", 
    
  }),
}

const selectedLanguageValue = () => (
  <div  className={`FloatRight text-white-black-theme`}>
    <span ><img  alt='' className="LanguageImage" src={ languageIcon } /> {defaultOption ? defaultOption.label: "Default Value"}</span>
  </div>
);

  const ChapterOptions = convertToSearchableChapters(chapters);
  const VerseOptions = convertToSearchableVerses(chapters,props.selectedSurah);
  useEffect(() => {
    // Find the option object with the specific ID (props.selectedSurah) and set it as the selected option
    setSelectedOptionChapter(ChapterOptions.find(option => option.value === props.selectedSurah));
    setSelectedOptionVerse(VerseOptions.find(option => option.value === props.selectedVerse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if(!currentUrl.includes('/search/')){
      setSearchKeyword('')
      dispatch(SettingActions.updateSettings({ rootWord: null }));
    }
  },
  // eslint-disable-next-line
  [currentUrl]);

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 15);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user]);


  const SubmitSearchForm = (event) => {
    //R.E to return if a searchKeyword string is empty or contains only whitespace characters. 
    if (/^\s*$/.test(searchKeyword)) return
    if (event.keyCode === 13 && searchKeyword.length > 0) {
      if (searchKeyword.includes(':')) {
        setSearchKeyword(searchKeyword.split(':')[1]);
      }
      //enter is pressed so search
      clickOnSearch()
    }
  }

  const onSearchWordChange = (value) => {
    if (rootWord) {
      dispatch(SettingActions.updateSettings({ rootWord: null }));
    }
    if (/^\s*$/.test(value) && !searchKeyword) return
    setSearchKeyword(value)
  }

  const clickOnSearch = () => {
    if (searchKeyword.length > 0)
      if (searchKeyword.includes(':')) {
        setSearchKeyword(searchKeyword.split(':')[1]);
        navigate('/search/' + searchKeyword.split(':')[1]);
      }
      else {
        navigate('/search/' + searchKeyword);
      }
  }
  

  return (
    <NavbarUi t={t} chapters={chapters} selectedOptionChapter={selectedOptionChapter} selectedOptionVerse={selectedOptionVerse}
      user={user} setUser={setUser} searchKeyword={searchKeyword} rootWord={rootWord} rootWordsCount={rootWordsCount}
      lastRead={lastRead} changeLanguageHandler={changeLanguageHandler} handleVerseChange={handleVerseChange} currentUrl={currentUrl}
      handleChapterChange={handleChapterChange} ChapterOptions={ChapterOptions} VerseOptions={VerseOptions} onMenuOpen={onMenuOpen}
      SubmitSearchForm={SubmitSearchForm} onSearchWordChange={onSearchWordChange} clickOnSearch={clickOnSearch} navigate={navigate}
      toggleDropdown={toggleDropdown} isDropDownOpen={isDropDownOpen} toggleLoginModal={toggleLoginModal} 
      isModalHide={isModalHide} setIsModalHide={setIsModalHide} onHideLoginModal={onHideLoginModal} languageOptions={languageOptions} 
      defaultOption={defaultOption} CustomDropdownIndicator={CustomDropdownIndicator} selectedLanguageValue={selectedLanguageValue}
      customStylesLanguage={customStylesLanguage} profileIcon={profileIcon} SearchLogo={SearchLogo} userTheme={userTheme}
      BookmarksBlue={BookmarksBlue} NotesBlue={NotesBlue} CollectionBlue={CollectionBlue} lastReadBlue={lastReadBlue}

    />
  );
}

