import { React, } from "react";
import {  useSelector } from "react-redux";
import HistoryUi from '../UI/HistoryUi'
import CrossIcon from '../../../assets/images/cross_black.svg'
import CrossIconWhite from '../../../assets/images/cross_white.svg'
import HistoryIcon from '../../../assets/images/HistorySidebarIcon.svg'
import HistoryIconPink from '../../../assets/images/HistorySidebarIconPink.svg'

function History(props) {
  const historyList = useSelector((state) => state.settings.historyList);
  const userTheme = useSelector((state) => state.settings.userTheme);
  const crossIcon = userTheme === 'Dark' ? CrossIconWhite : CrossIcon;
  const historyIcon = userTheme === 'Dark' ? HistoryIconPink : HistoryIcon;

  const onOverlayClick = () => {
    props.handleClickAway_CloseMenu()
  }

  return (
    <HistoryUi historyIcon={historyIcon} crossIcon={crossIcon} t={props.t} closeSettings={props.closeSettings} selectedMenu={props.selectedMenu} 
    onOverlayClick={onOverlayClick} historyList={historyList}
    />
  )
}

export default History
