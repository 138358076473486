import { Route, Routes } from "react-router-dom";//The Routes component is used to group multiple Route and route is used for mapping between a specific URL path and the component.
import Navbar from "./components/Views/Logic/Navbar";
import { useEffect, useState } from 'react'
import { findAllChapters, findAllTranslatorScriptsReciters } from './apis/FetchData';
import "./assets/css/main.css";
import "./assets/css/fonts.css";
import './assets/css/bootstrap.min.css';
import './assets/css/mystyles.css';
import './assets/css/readstyle.css';
import './assets/css/dropdown.css';
import './assets/css/login.css';
import './assets/css/custom-theme.scss';
import './assets/css/theme_custom_changes.css';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ChaptersReducer } from "./redux/AllChapter"
import Home from "./components/Views/Logic/Home/Home"
import Search from "./components/Views/Logic/Search";
import Collections from "./components/Views/Logic/AllCollections/AllCollections";
import CollectionDetails from "./components/Views/Logic/AllCollections/CollectionDetails";
import Bookmarks from "./components/Views/Logic/Bookmarks/AllBookmarks";
import Notes from "./components/Views/Logic/Notes/Notes";


const ChapterActions = ChaptersReducer.actions;

function App() {

  // useSelector((state) => state.chapters);
  const chapters = useSelector((state) => state.chapters);
  let userTheme = useSelector((state) => state.settings.userTheme);
  const dispatch = useDispatch()//uses the useDispatch hook from the react-redux library to get the dispatch function from the Redux store
  const location = useLocation();
  const [selectedSurah, setselectedSurah] = useState(1);
  let [selectedVerse, setselectedVerse] = useState(1);

  if (userTheme==='Auto')
  {
    userTheme='Light'
  }
  const fetchData = async () => {
    try {
      if (chapters.chapters.length === 0) {

        let chaptersResult = await findAllChapters();
        if (chaptersResult.status)
          dispatch(
            ChapterActions.updateChapterList({ chapters: chaptersResult.data })
          );
      }
      if (chapters.reciters.length === 0 || chapters.scripts.length === 0 || chapters.translators.length === 0) {
        let chaptersResult = await findAllTranslatorScriptsReciters();
        if (chaptersResult.status) {
          dispatch(
            ChapterActions.updateRecitersList({
              reciters: chaptersResult.data.reciters,
            })
          );
          dispatch(
            ChapterActions.updateScriptsList({
              scripts: chaptersResult.data.scripts,
            })
          );
          dispatch(
            ChapterActions.updateTranslatorsList({
              translators: chaptersResult.data.translators,
            })
          );
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  });

  useEffect(() => {
    let locationParts = location.pathname.split("/");
    if (locationParts.length > 2) {
      setselectedSurah(parseInt(locationParts[1]));
      setselectedVerse(parseInt(locationParts[2]));
    }
  }, [location]);

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', userTheme);
  }, [userTheme]);

  return (

    <div>
      <Navbar  selectedSurah={selectedSurah} selectedVerse={selectedVerse} />
      <div className="body">
        <Routes>
          <Route path="/" element={<Home  selectedSurah={selectedSurah} selectedVerse={selectedVerse} />} />
          <Route path=":surah/:verse" element={<Home   selectedSurah={selectedSurah} selectedVerse={selectedVerse} />} />               
          <Route path="/search" element={<Search  userTheme={userTheme} />} />
          <Route path="/search/:searchKeyword" element={<Search  userTheme={userTheme} />} />
          <Route path="/search/rootword/:searchKeyword?" element={<Search  userTheme={userTheme} />} />
          <Route path="/collections" element={<Collections  userTheme={userTheme} />} />
          <Route path="/collection-detail/:collectionId" element={<CollectionDetails  userTheme={userTheme} />} />
          <Route path="/bookmarks" element={<Bookmarks  userTheme={userTheme} />} />
          <Route path="/notes" element={<Notes  userTheme={userTheme} />} />



        </Routes>
      </div>
    </div>

  );
}

export default App;
