function getTranslationDirection(text) {
    const rtlCharacters = /[\u0600-\u06FF\u0750-\u077F\u0590-\u05FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
  
    // Take the first 10 characters from the text
    const substringToCheck = text.substring(0, 10);
  
    if (rtlCharacters.test(substringToCheck)) {
      return 'rtl'; // Right-to-left script (e.g., Arabic, Urdu)
    } else {
      return 'ltr'; // Left-to-right script (e.g., English)
    }
  }
  
  
  export {getTranslationDirection}