import axios from '../apis/AxiosInterceptors';
import { SettingsReducer } from '../redux/Setting';
import store from '../redux/store/store';
import { auth } from "../apis/firebase_authentications";

const signUpTheUser = async () => {
  await signUp();
}

const SendSignupRequest = async()=>{
  if (auth.currentUser){
    await auth.currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      if (idTokenResult.claims && !idTokenResult.claims.Role) {
        console.log('Token Role do not exists:', idTokenResult);
        // Send request here
        signUpTheUser()
      }
    })
    .catch((error) => {
      console.error('Error getting token claims:', error);
    });
  }
}


const getAuthTokenWithOutSignUp = (user) => {
  return new Promise((resolve, reject) => {
    if (user) {
      user.getIdToken(true)
        .then(idToken => {
          if (idToken) {
            resolve(idToken); 
            store.dispatch(SettingsReducer.actions.updateSettings({ firebaseToken: idToken }));
          }
        })
        .catch(error => {
          console.error("Error getting ID token:", error);
          reject(error);
        });
    }
  });
};

const getAuthToken = (user) => {
  return new Promise((resolve, reject) => {
    if (user) {
      user.getIdToken(true)
        .then(idToken => {
          if (idToken) {
            resolve(idToken); 
            //send request here
            store.dispatch(SettingsReducer.actions.updateSettings({ firebaseToken: idToken }));
            SendSignupRequest()
            getAuthTokenWithOutSignUp(user)            
          } else {
            reject(new Error("ID token not available"));
          }
        })
        .catch(error => {
          console.error("Error getting ID token:", error);
          reject(error);
        });
    } else {
      reject(new Error("No authenticated user"));
    }
  });
};





const signUp = () => {
  let url = process.env.REACT_APP_API_URL + 'users/signup';
  return axios({
    url: url,
    method: "POST",
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};



const addToBookmark = (verseId, verseNo, chapterNo, chapterName) => {
  let data = {
    verseId: verseId,
    verseNo: verseNo,
    chapterNo: chapterNo,
    chapterName: chapterName,

  }
  let url = process.env.REACT_APP_API_URL + 'bookmarks';
  return axios({
    url: url,
    method: "POST",
    data,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};



const deleteBookmark = (verseId) => {
  let url = process.env.REACT_APP_API_URL + 'bookmarks/'+verseId;
  return axios({
    url: url,
    method: "DELETE",
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};


const addToNotes = (verseId, chapterNo, notes,noteIdForUpdate) => {
  let url;
  let method;
  let data;
  if(noteIdForUpdate){
     url = process.env.REACT_APP_API_URL + 'notes/'+noteIdForUpdate;
     data = {note: notes }
     method='PATCH'
  }
  else{
     url = process.env.REACT_APP_API_URL + 'notes/';
     data = {verseId: verseId,chapterNo: chapterNo,note: notes }
     method='POST'
  }
  
  return axios({
    url: url,
    method: method,
    data,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const deleteNotes = (notesId) => {
  const url = process.env.REACT_APP_API_URL + `notes/${notesId}`;
  const data={}
  return axios({
    url: url,
    method: "DELETE",
    data,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const createNewCollection = (data,firebaseToken) => {
  let url = process.env.REACT_APP_API_URL + 'collections/';
  return axios({
    url: url,
    method: "POST",
    data,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};


const addToCollection = (verseId, collectionId) => {
  let data={"verseId":verseId,"collectionId":collectionId}
  let url = process.env.REACT_APP_API_URL + 'collection-verses/';
  return axios({
    url: url,
    method: "POST",
    data,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
const updateCollection = (updateId, data) => {
  let url = process.env.REACT_APP_API_URL + `collections/${updateId}`;
  return axios({
    url: url,
    method: "PATCH",
    data,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const deleteCollection = (deleteId) => {
  let url = process.env.REACT_APP_API_URL + `collections/${deleteId}`;
  return axios({
    url: url,
    method: "DELETE",
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const deleteVerseFromCollection = (deleteId) => {
  let url = process.env.REACT_APP_API_URL + `collection-verses/${deleteId}`;
  return axios({
    url: url,
    method: "DELETE",
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
export {
  getAuthToken,
  addToBookmark,
  deleteBookmark,
  addToNotes,
  deleteNotes,
  createNewCollection,
  addToCollection,
  updateCollection,
  deleteCollection,
  deleteVerseFromCollection,
  signUp,
  SendSignupRequest,
  getAuthTokenWithOutSignUp

};

