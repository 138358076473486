import React, { useState,useEffect } from 'react'
import ShareModal from "../Ui/ShareModalUi";
import { useSelector } from "react-redux";
import { message } from 'antd';

export default function SharingModal(props) {        
    const chapters = useSelector((state) => state.chapters);
    const selectedTranslations = useSelector((state) => state.settings.selectedTranslations);
    const tanslatorNames = useSelector((state) => state.chapters.translators);
    const selectedTranslationObjects = tanslatorNames.filter(item => selectedTranslations.includes(item.id));
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState(selectedTranslationObjects);

  const newItem = {
    "id": 0,
    "language": props.t('share-arabic'),
    "translatorName": props.t('share-arabic-verse'),
    "engName": "",
    "fileId": ""
  };

  const addNewItemToData = () => {
    if (!data.some(item => item.id === 0)) {
      setData((prevData) => [newItem, ...prevData]);
    }
  };
    addNewItemToData();

  
  const handleTranslationSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filter the data based on the searchQuery
  const filteredTranslationDataForSearch = data.filter((item) => {
    const { engName, translatorName, language } = item;
    const lowerQuery = searchQuery.toLowerCase();

    return (
      engName.toLowerCase().includes(lowerQuery) ||
      translatorName.toLowerCase().includes(lowerQuery) ||
      language.toLowerCase().includes(lowerQuery)
    );
  });

  
  const HandleTranslationCheckboxChange = (translatorId) => {
    if (props.translationForShare.includes(translatorId)) {
        props.setTranslationForShare(props.translationForShare.filter(id => id !== translatorId));
    } else {
      if (props.translationForShare.length >= 3) {
        message.warning(props.t('maximum-3-items'));
        return
      }
      props.setTranslationForShare([...props.translationForShare, translatorId]);
    }

  };

  useEffect(() => {
    props.setTranslationForShare([])
    },
    // eslint-disable-next-line
    [])

  const onShareVerse = () => {
    if (props.translationForShare.length === 0) {
      message.warning(props.t('minimum-1-item'));
      return
    }
    props.setIsShareModalOpen(false);
    props.setSocialMediaModal(true)
  };

    return (
        <ShareModal  
        t={props.t} onClose={props.onClose}
        chapters={chapters}
        singleVerse={props.singleVerse}
        selectedTranslationObjects={selectedTranslationObjects}
        handleTranslationSearch={handleTranslationSearch}
        setSearchQuery={setSearchQuery}
        filteredTranslationDataForSearch={filteredTranslationDataForSearch}
        HandleTranslationCheckboxChange={HandleTranslationCheckboxChange}
        translationForShare={props.translationForShare}
        onShareVerse={onShareVerse}
        setSocialMediaModal={props.setSocialMediaModal}        
         />

    )
}
