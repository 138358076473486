export const convertToSearchableChapters = (data) => {
    if (!data || !data.chapters || !Array.isArray(data.chapters)) {
      return [];
    }
    return data.chapters.map((chapter) => {
      return {
        value: chapter.chapterNo,
        label: chapter.alphaArabicName,
        // label: chapter.alphaArabicName.replace(/-/g, '- '),

      };
    });
  };

  export const convertToSearchableVerses = (data,selectedSurah) => {
    if (!data || !data.chapters || !Array.isArray(data.chapters)) {
      return [];
    }
    let totalVerses = data.chapters[parseInt(selectedSurah) - 1];
    if (totalVerses) {
      totalVerses = totalVerses.totalVerses;
    }
    const options = Array.from({ length: totalVerses }, (_, index) => index + 1).map((value) => ({
      value,
      label:  value.toString()
    }));

    return options;
  }