import { React, useState, useEffect } from 'react'
import AllBookmarksUi from "../../Ui/Bookmarks/AllBookmarksUi"
import { useTranslation } from "react-i18next";
import { findAllBookmarks } from "../../../../apis/FetchData";
import Book from "../../../../assets/images/collection/colletion-book.svg"
import BookWhite from "../../../../assets/images/collection/colletion-book-white.svg"

export default function AllBookmarks(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const BookIcon = props.userTheme === 'Dark' ? BookWhite : Book;

  const fetchBookmarks= async () => {
    setLoading(true);
    let result = await findAllBookmarks();
    if (result && result.status === 200) {
      setBookmarks(result.data)
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBookmarks();
  }, []);


  return (
    <AllBookmarksUi t={t} setLoading={setLoading} loading={loading} bookmarks={bookmarks} BookIcon={BookIcon}
     />
  );
}