import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  systemTheme: "",
    userTheme: "Auto",
    firebaseToken:null,
    lastRead: null,
    language: "en",
    arabicFontSize: '1.62500',//rem
    arabicFontText: 'Small',
    translationFontSize: '1.25',
    translationFontText: 'Small',
    script: "text",
    selectedTranslations:[],
    selectedReciterId:null,
    selectedReciterName:'',
    jumpToVerse:true,
    showTranslation:true,
    playingSurah:1,
    playingVerse:1,
    shuffle:3,
    playState:false,
    rootWordsCount:0,
    rootWord:null,
    historyList:[],
    showTranslationCollectionDetails:true,
    showNotesCollectionDetails:true,
    beforeAfterVerseCollectionDetails:'2',
};

export const SettingsReducer = createSlice({
  name: "settingsSlice",
  initialState: initialState,
  reducers: {
    updateSettings: (state, action) => {
      // Modify the existing state in place
      Object.assign(state, action.payload);
    },
    resetSettings: (state) => {
      // Reset the state to its initial values
      Object.assign(state, initialState);
    },
  },
});
