import React from 'react';
import 'reactjs-popup/dist/index.css'; // Import the CSS for reactjs-popup
import PlayVerse from "../../../../assets/images/PlayVerse.svg";
import PauseVerseWhite from "../../../../assets/images/pauseWhiteAndBlack.svg";
import Grammar from "../../../../assets/images/Grammar.svg";
import BookmarkPink from "../../../../assets/images/bookmark_pink.e35c4959.svg"
import NotesPink from "../../../../assets/images/note_pink.e26f8117.svg"
import Loading from '.././Loading';
import AudioBar from "../../Logic/AudioBar";
import GrammerModal from '../../../Modals/Logic/Grammer'
import DeleteBookmarlConfirmationModal from '../../../Modals/Ui/DeleteBookmarkConfirmation'
import DeleteConfirmationModal from '../../../Modals/Logic/DeleteConfirmationNotes'
import AddTooltips from '../../Logic/Home/AddSection/AddTooltip'
import DisplayShare from '../../Logic/Home/AddSection/DisplayShare'
import NotesModal from '../../../Modals/Logic/Notes'
import FooterCode from "../../Logic/FooterLogic"
import { getTranslationDirection } from '../../../../assets/js/translationDirection'

export default function Home(props) {
  return (
    <>
      <div className={`m-auto all_verses `} id="chapterContent" onScroll={props.handleScroll}>
        <div ref={props.topOfPageRef} id='TopOfThePage'></div>
        {props.loading && <Loading />}
        <div className='page-wrap'>
          <div className=" d-flex w-100 justify-content-center">
            {props.selectedSurah ?
              <img data-bs-theme="dark" alt={"surah name"} className={"vw-12 "} height="150px" width="150px" backgroundcolor="#E84778" style={{ filter: 'var(--special-filter)' }} src={props.selectedSurah ? require(`../../../../assets/images/chapters/${props.selectedSurah}.svg`) : require('../../../../assets/images/chapters/1.svg')} />
              : ''
            }
          </div>
          {props.FirstVerse && props.selectedVerse === 1 && props.selectedSurah !== 1 &&
            <div ref={props.playingVerse === 0 ? props.playingVerseRef : null} className={`w-100 d-flex justify-content-center verses-body ${props.playingVerse === 0 ? 'playing-verse' : ''}`} >
              <div id="verse-cell-blessing" className={`row w-100 pl-3 py-3 d-flex flex-row cell-border-bottom-blessing `} style={{ direction: 'rtl' }} >
                {
                  props.FirstVerse && props.FirstVerse[props.scriptStyle].split(' ').map((singleWord, index1) => {
                    props.wordRefs.current[index1] = React.createRef();
                    return (
                      <div
                        key={'word__' + index1}
                        ref={props.wordRefs.current[index1]}
                        style={{ fontSize: props.arabicFontSize + 'rem' }}
                        className={`elementHover ${props.showVerseHoverPopup ? 'hovered' : ''} text-right w-auto align-self-center m-1 p-0 ${props.playingVerse === 0 ? 'verse_text_play' : ''} ${props.scriptStyle} Khebrat_Musamim_Bold`} aria-describedby="popup-2908">
                        {singleWord}
                      </div>
                    );
                  })
                }
                {props.showTranslation && props.FirstVerse.translations && props.FirstVerse.translations.length > 0 && props.FirstVerse.translations.map((translation, translation_id) => (
                  <div key={"translation__" + translation_id} className=" row w-100  d-flex flex-column  " style={{ direction: "ltr" }}>
                    <span className="m-0  w-100  translation_name py-2 " style={{ fontSize: props.translationFontSize + "rem" }}>
                      {translation.translator.translatorName}
                    </span>
                    <label className="m-0 w-100 translation_text py-2 " style={{ fontSize: props.translationFontSize + "rem" }} >
                      {translation.translationText}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          }

          {props.allVerses.length > 0 && props.allVerses.map((singleVerse, index) => {
            const isLastIndex = index === props.allVerses.length - 1;
            return (
              <div key={singleVerse.verseNo + 'v'} id={singleVerse.verseNo} ref={props.playingSurah === singleVerse.chapterNo && props.playingVerse === singleVerse.verseNo ? props.playingVerseRef : null} className={`w-100 d-flex justify-content-center  verses-body  ${props.playingVerse === singleVerse.verseNo ? 'playing-verse' : ''}`} >
                <div className={`tooltipBoundary verse_options_list_width    d-flex flex-column  align-items-center  py-3 ${isLastIndex ? '' : 'cell-border-bottom'}`}>
                  <label className={` word_count_text px-1 specialAreaColor text-white-black-theme`}> {singleVerse.chapterNo + ":" + singleVerse.verseNo} </label>
                  <img onClick={() => props.callPlayFunction(singleVerse.chapterNo, singleVerse.verseNo)} title={props.t('play')} className="verse_option_icon  my-2 cursor_pointer my-2" src={(props.playState && props.playingVerse === singleVerse.verseNo && props.playingSurah === singleVerse.chapterNo) ? PauseVerseWhite : PlayVerse} alt="play" />
                  <img onClick={() => props.copyVerse(singleVerse.text)} title={props.t('copy-verse')} className="verse_option_icon  my-2 cursor_pointer  my-2" src={props.CopyVerse} alt="copy" />
                  <DisplayShare loading={props.loading} setLoading={props.setLoading} singleVerse={singleVerse} t={props.t} />
                  <img onClick={() => props.openGrammerModal(props.allVerses, singleVerse.verseNo)} title={props.t('gammar')} className="verse_option_icon  my-2 cursor_pointer my-2" src={Grammar} alt="grammar" />
                  <AddTooltips allVerses={props.allVerses} loading={props.loading} setLoading={props.setLoading} user={props.user} showTooltip={props.showTooltip} setShowTooltip={props.setShowTooltip} setActiveImageIndex={props.setActiveImageIndex} activeImageIndex={props.activeImageIndex} closeGrammerModal={props.closeGrammerModal} callPlayFunction={props.callPlayFunction} WordPoup={props.WordPoup} handlePlusClick={props.handlePlusClick} index={index} singleVerse={singleVerse} t={props.t} chapters={props.chapters} setIsDeleteConfirmationsModalOpen={props.setIsDeleteConfirmationsModalOpen} setIsNotesModalOpen={props.setIsNotesModalOpen} closeNotesModal={props.closeNotesModal} NotesData={props.notesData} setNotesData={props.setNotesData} />
                </div>
                <div id="verse-cell" className={`w-100 pl-3 py-3 d-flex  flex-column  ${isLastIndex ? '' : 'cell-border-bottom'}`}>
                  <div className="d-flex flex-row m-0 p-0">
                    {(singleVerse.notes.length > 0 || (singleVerse.temporaryNotes && singleVerse.temporaryNotes.length > 0)) && (
                      <img
                        // onClick={() => props.onDeleteNotesBadge(singleVerse)} 
                        onClick={() => props.AddToNotes(singleVerse)}
                        alt='' className=" size-24-24 cursor_pointer " src={NotesPink} />
                    )}
                    {singleVerse.bookmark.length > 0 && (
                      <img onClick={() => props.bookmarkDeleteConfirmation(singleVerse.id)} alt='' className="size-24-24 ml-2 cursor_pointer" src={BookmarkPink} />
                    )}
                  </div>
                  <div className="d-flex flex-row-reverse justify-content-start py-2 flex-wrap">
                    <div>
                    </div>
                    {singleVerse[props.scriptStyle].split(" ").map(word => word.trim()).filter(word => word !== "").map((singleWord, index1) => {
                    props.wordRefs.current[index1] = React.createRef();
                    <div id="append-popup-here"></div>
                    return (
                      <>
                        <div key={"word__" + index1} ref={props.wordRefs.current[index1]} onMouseEnter={() => props.togglePopupOverVerse(index1, index)} onMouseLeave={() => props.handleMouseLeave()}
                          style={{ fontSize: props.arabicFontSize + "rem", }} className={` elementHover  ${props.showVerseHoverPopup ? 'hovered' : ''} text-right  w-auto  align-self-center pl-2 
                          ${props.playingSurah === singleVerse.chapterNo && props.playingVerse === singleVerse.verseNo ? 'verse_text_play' : ''}    ${props.scriptStyle}  Khebrat_Musamim_Bold`} aria-describedby="popup-2908" >
                          {singleWord}
                          {props.activeVerseWordIndex === index1 && props.activeVerseIndex === index && (
                            <span className="tooltipVerseHoverText" >{singleVerse.versewordbywordTranslation !== null && singleVerse.versewordbywordTranslation !== undefined ? props.WordPoup(singleWord, singleVerse.versewordbywordTranslation, index1,singleVerse[props.scriptStyle]) : ''}</span>
                          )}
                        </div>
                      </>
                    );
                  })}

                    {/* {
                      props.scriptStyle === 'textIndopak'
                        ? props.indoPakVerses[index]['text_indopak']
                          .split(" ")
                          .map(word => word.trim())
                          .filter(word => word !== "")
                          .map((singleWord, index1) => {
                            props.wordRefs.current[index1] = React.createRef();
                            // Your logic for each word in IndoPak style goes here
                            return (
                              <>
                                <div key={"word__" + index1} ref={props.wordRefs.current[index1]} onMouseEnter={() => props.togglePopupOverVerse(index1, index)} onMouseLeave={() => props.handleMouseLeave()}
                                  style={{ fontSize: props.arabicFontSize + "rem", }} className={` elementHover  ${props.showVerseHoverPopup ? 'hovered' : ''} text-right  w-auto  align-self-center pl-2 
              ${props.playingSurah === singleVerse.chapterNo && props.playingVerse === singleVerse.verseNo ? 'verse_text_play' : ''}    ${props.scriptStyle}  `} aria-describedby="popup-2908" >
                                  {singleWord}
                                  {props.activeVerseWordIndex === index1 && props.activeVerseIndex === index && (
                                    <span className="tooltipVerseHoverText" >{singleVerse.versewordbywordTranslation !== null && singleVerse.versewordbywordTranslation !== undefined ? props.WordPoup(singleWord, singleVerse.versewordbywordTranslation, index1, props.indoPakVerses[index]['text_indopak']) : ''}</span>
                                  )}
                                </div>
                              </>
                            );
                          })
                        : singleVerse[props.scriptStyle]
                          .split(" ")
                          .map(word => word.trim())
                          .filter(word => word !== "")
                          .map((singleWord, index1) => {
                            props.wordRefs.current[index1] = React.createRef();
                            // Your logic for each word in singleVerse style goes here
                            return (
                              <>
                                <div key={"word__" + index1} ref={props.wordRefs.current[index1]} onMouseEnter={() => props.togglePopupOverVerse(index1, index)} onMouseLeave={() => props.handleMouseLeave()}
                                  style={{ fontSize: props.arabicFontSize + "rem", }} className={` elementHover  ${props.showVerseHoverPopup ? 'hovered' : ''} text-right  w-auto  align-self-center pl-2 
              ${props.playingSurah === singleVerse.chapterNo && props.playingVerse === singleVerse.verseNo ? 'verse_text_play' : ''}    ${props.scriptStyle}  `} aria-describedby="popup-2908" >
                                  {singleWord}
                                  {props.activeVerseWordIndex === index1 && props.activeVerseIndex === index && (
                                    <span className="tooltipVerseHoverText" >{singleVerse.versewordbywordTranslation !== null && singleVerse.versewordbywordTranslation !== undefined ? props.WordPoup(singleWord, singleVerse.versewordbywordTranslation, index1, singleVerse[props.scriptStyle]) : ''}</span>
                                  )}
                                </div>
                              </>
                            );
                          })
                    } */}

                    <label className="text-right  w-auto  align-self-center simple-font-family" role="button" style={{ fontSize: props.arabicFontSize + "rem", fontFamily: "simple_script_font" }}>
                      {" \uFD3E" +
                        singleVerse.verseNo.toLocaleString("ar-EG") +
                        "\uFD3F"}
                    </label>
                  </div>
                  {/* Loop the translations here */}
                  {props.showTranslation && singleVerse.translations.map((translation, translation_id) => {
                    const direction = getTranslationDirection(translation.translationText)
                     // Create a new DOMParser
                    const parser = new DOMParser();

                    // Parse the HTML string
                    const doc = parser.parseFromString(translation.translationText, 'text/html');

                    // Extract the text content
                    const textTranslationContent = doc.body.textContent || "";
                    return (
                      <>
                        <div key={"translation__" + translation_id} className=" w-100  d-flex flex-column" >
                          <span className="m-0 w-100 translation_name py-2 translation-text-alignment " data-direction={direction} style={{ fontSize: props.translationFontSize + "rem" }}>
                            {translation.translator.translatorName}
                          </span>
                          <label className="m-0 w-100 py-2 translation_text translation-text-alignment" data-direction={direction} style={{ fontSize: props.translationFontSize + "rem" }} >
                          {textTranslationContent}
                          </label>
                        </div>
                      </>

                    );
                  })}
                </div>
              </div>
            );
          })
          }
          {props.isGrammerModalOpen && props.chapters && <GrammerModal t={props.t} chapters={props.chapters} Grammers={props.grammerModalVerse} grammerModalVerseId={props.grammerModalVerseId} updateGrammerVerseId={props.updateGrammerVerseId} onClose={props.closeGrammerModal} />}
          {props.isBookmarkConfirmationsModalOpen && <DeleteBookmarlConfirmationModal t={props.t} RemoveBookmark={props.RemoveBookmark} DeleteBookmarkId={props.deleteNotesId} onClose={props.closeBookmarkDeleteConfirmationModal} />}
          {props.isDeleteConfirmationsModalOpen && <DeleteConfirmationModal t={props.t} allVerses={props.allVerses} NotesData={props.notesData} DeleteNotesId={props.notesData.id} setIsDeleteConfirmationsModalOpen={props.setIsDeleteConfirmationsModalOpen} onClose={props.closeDeleteConfirmationModal} setLoading={props.setLoading} />}
          {props.isNotesModalOpen && props.chapters && <NotesModal t={props.t} allVerses={props.allVerses} NotesData={props.notesData} setDeleteNotesId={props.setDeleteNotesId} setLoading={props.setLoading} chapters={props.chapters} setIsDeleteConfirmationsModalOpen={props.setIsDeleteConfirmationsModalOpen} setIsNotesModalOpen={props.setIsNotesModalOpen} closeNotesModal={props.closeNotesModal} />}
        </div>
        <FooterCode />
        <AudioBar ref={props.childRef} />


      </div>
    </>
  )
}


