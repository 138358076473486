import { React, useState, useEffect } from 'react'
import AllNotesUi from "../../Ui/Notes/AllNotesUi"
import { useTranslation } from "react-i18next";
import { findAllNotes } from "../../../../apis/FetchData";
import { useSelector } from "react-redux";
import Book from "../../../../assets/images/collection/colletion-book.svg"
import BookWhite from "../../../../assets/images/collection/colletion-book-white.svg"
import EditB from "../../../../assets/images/collection/edit.svg"
import DeleteB from "../../../../assets/images/collection/delete.svg"
import JumptoB from "../../../../assets/images/collection/jump_to.svg"
import EditPink from "../../../../assets/images/collection/editPink.svg"
import DeletePink from "../../../../assets/images/collection/deletePink.svg"
import JumptoPink from "../../../../assets/images/collection/jump_toPink.svg"

export default function Notes(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmationsModalOpen, setIsDeleteConfirmationsModalOpen] = useState(false);
  const [editDeleteId, setEditDeleteId] = useState(false);
  const [deletedSuccess, setDeletedSuccess] = useState(true);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const EditIcon = props.userTheme === 'Dark' ? EditPink : EditB;
  const DeleteIcon = props.userTheme === 'Dark' ? DeletePink : DeleteB;
  const JumptoIcon = props.userTheme === 'Dark' ? JumptoPink : JumptoB;
  const BookIcon = props.userTheme === 'Dark' ? BookWhite : Book;
  const [notes, setNotes] = useState([]);
  const scriptStyle = useSelector((state) => state.settings.script);
  const arabicFontSize = useSelector((state) => state.settings.arabicFontSize);

  const fetchNotes= async () => {
    setLoading(true);
    let result = await findAllNotes();
    if (result && result.status === 200) {
      setNotes(result.data)
    }
    setLoading(false);
    setDeletedSuccess(false)
  };

  useEffect(() => {
    if(deletedSuccess){
      fetchNotes();
    }
    
  }, [deletedSuccess]);


  const DeleteNotes = (delete_id) => {
    setIsDeleteConfirmationsModalOpen(true)
    setEditDeleteId(delete_id)
  }
  const onClose = () => {
    setIsDeleteConfirmationsModalOpen(false)
  }
  const EditNotes = (edit_data) => {
    setIsNotesModalOpen(true)
    setEditDeleteId(edit_data)
  }
  const closeNotesModal = () => {
    setIsNotesModalOpen(false)
  }
  

  return (
    <AllNotesUi t={t} setLoading={setLoading} loading={loading} notes={notes}
      scriptStyle={scriptStyle} arabicFontSize={arabicFontSize} DeleteNotes={DeleteNotes}
      isDeleteConfirmationsModalOpen={isDeleteConfirmationsModalOpen} deleteId={editDeleteId}
      setIsDeleteConfirmationsModalOpen={setIsDeleteConfirmationsModalOpen}
      onClose={onClose} setDeletedSuccess={setDeletedSuccess} isNotesModalOpen={isNotesModalOpen}
      EditNotes={EditNotes} setIsNotesModalOpen={setIsNotesModalOpen} closeNotesModal={closeNotesModal}
      BookIcon={BookIcon} Edit={EditIcon} Delete={DeleteIcon} Jumpto={JumptoIcon}
     />
  );
}