import React from 'react';
import LastReadIcon from '../../../../../assets/images/LastRead.svg'

export default function LastReadUi(props) {
  return (
    <div onClick={() => props.AddToLastRead(props.singleVerse.chapterNo, props.singleVerse.verseNo)} className="d-flex flex-row w-100 mt-3 cursor_pointer  px-1">
      <img alt="" className=" verse_option_icon cursor_pointer" src={LastReadIcon} />
      <span className="login_popup_description text-left my-0 ml-2"> {props.t("last-read")}</span>
    </div>
  )
}


