import React from 'react'
import { message } from 'antd';
import { deleteVerseFromCollection } from '../../../apis/PostData'
import DeleteVerseCollectionConfirmation from '../../Modals/Ui/DeleteVerseCollectionConfirmationUi'

export default function DeleteVerseConfiramtionOfCollection(props) {
    const onDeleteVerseCollection = async () => {
        props.onCloseDelete()
        let result = await deleteVerseFromCollection(props.deleteVerseIdofCollection);
        if (result && result.status===200) {
            message.success(props.t('verse-deleted-collection-success'));
            props.setDeleted(true)
        }
    };
    return (
     <DeleteVerseCollectionConfirmation t={props.t}  onDeleteVerseCollection={onDeleteVerseCollection} onCloseDelete={props.onCloseDelete}/>
    )
}
