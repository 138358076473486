import React from 'react'
import Logout from "../../../assets/images/logout_blue.svg";
import Bookmark from "../../../assets/images/bookmark_empty.svg";
import Notes from "../../../assets/images/notes_blue.svg";
import Collection from "../../../assets/images/collection_blue.svg";
import ClickAwayListener from 'react-click-away-listener';

export default function AccountDropDown(props) {
    return (
        <ClickAwayListener onClickAway={props.handleClickAway}>
            <div className='px-1 mt-1 clickAway' >
                <label className={`language-name mobile-hidden SF_UI_Text mr-2 cursor_pointer dropbtn text-white-black-theme `} onClick={e => props.handleClickListItem(e)} >
                    {props.user.displayName.split(' ').slice(0, 2).join(' ').length > 14
                        ? `${props.user.displayName.split(' ').slice(0, 1).join(' ')}`
                        : props.user.displayName.split(' ').slice(0, 2).join(' ')
                    }
                </label>
                <div className="dropdown">
                    <div onClick={e => props.handleClickListItem(e)} className="dropbtn">
                        <img className='user_avatar' src={props.user.photoURL} alt={props.user.photoURL} />
                    </div>
                    <div id="myDropdown" onBlur={e => props.handleClose(false)} className={`login-menu margin8  align-items-center py-1 ${props.anchorEl ? "login-show" : ""}`} >
                        <div className={` ArrowWhite`} ></div>

                        <div className=" div-name menu_item_row menu_item_row_bottom_border d-flex flex-column row m-0 py-3 ">
                            <div className="d-flex mx-0 flex-row w-100">
                                <img
                                    className="login_menu_user_avatar"
                                    alt={props.user.photoURL}
                                    src={props.user.photoURL} />
                                <label className={` SF_UI_Text name-heading font-size-13-500 nowrap m-0 ml-2 px-2 text-white-black-theme mb-1 `} >
                                    {props.user.displayName}
                                </label>
                            </div>
                            <div className="SF_UI_Text font-size-11-400 d-flex div-email flex-column ml-3 w-100">
                                <label
                                    className={`m-0 ml-4 px-3 text-white-gray-2-theme overflow-break-word`}>
                                    {props.user.email ? props.user.email : props.user.phoneNumber}
                                </label>
                            </div>
                        </div>
                        <div onClick={() => { props.handleClickAway(); props.navigate('/collections/'); }} className="menu_item_row px-4 mt-2 cursor_pointer">
                            <img
                                className=" login_menu_item_icon cursor_pointer mt-1 "
                                src={Collection} alt="Collection" />
                            <label
                                className={`SF_UI_Text font-size-14-400  nowrap px-2 cursor_pointer mt-1 text-white-gray-3-theme `}
                                role="button"  >
                                {props.t('collections')}
                            </label>
                        </div>
                        <div onClick={() => { props.handleClickAway(); props.navigate('/bookmarks/'); }} className="menu_item_row px-4 cursor_pointer">
                            <img
                                className="SF_UI_Text login_menu_item_icon cursor_pointer mt-1"
                                src={Bookmark} alt="Bookmark" />
                            <label
                                className={`SF_UI_Text font-size-14-400 nowrap px-2 cursor_pointer mt-1 text-white-gray-3-theme `}
                                role="button" >
                                {props.t('bookmark')}
                            </label>
                        </div>
                        <div onClick={() => { props.handleClickAway(); props.navigate('/notes/'); }} className="menu_item_row px-4 cursor_pointer">
                            <img
                                className=" login_menu_item_icon cursor_pointer mt-1"
                                src={Notes} alt="Notes" />
                            <label
                                className={`SF_UI_Text font-size-14-400 nowrap px-2 cursor_pointer mt-1 text-white-gray-3-theme`}

                                role="button"  >
                                {props.t('notes')}
                            </label>
                        </div>

                        <div onClick={e => props.handleLogout(e)} className="menu_item_row  menu_item_row_top_border mt-2 justify-content-center cursor_pointer">
                            <img
                                className=" login_menu_item_icon cursor_pointer mt_25"
                                src={Logout} alt="Logout" />
                            <label
                                className={`SF_UI_Text font-size-14-400 nowrap text-uppercase mb-0 px-2 mt_25 cursor_pointer text-white-black-theme `}
                                role="button">
                                {props.t('logout')}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </ClickAwayListener>

    )
}
