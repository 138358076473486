import React, { useState,useEffect } from 'react'
import { message } from 'antd';
import { deleteNotes } from '../../../apis/PostData'
import {FindAllTranslations} from "../../../apis/FetchData";
import DeleteNotesConfirmation from '../../Modals/Ui/DeleteConfirmationNotesUi'

export default function DeleteConfiramtionOfNotes(props) {
    // const [notesIdToDelete, setNotesIdToDelete] =useState(props.NotesData.notes[0]?.id || '');
    const [notesIdToDelete, setNotesIdToDelete] = useState(props.NotesData?.notes?.[0]?.id || props.DeleteNotesId || '' );
    const fetchNotesData = async() => {
        if (!notesIdToDelete)
        {
            props.setLoading(true);
            let result = await FindAllTranslations(props.NotesData.chapterNo, props.NotesData.verseNo, props.selectedReciterId, props.selectedTranslations);
            result=result.data.verse[0].notes
            if (result.length>0){
                setNotesIdToDelete(result[0].id)
            }
            props.setLoading(false);
        }
    };

    useEffect(()=>{
        fetchNotesData()
    },    
    // eslint-disable-next-line
    [])

    const onDeleteNotes = async () => {
        props.setLoading(true);
        let result = await deleteNotes(notesIdToDelete);//Fetch and enter notes id here to delete
        if (result && result.status===200) {
            if(props.previousPage!=="AllNotes")
            {
                const verseToUpdate = props.allVerses.find((verse) => verse.id === props.NotesData.id);
                if(verseToUpdate.notes.length>0){
                    verseToUpdate.notes= []
                }  
                if (verseToUpdate.temporaryNotes && verseToUpdate.temporaryNotes.length>0){
                    delete verseToUpdate.temporaryNotes;
                }
            }
            else{
                props.setDeletedSuccess(true)
            }
            
            props.setLoading(false);
            message.success(props.t('delete-note-success-msg'));
        }
        props.onClose()
    };

    return (
     <DeleteNotesConfirmation t={props.t} onDeleteNotes={onDeleteNotes} onClose={props.onClose}/>
    )
}
