import { React, useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { fetchSearchResult, fetchRootSearchResult } from "../../../apis/FetchData";
import { useDispatch,useSelector } from "react-redux";
import SearchUi from "../Ui/SearchUi"
import { SettingsReducer } from "../../../redux/Setting"
const SettingActions = SettingsReducer.actions;

export default function Search(props) {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState('');
    const { searchKeyword } = useParams();
    const [searchResult, setSearchResult] = useState('');
    const [searchRootWordResult, setSearchRootWordResult] = useState([]);
    const [occurance, setOcurrance] = useState(0);
    const [rootWordSearch, setRootWordSearch] = useState(false)
    const [expandedSections, setExpandedSections] = useState([]);
    const [rootWordDivVisibility, setrootWordDivVisibility] = useState([]);
    const [sectionSearchResultsVisibility, setSectionSearchResultsVisibility] = useState([]);
    const [loading, setLoading] = useState(true);
    const scriptStyle = useSelector((state) => state.settings.script);
    let logicalScriptStyleForSearch = scriptStyle==='textIndopak'? 'textUthmani':scriptStyle
    const arabicFontSize = useSelector((state) => state.settings.arabicFontSize);



    const searchInVerses = useCallback(async (searchingWord) => {
        setLoading(true);
        if (searchingWord) {
            let result = await fetchSearchResult(decodeURIComponent(searchingWord));
            if (result && result.status) {
                setSearchResult(result.data.Quranicscript.verse);
                setOcurrance(result.data.Quranicscript.verse.length);
                setLoading(false);
            }
        }
    }, []);

    const searchRootWord = useCallback(async () => {
        if (keyword) {
            setLoading(true);
            let result = await fetchRootSearchResult(decodeURIComponent(keyword));
            if (result && result.status) {
                setSearchRootWordResult(result.data.words);
                dispatch(SettingActions.updateSettings({ rootWordsCount: result.data.count }));
                setLoading(false);

            }
        }
    }, [keyword, dispatch]);


    useEffect(() => {
        if (searchKeyword) {
            if (searchKeyword.startsWith('rootword:')) {
                setKeyword(searchKeyword.split(':')[1]);
                dispatch(SettingActions.updateSettings({ rootWord: searchKeyword.split(':')[1] }));
                setRootWordSearch(true)
                searchRootWord()
            }
            else {
                
                if (searchKeyword.includes(':')) {
                    setKeyword(searchKeyword.split(':')[1]);
                }
                else {
                    setKeyword(searchKeyword);
                }
                setRootWordSearch(false)
                dispatch(SettingActions.updateSettings({ rootWordsCount: 0 }));
                dispatch(SettingActions.updateSettings({ rootWord: null }));
                setSearchRootWordResult([searchKeyword]);
                searchInVerses(searchKeyword)
                
            }
        }
    }, [searchKeyword, dispatch,rootWordSearch,setRootWordSearch,searchInVerses,searchRootWord]);

    const toggleSearchResultVisibility = (index, searchingWord) => {
        setSearchResult('');
        if (expandedSections.includes(index)) {
            setExpandedSections([]);
            setSectionSearchResultsVisibility([]); // Hide search results for all sections
        } else {
            searchInVerses(searchingWord);
            setExpandedSections([index]);
            setSectionSearchResultsVisibility([index]); // Show search results for this section
        }
    };
    useEffect(() => {
        setrootWordDivVisibility(new Array(searchRootWordResult.length).fill(true));
    }, [searchRootWordResult]);

    const toggleDivVisibility = (index) => {
        const updatedDivVisibility = [...rootWordDivVisibility];
        updatedDivVisibility[index] = !updatedDivVisibility[index];
        setrootWordDivVisibility(updatedDivVisibility);
    };

    const removeDiacritics = (text) => {
        return text.normalize('NFD').replace(/[\u064B-\u065F-\u064C-\u064D-\u064E-\u064F-\u0650-\u0651-\u0652-\u0653-\u0654-\u0655-\u0656-\u0657-\u0658-\u0659-\u065A-\u065B-\u065C-\u065D-\u065E-\u065F-\u0660-\u066B-\u066C-\u0670-\u06D6-\u06D7-\u06D8-\u06D9-\u06DA-\u06DB-\u06DC-\u06DF-\u06E0-\u06E1-\u06E2-\u06E3-\u06E4-\u06E5-\u06E6-\u06E7-\u06E8-\u06E8-\u06EC-\u06ED-\u06F0-\u06FD-\u06FE-\u06FF]/g, '');
      };
      const noAccentOrigin = {
        'ك': 'ک',
        'ﻷ': 'لا',
        'ؤ': 'و',
        'ى': 'ی',
        'ي': 'ی',
        'ئ': 'ی',
        'أ': 'ا',
        'إ': 'ا',
        'آ': 'ا',
        'ٱ': 'ا',
        'ٳ': 'ا',
        'ة': 'ه',
        'ء': '',
        'ِ': '',
        'ْ': '',
        'ُ': '',
        'َ': '',
        'ّ': '',
        'ٍ': '',
        'ً': '',
        'ٌ': '',
        'ٓ': '',
        'ٰ': '',
        'ٔ': '',
        '�': ''
      };
      
const accentRemover = (str) => {
    return str.replace(/[^\u064B-\u065F-\u064C-\u064D-\u064E-\u064F-\u0650-\u0651-\u0652-\u0653-\u0654-\u0655-\u0656-\u0657-\u0658-\u0659-\u065A-\u065B-\u065C-\u065D-\u065E-\u065F-\u0660-\u066B-\u066C-\u0670-\u06D6-\u06D7-\u06D8-\u06D9-\u06DA-\u06DB-\u06DC-\u06DF-\u06E0-\u06E1-\u06E2-\u06E3-\u06E4-\u06E5-\u06E6-\u06E7-\u06E8-\u06E8-\u06EC-\u06ED-\u06F0-\u06FD-\u06FE-\u06FF]/g, (a) => {
      return noAccentOrigin[a] === undefined ? a : noAccentOrigin[a];
    });
  };
  const cleanArabicString = (str1) => {
    const normalizedStr1 = accentRemover(str1.normalize('NFKD').toLowerCase());
    return normalizedStr1;
  };
  const compareStrings = (str1, str2) => {
    const normalizedStr1 = accentRemover(str1.normalize('NFKD').toLowerCase());
    const normalizedStr2 = accentRemover(str2.normalize('NFKD').toLowerCase());
    return normalizedStr1 === normalizedStr2;
  };

    return (
    <SearchUi searchResult={searchResult} cleanArabicString={cleanArabicString} compareStrings={compareStrings} searchRootWordResult={searchRootWordResult} occurance={occurance}
    rootWordSearch={rootWordSearch} expandedSections={expandedSections} rootWordDivVisibility={rootWordDivVisibility}
    sectionSearchResultsVisibility={sectionSearchResultsVisibility} loading={loading} toggleSearchResultVisibility={toggleSearchResultVisibility}
    toggleDivVisibility={toggleDivVisibility} scriptStyle={logicalScriptStyleForSearch} removeDiacritics={removeDiacritics}
    arabicFontSize={arabicFontSize}

    />
    );
}