import React from 'react'
import ArrowUp from '../../../assets/images/arrow_up_black.svg'
import ArrowDown from '../../../assets/images/arrow_down_black.svg'
import Loading from './Loading';
import FooterCode from "../Logic/FooterLogic"

export default function Search(props) {


    return (
        <div className="">
            {props.loading && <Loading />}
            <div className="d-flex flex-column padding-search-section-4-1 py-4 page-wrap">
                {props.rootWordSearch &&
                    props.searchRootWordResult &&
                    props.searchRootWordResult.length > 0 && (
                        <div className="search-rood-container mb-1">
                            {props.searchRootWordResult.map((word, index) => (
                                <label
                                    key={index + "S3"}
                                    onClick={() => props.toggleDivVisibility(index)}
                                    className={`font-size-16-400 ml-2 p-2  ${props.rootWordDivVisibility[index] ? 'bg-color-gray4' : 'bg-color-gray3'
                                        }`}
                                    role="button"
                                >
                                    {word}
                                </label>
                            ))}
                        </div>
                    )}
                {props.searchRootWordResult && props.searchRootWordResult.length > 0 && (
                    <div>
                        {props.searchRootWordResult.map((word, index) => (
                            <div key={index + "S4"}
                                className={`d-flex flex-column padding-search-section-4 mt-4 ${props.rootWordDivVisibility[index] ? '' : 'd-none'
                                    }`}
                            >
                                <div
                                    className={`d-flex flex-column my-1 ${props.expandedSections.includes(index) ? 'search-container-expand' : ''
                                        }`}
                                >
                                    <div onClick={() => props.toggleSearchResultVisibility(index, word)} className="px-4 w-100 search-title-container-expand cursor-pointer">
                                        <label className="font-size-15-400 m-0 padding-word-x-3 cursor-pointer ">
                                            <strong className="search-strong ">{word}</strong>
                                            {props.sectionSearchResultsVisibility.includes(index) && props.occurance !== 0 ? (<span> Occurrences: {props.occurance}</span>) : null}
                                        </label>
                                        <img

                                            className="size-20-25 ml-3"
                                            alt=""
                                            src={props.expandedSections.includes(index) ? ArrowUp : ArrowDown}
                                        />
                                    </div>
                                    <div className="d-flex flex-column py-2 w-100">
                                        {props.sectionSearchResultsVisibility.includes(index) && (
                                            <div className="d-flex flex-column p-4 search-data-container">
                                                {props.searchResult &&
                                                    props.searchResult.map((singleVerse, index) => {
                                                        const isLastIndex = index === props.searchResult.length - 1;
                                                        return (
                                                            <div key={index + "S1"} className={`d-flex flex-row  ${isLastIndex ? '' : 'border-bottom'}`}>
                                                                <div className="w-100 pl-3 py-3 d-flex flex-column">
                                                                    <label className="txt-color-gray5 font-size-16-400 text-left">
                                                                        {singleVerse.chapterNo} : {singleVerse.verseNo}
                                                                    </label>
                                                                    <div className="d-flex flex-row-reverse justify-content-start py-2 flex-wrap">

                                                                        {singleVerse[props.scriptStyle].split(' ').map((splitedWord, index) => {
                                                                            const matchCondition1 = props.compareStrings(
                                                                                props.removeDiacritics(splitedWord.toUpperCase()).trim(),
                                                                                props.removeDiacritics(word.toUpperCase()).trim()
                                                                            ) === true;

                                                                            const matchCondition2 = Array.from(word.toUpperCase()).every((letter) =>
                                                                                props.removeDiacritics(splitedWord).includes(
                                                                                    props.removeDiacritics(letter.toUpperCase())
                                                                                )
                                                                            );

                                                                            const allLettersMatched = matchCondition1
                                                                                || matchCondition2;

                                                                            return (
                                                                                <div
                                                                                    key={index + "S2"}
                                                                                    className={` ${props.scriptStyle} text-right w-auto align-self-center pl-2 ${allLettersMatched ? 'txt-color-pink' : 'text-darker'
                                                                                        }`}
                                                                                    style={{ fontSize: props.arabicFontSize + "rem", }}
                                                                                >
                                                                                    {splitedWord}
                                                                                </div>
                                                                            );
                                                                        })}


                                                                        <label
                                                                            className="text-right w-auto align-self-center simple-font-family verse_end medium-aya-font"
                                                                            role="button"
                                                                            style={{ fontSize: 14, fontFamily: 'simple_script_font' }}
                                                                        >
                                                                            {' \uFD3E' +
                                                                                singleVerse.verseNo.toLocaleString('ar-EG') +
                                                                                '\uFD3F'}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <FooterCode />
        </div>
    );
}