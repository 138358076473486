import React from "react";
import DisplayCollectionUi from "../../../Ui/Home/AddSection/DisplayCollectionUi";


const DisplayCollection = (props) => {


 const AddToCollections = (verseId) => {
    props.HideAddPlusPopup()
    props.setNotesData([verseId])
    props.setIsCollectionModalOpen(true);
  };

  return (
    <DisplayCollectionUi
      singleVerse_id={props.singleVerse_id}
      AddToCollections={AddToCollections}
      t={props.t}
      
    />
  );
};

export default DisplayCollection;
