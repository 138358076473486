import { React, useState, useEffect } from 'react'
import AllCollectionsUi from "../../Ui/AllCollections/AllCollectionsUi"
import { useTranslation } from "react-i18next";
import { findAllCollections } from "../../../../apis/FetchData";
import Book from "../../../../assets/images/collection/colletion-book.svg"
import BookWhite from "../../../../assets/images/collection/colletion-book-white.svg"
import CollectionPopupBlack from "../../../../assets/images/collection/collection_popup_icon.svg"
import CollectionPopupPink from "../../../../assets/images/collection/collection_popup_iconPink.svg"
import HalfEyeBlack from "../../../../assets/images/collection/half-eye.svg"
import HalfEyePink from "../../../../assets/images/collection/half-eyePink.svg"
import ThreeDotsBlack from "../../../../assets/images/collection/three_dots_vertical.svg"
import ThreeDotsPink from "../../../../assets/images/collection/three_dots_vertical.svg"
import ArrowUpDownPink from "../../../../assets/images/collection/up-and-downPink.svg"
import ArrowUpDownB from "../../../../assets/images/collection/up-and-down.svg"
import { message } from 'antd';

// import { updateCollection } from '../../../../apis/PostData'

export default function AllCollections(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const CollectionPopup = props.userTheme === 'Dark' ? CollectionPopupPink : CollectionPopupBlack;
  const HalfEye = props.userTheme === 'Dark' ? HalfEyePink : HalfEyeBlack;
  const ThreeDots = props.userTheme === 'Dark' ? ThreeDotsPink : ThreeDotsBlack;
  const BookIcon = props.userTheme === 'Dark' ? BookWhite : Book;
  const ArrowUpDown = props.userTheme === 'Dark' ? ArrowUpDownPink : ArrowUpDownB;
  const [collections, setCollections] = useState([]);
  const [isAddNewCollectionModalOpen, setIsAddNewCollectionModalOpen] = useState(false);
  const [isThreeDotOpen, setIsThreeDotOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [clickedCollection, setClickedCollection] = useState([]);
  const [isDeleteCollectionModalOpen, setIsDeleteCollectionModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortType, setSortType] = useState(false);
  const recordsPerPage = 8;
  const pageNumbers = [];
  const [draggedRow, setDraggedRow] = useState(null);
  const [filteredCollections, setFilteredCollections] = useState(null)

  // const UpdateCollection = async (idToUpdate,data1,idToUpdateWith,data2) => {
  //   // setLoading(true);
  //   let result = await updateCollection(idToUpdate, data1);
  //   let result2 = await updateCollection(idToUpdateWith, data2);
  //   // if (result.status && result2.status  === 200) {
  //   //   setLoading(false);
  //   // }
  // };

  const SortCollection = (sortingType) => {
    setSortType(sortingType)
  }


  useEffect(() => {
    setFilteredCollections(collections.filter((singleCollection) => singleCollection.collectionName.toLowerCase().includes(searchKeyword.toLowerCase())));
  }, [collections, searchKeyword]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
    setDraggedRow(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (index !== draggedRow) {
      const updatedRows = [...filteredCollections];
      const [draggedItem] = updatedRows.splice(draggedRow, 1);
      updatedRows.splice(index, 0, draggedItem);
      setFilteredCollections(updatedRows);
      setDraggedRow(index);
      // const row1= filteredCollections[draggedRow]
      // const row2=filteredCollections[index]
      // const data1 = { "collectionColor":row1.collectionColor, "collectionName": row1.collectionName }
      // const data2 = { "collectionColor": row2.collectionColor, "collectionName": row2.collectionName}
      // UpdateCollection(row2.id,data1,row1.id,data2)
    }
  }


  const AddNewCollection = () => {
    setIsAddNewCollectionModalOpen(true);
    setClickedCollection([])
  };
  const onClose = () => {
    setIsAddNewCollectionModalOpen(false);
    setClickedCollection([])
  };

  const fetchCollections = async () => {
    setLoading(true);
    let result = await findAllCollections(sortType);
    if (result && result.status === 200) {
      setCollections(result.data)
    }
    setLoading(false);
  };
  const HideThreeDotsPopup = () => {
    setIsThreeDotOpen(false);
    setActiveImageIndex(null);

  };

  useEffect(() => {
    if (isAddNewCollectionModalOpen === false && isDeleteCollectionModalOpen === false) {
      fetchCollections();
      fetchCollections();
    }
  },
  // eslint-disable-next-line
  [isAddNewCollectionModalOpen, isDeleteCollectionModalOpen,sortType]);

  useEffect(() => {
    setTotalPages(filteredCollections && Math.ceil(filteredCollections.length / recordsPerPage));
  }, [collections, filteredCollections]);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const ThreeDotsClick = (index, singleCollection) => {
    setIsThreeDotOpen(!isThreeDotOpen);
    setActiveImageIndex(activeImageIndex === index ? null : index);
    setClickedCollection(singleCollection)

  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isThreeDotOpen && activeImageIndex !== null && !e.target.closest('.ThreeDotImgClick-container')) {
        HideThreeDotsPopup()
      }
    };
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  },
    // eslint-disable-next-line
    [isThreeDotOpen, activeImageIndex]);

  const onCloseDelete = () => {
    setIsDeleteCollectionModalOpen(false)
  }

  const handleClick = (verseCount) => {
    if (verseCount < 1) {
      message.warning(t('collection-empty'));
    } 
}
  return (
    <AllCollectionsUi t={t} handleClick={handleClick} setLoading={setLoading} loading={loading} collections={collections} AddNewCollection={AddNewCollection}
      setIsAddNewCollectionModalOpen={setIsAddNewCollectionModalOpen}
      isAddNewCollectionModalOpen={isAddNewCollectionModalOpen} onClose={onClose}
      isThreeDotOpen={isThreeDotOpen} setIsThreeDotOpen={setIsThreeDotOpen}
      activeImageIndex={activeImageIndex} setActiveImageIndex={setActiveImageIndex}
      ThreeDotsClick={ThreeDotsClick} clickedCollection={clickedCollection} setClickedCollection={setClickedCollection}
      isDeleteCollectionModalOpen={isDeleteCollectionModalOpen} setIsDeleteCollectionModalOpen={setIsDeleteCollectionModalOpen}
      onCloseDelete={onCloseDelete} currentPage={currentPage} recordsPerPage={recordsPerPage} pageNumbers={pageNumbers} handlePageChange={handlePageChange}
      setSearchKeyword={setSearchKeyword} searchKeyword={searchKeyword} filteredCollections={filteredCollections}
      BookIcon={BookIcon} handleDragStart={handleDragStart} handleDragOver={handleDragOver}
      CollectionPopup={CollectionPopup} HalfEye={HalfEye} ThreeDots={ThreeDots} ArrowUpDown={ArrowUpDown}
      sortType={sortType} SortCollection={SortCollection} 
      />
  );
}