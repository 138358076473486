import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import ThreeDotsToolTipUi from "../../../Ui/AllCollections/ToolTip/ThreeDotsToolTipUi";

import { findCollectionDetail } from "../../../../../apis/FetchData";
const ThreeDotsToolTip = (props) => {
  const navigate = useNavigate();
  const [UpdatedData, setUpdatedData] = useState([]);

  const OpenEdit = (data) => {
    setUpdatedData(data)
    props.setIsAddNewCollectionModalOpen(true)
  }

  const DeleteCollection = () => {
    props.setIsDeleteCollectionModalOpen(true)
  }
  const fetchCollectionsDetails = async (loads) => {
    props.setLoading(loads);
    let result = await findCollectionDetail(props.clickedCollection.id);
    if (result && result.status === 200) {
      // setCollectionDetail(result.data)
      if(loads===false){
        let url=result.data[0].collectionVerse[0]
        navigate('/'+url.verse.chapterNo+'/'+url.verse.verseNo);
      }
    }
    props.setLoading(false);
  };

  return (
    <ThreeDotsToolTipUi
      loading={props.loading}
      setLoading={props.setLoading}
      t={props.t}
      isThreeDotOpen={props.isThreeDotOpen}
      index={props.index}
      activeImageIndex={props.activeImageIndex}
      clickedCollection={props.clickedCollection}
      OpenEdit={OpenEdit}
      setIsAddNewCollectionModalOpen={props.setIsAddNewCollectionModalOpen}
      isAddNewCollectionModalOpen={props.isAddNewCollectionModalOpen} onClose={props.onClose} 
      UpdatedData={UpdatedData} 
      DeleteCollection={DeleteCollection}
      fetchCollectionsDetails={fetchCollectionsDetails}

     
    />
  );
};

export default ThreeDotsToolTip;
