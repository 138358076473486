import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchRecitationAudio } from '../../../apis/FetchData'; // Import your API function
import { useLocation,useNavigate  } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import AudioBarUi from "../Ui/AudioBarUi"
import shuffleIcon from '../../../assets/images/shuffle.svg';
import shuffleIconWhite from '../../../assets/images/shuffleWhite.svg';
import arrow_down_blue from '../../../assets/images/arrow_down_blue.svg';
import arrow_down_White from '../../../assets/images/arrow_down_white.svg';
import move_next from '../../../assets/images/move_next.svg';
import move_nextWhite from '../../../assets/images/move_nextWhite.svg';
import move_previous from '../../../assets/images/move_previous.svg';
import move_previousWhite from '../../../assets/images/move_previousWhite.svg';
import play_black from '../../../assets/images/play_black.svg';
import play_White from '../../../assets/images/play_white.svg';
import pause_black from '../../../assets/images/pause_black.svg'
import pause_White from '../../../assets/images/pause_white.svg'
import { SettingsReducer } from "../../../redux/Setting"
const SettingActions = SettingsReducer.actions;

//forwardRef, For calling child function in Parent component
const RecitationComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  const chapters = useSelector((state) => state.chapters);
  const reciterNames = useSelector((state) => state.chapters.reciters);
  const selectedReciterId = useSelector((state) => state.settings.selectedReciterId);
  const selectedReciterName = useSelector((state) => state.settings.selectedReciterName);
  const shuffle = useSelector((state) => state.settings.shuffle);
  const playState = useSelector((state) => state.settings.playState);
  const [recitations, setRecitations] = useState([]);
  const [firstVerse, setFirstVerse] = useState([]);
  const playingSurah = useSelector((state) => state.settings.playingSurah);
  const playingVerse = useSelector((state) => state.settings.playingVerse);
  const [lastPlayedVerseIndex, setLastPlayedVerseIndex] = useState(null);
  const [audioSrc, setAudioSrc] = useState('');
  const [selectedSurah, setSelectedSurah] = useState(0);
  const [selectedVerse, setSelectedVerse] = useState(0);
  const [verseLength, setverseLength] = useState(0);
  const [open, setOpen] = useState(false);
  const [openShuffle, setOpenShuffle] = useState(false);
  const userTheme = useSelector((state) => state.settings.userTheme);
  const shuffle_Icon = userTheme === 'Dark' ? shuffleIconWhite : shuffleIcon;
  const arrowDownBlue = userTheme === 'Dark' ? arrow_down_White : arrow_down_blue;
  const moveNext = userTheme === 'Dark' ? move_nextWhite : move_next;
  const movePrevious = userTheme === 'Dark' ? move_previousWhite : move_previous;
  const playBlackWhite = userTheme === 'Dark' ? play_White : play_black;
  const pauseBlackWhite = userTheme === 'Dark' ? pause_White : pause_black;
  const audioRef = useRef(null);
  let pauseThis = false;

  
  const fetchData = async () => {
    if (selectedSurah && selectedReciterName) {
      try {
        // console.log("selectedSurah:",selectedSurah," / ","selectedVerse:",selectedVerse," / ","verseLength:",verseLength)
      const response = await fetchRecitationAudio(selectedReciterId, selectedSurah, selectedVerse, verseLength);
      setRecitations(response && response.status===200 && response.data);
      // console.log("recitations:",response.data)
      } catch (error) {
        console.error('Error fetching recitations:', error);
      }
    }
  };

  useEffect(() => {
    if(!selectedSurah || (!playState && audioRef.current) ){
      let locationParts = location.pathname.split('/');
      if (locationParts.length > 2) {
        // console.log("locationParts:",locationParts)
        setSelectedSurah(parseInt(locationParts[1]));
        setSelectedVerse(parseInt(locationParts[2]));     
        dispatch(SettingActions.updateSettings({ playingSurah: parseInt(locationParts[1])}));
        dispatch(SettingActions.updateSettings({ playingVerse: parseInt(locationParts[1])>1 && parseInt(locationParts[2])===1 ?1111 :parseInt(locationParts[2]) }));// For playing  بِسْمِ ٱللَّٰهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ 
      }
      else {
        setSelectedSurah(1)
        setSelectedVerse(1)
      }
    }
  },
  // eslint-disable-next-line
   [location, dispatch,selectedSurah,selectedVerse]);
   

  useEffect(() => {
    if (!playState){
      let totalVerses = chapters.chapters[parseInt(selectedSurah) - 1];
      setverseLength(totalVerses && totalVerses.totalVerses);
      // console.log("inside fetch data, now totalVerses",totalVerses)
      if(totalVerses && recitations && totalVerses.totalVerses && parseInt(totalVerses.totalVerses) !== recitations.length)
      {
        // console.log("Now same, fetch again")
        fetchData()
      }
    }
  }, 
  // eslint-disable-next-line
  [location,chapters.chapters,selectedSurah,playState,playingVerse]);

  useEffect(() => {
    const fetchFirstAyat = async () => {
        const responseFirstVerse = await fetchRecitationAudio(selectedReciterId, 1, 1, 1);
        setFirstVerse(responseFirstVerse && responseFirstVerse.status===200 && [responseFirstVerse.data[0]]);
    };  
    fetchFirstAyat();
  }, [selectedReciterId]);




  useEffect(() => {
  }, [recitations,verseLength]);


   useEffect(() => {
    fetchData();
  },
  // eslint-disable-next-line
  [firstVerse,chapters.chapters, selectedSurah, verseLength, selectedReciterName, selectedReciterId, selectedVerse,location]);
  
  const handleReciterChange = (reciterid, recitername) => {
    dispatch(SettingActions.updateSettings({ selectedReciterId: reciterid }));
    dispatch(SettingActions.updateSettings({ selectedReciterName: recitername }));
  };
  
  const handleShuffleChange = (shuffleId) => {
    dispatch(SettingActions.updateSettings({ shuffle: shuffleId }));
  };

  const playVerse = (playChapterNo,verseIndex, play_pause,resetRecitations) => {
    setSelectedSurah(playChapterNo)
    if (resetRecitations)
    {
      let totalVerses = chapters.chapters[parseInt(selectedSurah) - 1];
      setverseLength(totalVerses && totalVerses.totalVerses);
      // console.log("total verses,",verseLength)
      fetchData()
    }
    try {
      if (!verseIndex)
      {
      dispatch(SettingActions.updateSettings({ playingVerse: parseInt(selectedVerse) }));
      verseIndex=selectedVerse
      }

      if (play_pause) {
        dispatch(SettingActions.updateSettings({ playState: true }));
      }
  
      if (verseIndex === undefined) {
        verseIndex = lastPlayedVerseIndex !== null ? lastPlayedVerseIndex : 0;
      }
      
    if (recitations && verseIndex!==1111) {
        let src = recitations.filter(item => item.verseNo === verseIndex && item.chapNo === playChapterNo);
        if (src.length>0) {
          setAudioSrc(src[0].url)
        }
        else{
          // Refind the recitations here and then refind the src.
          const response = fetchRecitationAudio(selectedReciterId, selectedSurah, selectedVerse, verseLength);
          setRecitations(response.data);
        }
      }
      else if(verseIndex===1111  ){
        let src = firstVerse.filter(item => item.verseNo === 1 && item.chapNo === 1);
        if (src.length > 0) {
          setAudioSrc(src[0].url);
        }      
      }
      setLastPlayedVerseIndex(verseIndex);
      dispatch(SettingActions.updateSettings({ playState: play_pause }));
      dispatch(SettingActions.updateSettings({ playingVerse: verseIndex===1111?0:verseIndex }));
    }
    catch (error) {
      console.log(error)
    }
  };

  const Pause = () => {
    dispatch(SettingActions.updateSettings({ playState: false }));
    pauseThis=true
  }

  const playNextVerse = () => {
    let nextVerseIndex = playingVerse + 1;
    if (shuffle === 2 && selectedVerse === recitations.length ) //recitations.length+1 if surah >1
    {
      nextVerseIndex = 1;
    }
    if (nextVerseIndex <= recitations.length) {
      dispatch(SettingActions.updateSettings({ playingVerse: nextVerseIndex }));
      if (verseLength !== recitations.length)
      {
        let totalVerses = chapters.chapters[parseInt(selectedSurah) - 1];
        setverseLength(totalVerses && totalVerses.totalVerses);
        fetchData()
      }
      if (recitations) {
        let src = recitations.filter(item => nextVerseIndex===0 ? item.verseNo === 1 && item.chapNo === 1 : item.verseNo === nextVerseIndex && item.chapNo === selectedSurah);
        if (src.length>0) {
          setAudioSrc(src[0].url)
        }
        else
        {
          console.log("recitations",recitations)
          setSelectedSurah(parseInt())
          // let totalVerses = chapters.chapters[parseInt(selectedSurah) - 1];
          // setverseLength(totalVerses && totalVerses.totalVerses);
          // fetchData()
          // console.log("totalVerses",totalVerses && totalVerses.totalVerses)

          // let src = recitations.filter(item => nextVerseIndex===0 ? item.verseNo === 1 && item.chapNo === 1 : item.verseNo === nextVerseIndex && item.chapNo === selectedSurah);
          // console.log("src",src)
          // if (src.length>0) {
          //   setAudioSrc(src[0].url)
          // }
       }
      }
      else{
        let totalVerses = chapters.chapters[parseInt(selectedSurah) - 1];
        setverseLength(totalVerses && totalVerses.totalVerses);
        fetchData()
      }
      setLastPlayedVerseIndex(nextVerseIndex);
    }
  };
  const playPreviousVerse = () => {
    if (playingVerse !== 1) {
      const nextVerseIndex = playingVerse - 1;
      if (nextVerseIndex < recitations.length ) {
        dispatch(SettingActions.updateSettings({ playingVerse: nextVerseIndex }));
        if (recitations) {
          let src = recitations.filter(item => item.verseNo === nextVerseIndex);
          if (src) {
            setAudioSrc(src[0].url)
          }
        }
        setLastPlayedVerseIndex(nextVerseIndex);
      }
    }
  };

  useEffect(() => {
    if (playState && !pauseThis && audioRef.current) {
      audioRef.current.audioEl.current.play();
    } else if (!playState && audioRef.current) {
      audioRef.current.audioEl.current.pause();
    }

  }, [playState, pauseThis]);

  const closeModal = () => setOpen(false);
  const closeModalShuffle = () => setOpenShuffle(false);


  // For Calling child function in Parent component
  useImperativeHandle(ref, () => ({ playVerse }));

  const setAudioSrcToTHis = (url) => {
    // Set props.audioSrc to the desired URL
    setAudioSrc(url);
    navigate('/'+ parseInt(selectedSurah+1)+'/'+1);
  };

  return (
    <AudioBarUi reciterNames={reciterNames} selectedReciterId={selectedReciterId} selectedReciterName={selectedReciterName}
    shuffle={shuffle} playState={playState} recitations={recitations} playingVerse={playingVerse} audioSrc={audioSrc}
    open={open} setOpen={setOpen} openShuffle={openShuffle} setOpenShuffle={setOpenShuffle} audioRef={audioRef}
    pauseThis={pauseThis} handleReciterChange={handleReciterChange} handleShuffleChange={handleShuffleChange}
    playVerse={playVerse} playNextVerse={playNextVerse} playPreviousVerse={playPreviousVerse} closeModal={closeModal}
    closeModalShuffle={closeModalShuffle} playBlackWhite={playBlackWhite} pauseBlackWhite={pauseBlackWhite} shuffle_Icon={shuffle_Icon} arrowDownBlue={arrowDownBlue} moveNext={moveNext} movePrevious={movePrevious}
    selectedSurah={selectedSurah} selectedVerse={playingVerse} Pause={Pause}  playingSurah={playingSurah}
    firstVerse={firstVerse} setAudioSrc={setAudioSrc} setAudioSrcToTHis={setAudioSrcToTHis}
    />
    );
});


export default RecitationComponent;
