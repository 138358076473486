import React from "react"
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/LogoQuranApp.svg";
import VectoreOne from '../../../assets/images/vectore-one.svg'
import Select from "react-select";
import LoginUi from "../Logic/Login";
import { getCurrentLanguageName } from '../../languageUtils';
import AccountDropDown from "../Logic/AccountDropDown"
import RightMenu from "../../RightMenu/Logic/RightMenu";

export default function Navbar(props) {

  return (
    <>
      <div className={`vw-100 bg-grey1 border-grey3 px-4 `}>
        <div className="row  mb-3">
          <div className="col-lg-3 col-md-12 col-sm-12 mt-sm-2 mt-md-2 ">
            <div className="d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start">
              <Link to="/1/1"><img src={Logo} alt={"Logo"} className="logo-margin" /></Link>
            </div>
          </div>
          <div className="col-lg-1 col-md-12 col-sm-12 mt-2">
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 mt-2 " align="center">
            <div className="custom-input-container " >
              <input
                className="SF_UI_Text search px-3 search-bar-background custom-height37"
                type="text"
                placeholder={props.t('search')}
                value={!props.selectedOptionVerse && props.rootWord ? props.t('root-word') + ': ' + props.rootWord : props.searchKeyword}
                onChange={(event) => props.onSearchWordChange(event.target.value)}
                onKeyUp={(event) => props.SubmitSearchForm(event)}
              />
              <img
                className="menu_icon mx-1 search-icon-custom"
                alt="..."
                src={props.SearchLogo}
                onClick={() => props.clickOnSearch()}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 mt-2 ">
            <div data-bs-theme="dark" className="d-flex justify-content-center small-col LoginAndLanguage justify-content-sm-center justify-content-md-center justify-content-lg-end">
              
              <Select
                options={props.languageOptions}
                value={props.languageOptions?.find((option) => option?.value === props.defaultOption?.value) || null }
                onChange={(selectedOption) => props.changeLanguageHandler(selectedOption.value)}
                isSearchable={false}
                menuPosition={'fixed'}
                defaultValue={props.defaultOption}
                className="language-select-react SF_UI_Text  "
                components={{
                  DropdownIndicator: props.CustomDropdownIndicator,
                  SingleValue: props.selectedLanguageValue,

                }}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    color:  state.isSelected ? "white" : "#707070",
                    backgroundColor: state.isSelected ?  '#0d6efd': 'var(--bs-black-white)',
                    marginTop: -5, 
                    marginBottom: -5, 
                    "&:hover": {
                      backgroundColor: 'var(--dropdown-item-hover-bs-color)', 
                    },
                    
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "12px",
                    width: "fitContent",
                    right: '0px',
                    border: "none",
                    boxShadow: "none",
                    background: "transparent",
                    cursor: "pointer",
                    color: " var(--navbar-language-text-color)", 
                  }),
                 
                }}
              />
              <img src={VectoreOne} alt="" />
              <div className="cursor-pointer ">
                {props.user ? (
                  <AccountDropDown  user={props.user} t={props.t} navigate={props.navigate} />
                ) : (
                  <div className="cursor-pointer mt-1 width-language-login "
                    data-bs-toggle="modal" data-bs-target="#loginModal"
                  >
                    <label id="loginSpan" className={`ms-0 px-2 SF_UI_font_regular vw-font-size-0_7 loginButton text-white-black-theme`}>
                      {props.t('login')}
                    </label>
                    <img alt="" className="profile-image" src={ props.profileIcon } />
                  </div>
                )
                }
              </div>

            </div>
          </div>
        </div>
        <div className="">
          <div className='row'>
            <div className="col-lg-4 col-md-12 col-sm-12 mb-2 center-items  ">
              <div className="row ">
                <div className="col-lg-5 col-md-12 col-sm-12 col-distancae-reduce mb-2 screen-margin chapter-style">
                  {props.selectedOptionChapter ? (
                    <Select options={props.ChapterOptions}
                      className={"SF_UI_Text MyDropdown custom-select "}
                      classNamePrefix={"MyDropdown"}
                      isSearchable={false}
                      onMenuOpen={props.onMenuOpen}
                      menuPosition={'fixed'}
                      value={props.selectedOptionChapter} // Set the selected option using the state variable
                      onChange={(e) => props.handleChapterChange(e)}
                      components={{
                        SingleValue: ({ children }) => (
                          <span style={{ color: 'var(--bs-color)' }} className="ml-2">{children} </span>
                        ),
                      }}
                      placeholder={
                        <span style={{ color: 'var(--bs-color)' }} className="ml-2">
                          {props.selectedOptionVerse.value}
                        </span>
                      }

                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                   
                          color:  state.isSelected ? "white" : "#707070",
                          backgroundColor: state.isSelected ?  '#0d6efd': 'var(--bs-body-bg)',
                          marginTop: -5, 
                          marginBottom: -5, 
                          "&:hover": {
                            backgroundColor: 'var(--dropdown-item-hover-bs-color)', 
                          },         
                                           
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          flexWrap: 'nowrap',
                      }),
                      input: (provided, state) => ({
                          ...provided,
                          minWidth: '20%'
                      }),
                  
                       
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: '153px',
                          height: '29px',
                          borderRadius: 50,
                          background: props.userTheme === 'Dark' && '#0C0C0C',
                          borderColor: props.userTheme === 'Dark' && "#707070",
                          // Remove the weird border around container
                          boxShadow: state.isFocused ? 'none' : 'none', // Change to 'none'
                          "&:hover": {
                            borderColor: props.userTheme === 'Dark' && "white",
                          }
                        })
                       
                      }}
                    />
                  ) : props.currentUrl.includes('/collections/') ||  props.currentUrl.includes('/collection-detail/') ? (
                    <a href="/collections/" >

                      <img alt="" className=" verse_option_icon " src={props.CollectionBlue} />
                      <label className="cursor-pointer font-size-13-700 blue3-white-color text-uppercase my-0 ml-2"> {props.t('collections')}</label>
                    </a>
                  )
                    : props.currentUrl.includes('/bookmarks/') ? (
                      <a href="/bookmarks/" >
                        <img alt="" className=" verse_option_icon " src={props.BookmarksBlue} />
                        <label className="cursor-pointer font-size-13-700 blue3-white-color text-uppercase my-0 ml-2">{props.t('bookmark')} </label>
                      </a>
                    ):
                     props.currentUrl.includes('/notes/') ? (
                      <a href="/notes/" >
                        <img alt="" className=" verse_option_icon " src={props.NotesBlue} />
                        <label className="cursor-pointer font-size-13-700 blue3-white-color text-uppercase my-0 ml-2"> {props.t('notes')} </label>
                      </a>
                    ):null
                    }
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 mb-2  verse-style col-distancae-reduce ">
                  {props.selectedOptionVerse ? (
                    <Select classNames="SF_UI_Text searchable-select-tag-text ml-2 "
                      options={props.VerseOptions} name='VerseSelect'
                      value={props.selectedOptionVerse}
                      isSearchable={false}
                      components={{
                        SingleValue: ({ children }) => (
                          <span style={{ color: '#8F8F8F' }} className="ml-2">
                            Verse:  <span style={{ color: 'var(--bs-color)' }}>{children}</span>
                          </span>
                        )
                      }}
                      placeholder={
                        <span className="ml-2">
                          Verse: <span style={{ color: "var(--bs-color)" }}>{props.selectedOptionVerse.value}</span>
                        </span>
                      }
                      className={"MyDropdown"}
                      classNamePrefix={"MyDropdown"}
                      onMenuOpen={props.onMenuOpen}
                      menuPosition={'fixed'}
                      onChange={(e) => props.handleVerseChange(e)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          color:  state.isSelected ? "white" : "#707070",
                          backgroundColor: state.isSelected ?  '#0d6efd': 'var(--bs-body-bg)',
                          marginTop: -5, 
                          marginBottom: -5, 
                          "&:hover": {
                            backgroundColor:'var(--dropdown-item-hover-bs-color)', 
                          },
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: '153px',
                          height: '29px',
                          borderRadius: 50,
                          background: props.userTheme === 'Dark' && '#0C0C0C',
                          borderColor: props.userTheme === 'Dark' && "#707070",
                          // Remove the weird border around container
                          boxShadow: state.isFocused ? 'none' : 'none', // Change to 'none'
                          "&:hover": {
                            borderColor: props.userTheme === 'Dark' && "white",
                          }
                        })
                       
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12  custom-text-align-center">
              {props.currentUrl.includes('/search/') && props.rootWordsCount !== 0 ? (
                <div className="mt-4">
                  <label className={`font-size-16-400  text-darker cursor_pointer py-0 my-0 ml-2 text-white-black-theme `}>
                    {props.t('words')}
                  </label>
                  <label  className={`font-size-16-400 cursor_pointer py-0 my-0 ml-1  text-white-black-2-theme`} >
                    : {props.rootWordsCount}
                  </label>
                </div>
              ) : props.selectedOptionVerse && props.lastRead ? (
                <div className=" mt-1 d-flex flex-row  align-items-center  justify-content-center cursor_pointer">
                  <Link to={'/' + (props.lastRead.split(':')[0]) + '/' + props.lastRead.split(':')[1]}>
                    <img
                      className="menu_icon cursor_pointer ml-4"
                      alt="..."
                      src={props.lastReadBlue}
                    />
                    <label className={`SF_UI_Text font-size-12-400 cursor_pointer py-0 my-0 ml-2  text-white-blue-2-theme `}>
                      Last Read:
                    </label>
                    <label className={`SF_UI_Text font-size-12-400  cursor_pointer py-0 my-0 ml-1 px-1 text-white-black-2-theme `} >
                      {props.chapters.chapters[props.lastRead.split(':')[0] - 1].alphaArabicName} - Verse#{props.lastRead.split(':')[1]}
                    </label>
                  </Link>
                </div>
              ) : null}
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 mt-1 mb-2 px-4" >
              <div className="d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end">
                <RightMenu  t={props.t} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginUi  t={props.t} isModalHide={props.isModalHide} setIsModalHide={props.setIsModalHide} onHideLoginModal={props.onHideLoginModal} Selectedlanguage={getCurrentLanguageName()} user={props.user} setUser={props.setUser} />
    </>
  );
}

