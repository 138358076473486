import React from 'react'
import CrossWhite from "../../../assets/images/cross_white.svg";

export default function ShareModalUi(props) {
    return (
        <div className="modal show" tabIndex="-1" onClick={(e) => e.target.classList.contains('modal') && props.onClose()} id="myModal" role="dialog" aria-labelledby="myModalLabel">
            <div className="dark__overlay" onClick={(e) => e.target.classList.contains('dark__overlay') && props.onClose()} />
            <div className="modal-dialog modal-dialog-centered w-50 float-to-center">
                <div className="modal-content darkBackground dark-mode-modal-border modal-share-width ">
                    <div className="modal-header dark-mode-modal-header dark-mode-modal-border-bottom">
                        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '3rem' }}>
                            <h5 className="modal-title model-title-text align-self-center">
                                {props.chapters.chapters[props.singleVerse.chapterNo - 1].alphaArabicName + ' (' + props.chapters.chapters[props.singleVerse.chapterNo - 1].chapterNo + ":" + props.singleVerse.verseNo + ")"}
                            </h5>
                            <img className=' model-cross-icon align-self-center '
                                alt='' onClick={() => props.onClose()} src={CrossWhite} />
                        </div>
                    </div>
                    <div className="modal-body" >
                        <div className="search-icon-share search text-center">
                            <input placeholder={props.t(('search_here'))} value={props.searchTranslatorQuery} onChange={props.handleTranslationSearch} className="search-reciter-and-translation px-3 search-reciter-text " type="text" />
                        </div>
                        <div className="translation_div px-1 mt-2">                           
                            {props.filteredTranslationDataForSearch ? (
                                props.filteredTranslationDataForSearch.map((translator) => (
                                    <div   className=" mb-2" key={translator.id + "TShare1"}>
                                        <input
                                            id={`tanslatorSearch${translator.id}`}
                                            name="tanslatorSearchNames"
                                            type="checkbox"
                                            checked={props.translationForShare.includes(translator.id)}
                                            onChange={() => props.HandleTranslationCheckboxChange(translator.id)}
                                        />
                                        <label className="share-checbox-text" htmlFor={`tanslatorSearch${translator.id}`}>
                                            {translator.language}
                                        </label>
                                        <p onClick={() => props.HandleTranslationCheckboxChange(translator.id)} className="mini-share-label mt-2">
                                            {translator.translatorName}
                                        </p>
                                        <p onClick={() => props.HandleTranslationCheckboxChange(translator.id)} className="mini-share-label wrap-line-height ">
                                            {translator.engName.replace(/\\/g, '')}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p>{props.t(('no-translator-available'))}</p>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer modal-footer-height-grammer dark-mode-modal-border-only-top">
                        <div className="flex-container custom-column-yes-no mb-2 justify-content-center align-items-center w-100" style={{ height: '0rem' }}>
                            <div className={`model_button_yes_no darkBackground model_button cursor_pointer px-4 py-3  `} title={(props.t('share-verse'))} onClick={() => props.onShareVerse()}>
                                <label className={` text-white-blue-2-theme cursor_pointer`}>{(props.t('share-verse'))}</label>
                            </div>
                            <div className={`model_button_yes_no darkBackground model_button cursor_pointer px-4 mx-2  py-3 `} title={(props.t('cancel'))} onClick={props.onClose}>
                                <label className={` text-white-blue-2-theme cursor_pointer`} style={{ "color": "rgb(106, 106, 106)" }}>{(props.t('cancel'))}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
