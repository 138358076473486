import React from 'react'
import CrossWhite from "../../../assets/images/cross_white.svg";

export default function DeleteConfirmationForNotes(props) {
    return (
        <div className="modal show " onClick={(e) => e.target.classList.contains('modal') && props.onClose()} tabIndex="-1">
            <div className="dark__overlay" onClick={(e) => e.target.classList.contains('dark__overlay') && props.onClose()} />
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className={`darkBackground  modal-content  dark-mode-modal-border ModalConfirmationWidth  `}>
                    <div className="modal-header dark-mode-modal-header dark-mode-modal-border-bottom">
                        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '3rem' }}>
                            <img className=' model-cross-icon align-self-center '
                                alt='' onClick={() => props.onClose()} src={CrossWhite} />
                        </div>
                    </div>
                    <div className="modal-body" >
                        <div className="d-flex flex-column">
                            <label className={`text-white-black-theme text-center font-size-18-600 `} >
                                {props.t('delete-note-msg')}
                            </label>
                            <div className="flex-container custom-column-yes-no mt-4 justify-content-center align-items-center w-100 h-100">
                                <div onClick={() => props.onDeleteNotes()} className={`model_button_yes_no darkBackground model_button cursor_pointer px-4 w-auto px-5 text-uppercase`} >
                                    <label className={`text-white-black-theme`} >{props.t('yes')}</label>
                                </div>
                                <div onClick={() => props.onClose()} className={`model_button_yes_no darkBackground model_button cursor_pointer px-4 text-uppercase w-auto px-5 ml-no`} >
                                    <label className={`text-white-black-theme `} style={{ color: "rgb(106, 106, 106)" }}>{props.t('no')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
