import React from "react";
import DisplayBookmarksUi from "../../../Ui/Home/AddSection/DisplayBookmarksUi";
import { addToBookmark, deleteBookmark } from "../../../../../apis/PostData";
import { message } from 'antd';


const DisplayBookmarks = (props) => {
 
  const AddToBookMark = async (verseId, verseNo, chapterNo, chapterName) => {
    props.HideAddPlusPopup()
    props.setLoading(true);
    let result = await addToBookmark(verseId, verseNo, chapterNo, chapterName);
    if (result && result.status === 201) {
      const verseToUpdate = props.allVerses.find((verse) => verse.id === verseId);
      if (verseToUpdate) {
        // Push the bookmark data into the 'bookmark' list of that verse        
        verseToUpdate.bookmark.push({ 'chapterName': chapterName, 'verseId': verseId, 'chapterNo': chapterNo, 'verseNo': verseNo });
      }
      message.success(props.t('bookmark-success'));
    }
    props.setLoading(false);
  };


  const RemoveBookmark = async (verseId) => {
    props.HideAddPlusPopup()
    if (props.isBookmarkConfirmationsModalOpen) {
      props.setIsBookmarkConfirmationsModalOpen(false)
    }
    props.setLoading(true);
    let result = await deleteBookmark(verseId);
    if (result && result.status === 200) {
      const verseToUpdate = props.allVerses.find((verse) => verse.id === verseId);
      if (verseToUpdate) {
        // Clear the 'bookmark' list of that verse
        verseToUpdate.bookmark = [];
      }
      message.success(props.t('remove-bookmark-success-msg'));
    }
    props.setLoading(false);
  };

  return (
    <DisplayBookmarksUi
      singleVerse={props.singleVerse}
      AddToBookMark={AddToBookMark}
      RemoveBookmark={RemoveBookmark}
      chapters={props.chapters}
      setLoading={props.setLoading}
      t={props.t}
    />
  );
};

export default DisplayBookmarks;
