import React from 'react'
import { useNavigate } from "react-router-dom";
import Grammer from "../Ui/GrammerUi";

export default function GrammerModal(props) {
    const navigate = useNavigate();
    const onPreviousClick = () => {
        if(props.grammerModalVerseId>1){
            props.updateGrammerVerseId(props.grammerModalVerseId-1)
        }
    };
    
    const onNextClick = () => {
        if(props.grammerModalVerseId<props.Grammers.length){
            props.updateGrammerVerseId(props.grammerModalVerseId+1)
        }

    };
  
    const OnRootWordClick = (root) => {
        navigate('/search/rootword:' + root);
        props.onClose()
    }


    return (
      <Grammer t={props.t} Grammers={props.Grammers} grammerModalVerseId={props.grammerModalVerseId} onClose={props.onClose} chapters={props.chapters} onPreviousClick={onPreviousClick} onNextClick={onNextClick} OnRootWordClick={OnRootWordClick} />
    )
}
