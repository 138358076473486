import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from './redux/store/store';
import { BrowserRouter } from "react-router-dom";//The Routes component is used to group multiple Route and route is used for mapping between a specific URL path and the component.
import "./i18n";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider } from 'react-helmet-async'; 
const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);

root.render(
  <BrowserRouter>
    <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
      <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
      </HelmetProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
