import React from 'react';
import NotesIcon from '../../../../../assets/images/notes.020a6984.svg'

export default function DisplayNotesUi(props) {
  return (
    <div onClick={() => props.AddToNotes(props.singleVerse)} className="d-flex flex-row w-100 mt-3  cursor_pointer  px-1">
    <img alt="" className=" verse_option_icon " src={NotesIcon}/>
    <span className="login_popup_description text-left my-0 ml-2">{props.t("notes")}</span>
  </div>

  )
}


