import React from "react";
import CrossIcon from '../../../assets/images/cross_black.svg'
import CrossIconWhite from '../../../assets/images/cross_white.svg'
import ThemeLight from '../../../assets/images/Theme.svg'
import ThemePink from '../../../assets/images/ThemePink.svg'
import ScriptImageLight from '../../../assets/images/Script.svg'
import ScriptImagePink from '../../../assets/images/ScriptPink.svg'
import FontSizeLight from '../../../assets/images/FontSize.svg'
import FontSizePink from '../../../assets/images/FontSizePink.svg'

function Apperance(props) {
  return (
    <aside className={` side-menu   ${props.selectedMenu === 'appearance' ? "side-menu--active" : ""}`} >
      <div className="side-menu__overlay" onClick={() => props.onOverlayClick()} />
      <div className={`scroll-it darkBackground side-menu__content d-flex flex-column ${props.selectedMenu === 'appearance' ? "side-menu__content--active" : ""}`}>
        <div className={`darkLightBackground side-menu-header`} >
          <span className={`text-white-black-theme `}>{props.t('display-apperance')}</span>
          <img className="" alt="" src={props.selectedTheme === 'Dark' ? CrossIconWhite  :CrossIcon }
            onClick={() => props.onOverlayClick()}
          />
        </div>
        <div className="d-flex flex-column  mx-2 jutify-content-center ">
          <div className=" d-flex flex-column ">
            <div className="side-menu-title"><img alt="..." src={props.selectedTheme === 'Dark' ? ThemePink  :ThemeLight } /><span >{props.t('theme')}</span></div>
            <div className="h-50 w-100 theme_selection  align-self-center ">
              <div  onClick={() => props.handleThemeChange("Auto")} className={`h-100 width-33 cursor_pointer ${props.selectedTheme==='Auto' ?'theme_left_select' : 'theme_left_unselect'}  `}>
                <span className={` ${props.selectedTheme==='Auto' ?'theme_text_select' : 'theme_text_unselect'} `}>{props.t('auto')}</span>
              </div>
              <div className="width-2px h-50 bg-color-gray2 align-self-center" />
              <div onClick={() => props.handleThemeChange("Light")}  className={`h-100 width-33 cursor_pointer ${props.selectedTheme==='Light' ?'theme_center_select' : 'theme_center_unselect'}  `}>
                <span  className={` ${props.selectedTheme==='Light' ?'theme_text_select' : 'theme_text_unselect'} `} >{props.t('light')}</span>
              </div>
              <div className="width-2px h-50 bg-color-gray2 align-self-center" />
              <div onClick={() => props.handleThemeChange("Dark")} className={`h-100 width-33 cursor_pointer ${props.selectedTheme==='Dark' ?'theme_right_select' : 'theme_right_unselect'}  `}>
                <span  className={` ${props.selectedTheme==='Dark' ?'theme_text_select' : 'theme_text_unselect'} `} >{props.t('dark')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 ml1_5  px-2 pt-2 d-flex justify-content-center ">
          <div className=" w-100 d-flex flex-column py-1 border-top-theme border-bottom-theme align-self-center jutify-content-center">
            <div className="side-menu-title w-100  cursor_pointer justify-content-between">
              <div><img alt="..." src={props.selectedTheme === 'Dark' ? ScriptImagePink  :ScriptImageLight } /><span >{props.t('script')}</span></div>
            </div>
            <div onClick={() => props.handleScriptChange("text")} className="script-menu-title w-100 mb-1  cursor_pointer justify-content-between">

              <div className="radio-container">
                <input checked={(props.selectedScript) === "text"} onChange={() => props.handleScriptChange("text")} className="script-radio" type="radio" id="Simple" value="text" />
                <label className="script-radio-label" htmlFor="Simple">Simple</label>
              </div>
              <label className="mt-1 mr-2 d-flex justify-content-center text-center cursor_pointer text-white-black-theme" style={{ fontSize: '0.70rem',fontWeight:600, fontFamily: 'Times New Roman'  }}>أُولَئِكَ عَلَى هُدًى مِنْ رَبِّهِمْ‎</label>
            </div>
            <div onClick={() => props.handleScriptChange("textIndopak")} className="script-menu-title w-100 mb-1 mt-4 cursor_pointer justify-content-between">
              <div className="radio-container">
                <input
                  checked={(props.selectedScript) === "textIndopak"}
                  onChange={() => props.handleScriptChange("textIndopak")}
                  className=" script-radio" type="radio" id="indopak" value="textIndopak" />
                <label className="script-radio-label " htmlFor="indopak" > Naskh</label>
              </div>
              <label className="mt-1 mr-2 d-flex justify-content-center text-center cursor_pointer text-white-black-theme" style={{ fontSize: '0.70rem' ,fontWeight:600, fontFamily: 'Times New Roman'  }}>اُولٰٓئِکَ عَلٰی ہُدًی مِّنۡ رَّبِّہِمۡ ٭‎</label>
            </div>
            <div className="mt-n12 paddingLeft3 w-100  cursor_pointer justify-content-between">
              <label className="script-radio-label-12 " htmlFor="indopak" > (Indo-pak/ Persian) </label>
            </div>
            <div onClick={() => props.handleScriptChange("textUthmani")} className="script-menu-title w-100 mb-1 mt-3 cursor_pointer justify-content-between">
              <div className="radio-container">
                <input
                  checked={(props.selectedScript) === "textUthmani"}
                  onChange={() => props.handleScriptChange("textUthmani")}
                  className="script-radio" type="radio" id="Uthmani" value="textUthmani" />
                <label className="script-radio-label  " htmlFor="Uthmani" > Uthmani </label>
              </div>
              <label className="mt-1 mr-2 d-flex justify-content-center text-center cursor_pointer text-white-black-theme" style={{ fontSize: '0.70rem',fontWeight:600, fontFamily: 'Times New Roman'  }}>أُو۟لَٰٓئِكَ عَلَىٰ هُدًى مِّن رَّبِّهِمْ‎</label>
            </div>
          </div>
        </div>
        <div className=" width-90 d-flex flex-column px-2 align-self-center jutify-content-center">
          <div className="side-menu-title d-flex w-100 justify-content-between">
            <div ><img className='FontSizeIconWidth' alt="..." src={props.selectedTheme === 'Dark' ? FontSizePink  :FontSizeLight } /><span >{(props.t('arabic-style-font'))}</span></div>
          </div>
          <div className="font-size-div d-flex px-2 flex-column justify-content-center">
            <label className="mt-1 d-flex justify-content-center text-center cursor_pointer text-white-black-theme" style={{ fontSize: props.arabicFontSize/2.5 + "rem",fontWeight:600, fontFamily: 'Times New Roman'  }}>بِسْمِ ٱللّٰهِ ٱلرَّحْمٰنِ ٱلرَّحِيمِ‎</label>
            <div className="d-flex w-100 mt-3  justify-content-between">
              <label className=" font-size-12-400 text-white-gray-3-theme ">{props.t('arabic-font-size')}</label>
              <label className="font-size-12-400  text-white-gray-3-theme "> {props.arabicFontText}</label>
            </div>
            <div>
              {/* <input className="range-arabic range111" type="range" name="vol" min={12} max={64} defaultValue={props.arabicFontValue} onChange={props.handleChangeArabic} */}
              {/* // style={{ headColor: 'yellow', changecolorposition: '7%', fillcolor: 'yellow', fillsecondry: 'yellow',accentColor: 'yellow', width: '100%', ...props.trackStyleArabic }} */}
            {/* /> */}
            <input  className="range-sliders"  name="vol" min={12} max={64} defaultValue={props.arabicFontValue} onChange={props.handleChangeArabic} type="range"/>

            </div>
          </div>
          <div className="font-size-div d-flex px-2 mt-2 flex-column justify-content-center">
            <label className="mt-1 d-flex justify-content-center text-center cursor_pointer text-white-black-theme" style={{  fontSize: props.translationFontSize/2 + "rem",fontWeight:400, fontFamily: 'SF UI Text' }}>In the name of Allah</label>
            <div className="d-flex w-100 mt-3  justify-content-between"><label className=" font-size-12-400  text-white-gray-3-theme ">{(props.t('translation-font-size'))}</label>
              <label className="font-size-12-400  text-white-gray-3-theme ">{props.translationFontText}</label></div>
            <div>
              {/* <input type="range" className="range-arabic" name="volTranslation" min={12} max={64} defaultValue={props.translationFontValue} onChange={props.handleChangeTranslation} */}
              {/* style={{ headColor: 'var(--dark-light-icon-color)', changecolorposition: '1%', fillcolor: 'var(--dark-light-icon-color)', fillsecondry: 'var(--dark-light-icon-color)', width: '100%', ...props.trackStyleTranslation }}  */}
              {/* // /> */}
              <input  className="range-sliders"  name="volTranslation" min={12} max={64} defaultValue={props.translationFontValue} onChange={props.handleChangeTranslation}  type="range"/>

              </div>
          </div>
        </div>
      </div>
    </aside>

  )
}

export default Apperance
