import React from 'react';
import Edit from "../../../../../assets/images/collection/edit.svg"
import Delete from "../../../../../assets/images/collection/delete.svg"
import Jumpto from "../../../../../assets/images/collection/jump_to.svg"

export default function AddToolTipUi(props) {
  return (
    <>
      <div key={props.index + 'tooltipThreeDots'} className="tooltip-container " >
        {(
          <div className={`tooltip ${props.activeImageIndex === props.index ? 'active' : ''}`}  >
            <div className={`darkBackground triangleCollectionAll triangleArrowCollectionAll `} >
              <div style={{ width: "auto !important", paddingLeft: 2, paddingRight: 2 }} align="center">
                <>
                  <div onClick={() => props.OpenEdit(props.clickedCollection)} className="d-flex flex-row margin-left-collection mb-2 w-100 mt-3 px-2 cursor_pointer">
                    <img alt="" className=" verse_option-_icon" src={Edit} />
                    <span className="login_popup_description text-left my-0 ml-2">{props.t('edit')}</span>
                  </div>
                  <div className='bottom_border_line  mt-2 mb-2' />
                  <div onClick={() => props.DeleteCollection()} className="d-flex flex-row margin-left-collection mb-2 w-100 mt-2 px-2 cursor_pointer">
                    <img alt="" className=" verse_option-_icon" src={Delete} />
                    <span className="login_popup_description text-left my-0 ml-2">{props.t('delete')}</span>
                  </div>
                  <div className='bottom_border_line mt-2 mb-2' />
                    <div  onClick={() => props.fetchCollectionsDetails(false)} className="d-flex flex-row margin-left-collection w-100 mt-2 px-2 cursor_pointer">
                      <img alt="" className=" verse_option-_icon" src={Jumpto} />
                      <span className="login_popup_description text-left my-0 ml-2">{props.t('jump-to') } </span>
                    </div>
                </>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}


