import React from 'react';
import Share from "../../../../../assets/images/Share.svg";
import SharVerseModal from '../../../../../components/Modals/Logic/Share'
import ShareSocialModal from '../../../../../components/Modals/Logic/ShareSocialMedia'

export default function DisplayShareUi(props) {
  return (
    <>
    <img onClick={() => props.ShareModal(props.singleVerse)} title={props.t('share-verse')} className="verse_option_icon  my-2 cursor_pointer my-2" src={Share} alt="share" />
    {props.isShareModalOpen && <SharVerseModal  translationForShare={props.translationForShare} setTranslationForShare={props.setTranslationForShare}  setSocialMediaModal={props.setSocialMediaModal} onShareVerse={props.onShareVerse} singleVerse={props.singleVerse} title={props.t('add-collection-title')} t={props.t}  isShareModalOpen={props.isShareModalOpen} setIsShareModalOpen={props.setIsShareModalOpen} onClose={props.closeShareModalOpen} setLoading={props.setLoading} />}
    {props.socialMediaModal && <ShareSocialModal translationForShare={props.translationForShare} setTranslationForShare={props.setTranslationForShare} closeSocialMediaModal={props.closeSocialMediaModal} singleVerse={props.singleVerse} title={props.t('add-collection-title')} t={props.t}  isShareModalOpen={props.isShareModalOpen} setIsShareModalOpen={props.setIsShareModalOpen} onClose={props.closeShareModalOpen} setLoading={props.setLoading} />}

    </>
    )
}


