//+1 650-555-3434	
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { message } from 'antd';
import { getAuth,signInWithPopup,GoogleAuthProvider} from "firebase/auth"; 
import {getAuthToken} from '../apis/PostData'
import $ from 'jquery';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
const app = firebase.initializeApp(config);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({ prompt: 'select_account' });

const signInWithGoogle = async (HideModal,t) => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user;
    if (user && typeof HideModal === "function" ){
      HideModal()
      $('.modal-backdrop').remove(); // Remove the modal backdrop element
        message.success(t('logged-in-successfully'));
        setTimeout(() => {
          getAuthToken(auth.currentUser)
        }, 5000);
   }
    return user  
  } catch (err) {
    console.error(err);
    return false
  }
};

const signInWithApple = async () => {
  var provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  firebase
  .auth()
  .signInWithPopup(provider)
  .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */

  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    console.log("errorCode:",errorCode)
    console.log("errorMessage:",errorMessage)

  });
  
};

export {
  auth,
  signInWithGoogle,
  signInWithApple
};