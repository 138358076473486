import React from 'react';
import BookmarkIcon from '../../../../../assets/images/bookmark.c574f005.svg'

export default function DisplayBookmarksUi(props) {
  return (
      <div onClick={() => { if (props.singleVerse.bookmark.length > 0) { props.RemoveBookmark(props.singleVerse.id); } else { props.AddToBookMark(props.singleVerse.id, props.singleVerse.verseNo, props.singleVerse.chapterNo, props.chapters.chapters[props.singleVerse.chapterNo - 1].name); } }} className="d-flex flex-row w-100 mt-3  cursor_pointer px-1">
        <img alt="" className="verse_option_icon" src={BookmarkIcon} />
        <span className="login_popup_description text-left my-0 ml-2">
          {props.singleVerse.bookmark.length > 0 ? props.t("remove-bookmark") : props.t("bookmark")}

        </span>
        
      </div>
     
  )
}


