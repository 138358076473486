import axios from 'axios';
import store from '../redux/store/store';
import { auth } from './firebase_authentications';
import { SettingsReducer } from '../redux/Setting';
import { getAuthTokenWithOutSignUp } from "../apis/PostData";

// Create a new Axios instance with custom configuration
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});


// Request interceptor

instance.interceptors.request.use(
  (config) => {
    
    const firebaseToken = store.getState().settings.firebaseToken; // Get the Firebase token from the Redux store
    if (firebaseToken) {
      // Add the Firebase token to the request headers
      config.headers.Authorization = `Bearer ${firebaseToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Check if the error is due to an expired token
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      // Refresh the token
      getAuthTokenWithOutSignUp(auth.currentUser)
      .then(newAccessToken => {
        store.dispatch(SettingsReducer.actions.updateSettings({ firebaseToken: newAccessToken }));
        if (newAccessToken) {
        // Update the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      }
      })
      .catch(error => {
        // console.error("Error:", error);
      });
      // Retry the original request
      return instance(originalRequest);
    }
  }
);

export default instance;
