import React,{useState} from "react";
import DisplayShareUi from "../../../Ui/Home/AddSection/DisplayShareUi";


const DisplayShare = (props) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [socialMediaModal, setSocialMediaModal] = useState(false);
  const [translationForShare, setTranslationForShare] = useState([]);

  const ShareModal = () => {
    setIsShareModalOpen(true);
  };
  const closeShareModalOpen = () => {
    setIsShareModalOpen(false);
  };
  

  const closeSocialMediaModal = () => {
    setSocialMediaModal(false);
  };
  

  return (
    <DisplayShareUi
      t={props.t}
      singleVerse={props.singleVerse}
      isShareModalOpen={isShareModalOpen}
      setIsShareModalOpen={setIsShareModalOpen}
      ShareModal={ShareModal}
      closeShareModalOpen={closeShareModalOpen}
      socialMediaModal={socialMediaModal}
      setSocialMediaModal={setSocialMediaModal}
      closeSocialMediaModal={closeSocialMediaModal}
      translationForShare={translationForShare} 
      setTranslationForShare={setTranslationForShare}
      
    />
  );
};

export default DisplayShare;
