import React,{useState,useEffect} from 'react'
import ShareSocialMediaModal from "../Ui/ShareSocialMediaModalUi";
import { useSelector } from "react-redux";
import { convertToSearchableChapters } from '../../Views/Logic/SearchableChapters';

export default function ShareSocialMedia(props) {
  const chapters = useSelector((state) => state.chapters);
  const ChapterOptions = convertToSearchableChapters(chapters);
  const scriptStyle = useSelector((state) => state.settings.script);
  const [shareUrl, setShareUrl] = useState('');
  const [quote, setQuote] = useState('');
  const [currentDomain, setCurrentDomain] = useState('');

  function WriteTranslations(ayat_translations, surahName) {
    let line = surahName+'\n\n';
    ayat_translations.forEach((translation_text) => {
            line = line+ translation_text.translationText ;
            line=line +'\n\n'            
    });
        setQuote(line)
}



function WriteArabicVerse( verse_arabic,surahName) {
  let line = surahName+'\n\n' + verse_arabic+'\n\n';
  setQuote(line)
}



  useEffect(() => {
    const currentURL = window.location.href;
    const currentD = new URL(currentURL).origin;
    setCurrentDomain(currentD.includes('localhost')
    ? 'https://devquranweb-nest2.doctors-finder.com'
    : currentD
  );
  
  setShareUrl(currentDomain+'/'+props.singleVerse.chapterNo+"/"+props.singleVerse.verseNo)

  const handleShareThisVerse = (verse_arabic, surah_no, ayat_no, ayat_translations) => {
    const surahName = ChapterOptions.find(option => option.value === parseInt(surah_no)).label + " (" + surah_no + ":" + ayat_no + ")"
    let selected_share_translations_ids = props.translationForShare
    let selectedShareTranslation1__Id;
    let selectedShareTranslation2__Id;
    let selectedShareTranslation3__Id;
    selected_share_translations_ids.sort((a, b) => a - b);
    const selectedShareArabic = selected_share_translations_ids[0] === 0; //will be true if 0 in 0th index
    const selectedShareTranslation1 = Boolean((selected_share_translations_ids[0] === 0 && selected_share_translations_ids[1]) || (selected_share_translations_ids[0] && selected_share_translations_ids[0] !== 0));//will be true if condition true
    const selectedShareTranslation2 = Boolean((selected_share_translations_ids[0] === 0 && selected_share_translations_ids[2]) || (selected_share_translations_ids[1] && selected_share_translations_ids[0] !== 0));//will be true if condition true
    const selectedShareTranslation3 = Boolean(selected_share_translations_ids[0] !== 0 && selected_share_translations_ids[2]);//will be true if condition true

    if (selected_share_translations_ids[0] === 0) {
      selectedShareTranslation1__Id = selected_share_translations_ids[1] || selected_share_translations_ids[0];//selectedShareTranslation1__Id is assigned the value of selected_share_translations_ids[1] if it's available; otherwise, it falls back to selected_share_translations_ids[0]
      selectedShareTranslation2__Id = selected_share_translations_ids[2] || selected_share_translations_ids[1];// assigns the value of selected_share_translations_ids[2] to selectedShareTranslation2__Id if it's truthy, and if it's falsy, it falls back to selected_share_translations_ids[1]
    } else {
      selectedShareTranslation1__Id = selected_share_translations_ids[0];
      selectedShareTranslation2__Id = selected_share_translations_ids[1];
      selectedShareTranslation3__Id = selected_share_translations_ids[2];
    }
    if (selectedShareArabic && (selectedShareTranslation1 || selectedShareTranslation2)) {
      const translation_array = ayat_translations.filter(item =>
          item.translator.id === selectedShareTranslation1__Id || item.translator.id === selectedShareTranslation2__Id
      );

      translation_array.unshift({ "translationText": verse_arabic }); // Add the verse_arabic at the 0th index.
      if (translation_array.length > 0) {
          WriteTranslations(translation_array,surahName,verse_arabic)
      }
  }
  else if (selectedShareArabic === false && (selectedShareTranslation1 || selectedShareTranslation2 || selectedShareTranslation3)) {
      const translation_array = ayat_translations.filter(item =>
          item.translator.id === selectedShareTranslation1__Id || item.translator.id === selectedShareTranslation2__Id || item.translator.id === selectedShareTranslation3__Id
      );
      if (translation_array.length > 0) {
          WriteTranslations(translation_array, surahName)
      }      
    }
  else if (selectedShareArabic) {
    WriteArabicVerse( verse_arabic, surahName)
    }
  };

  handleShareThisVerse(props.singleVerse[scriptStyle], props.singleVerse.chapterNo, props.singleVerse.verseNo, props.singleVerse.translations)
  },
  
  [props.scriptStyle,props.singleVerse,currentDomain,scriptStyle,ChapterOptions,props.translationForShare]);


  return (
    <ShareSocialMediaModal
      t={props.t} onClose={props.onClose}
      chapters={chapters}
      singleVerse={props.singleVerse}
      onShareVerse={props.onShareVerse}
      closeSocialMediaModal={props.closeSocialMediaModal}
      scriptStyle={scriptStyle}
      quote={quote} shareUrl={shareUrl}

    />

  )
}
