import React from 'react';

export default function DisplayNotesUi(props) {
  return (
    < >
    <label className={`login_popup_title mt-2 text-white-black-theme `} >{props.t('login-to-add')}</label>
    <label className={`login_popup_description text-white-black-theme `} >   {props.t('login-to-add-hint')}</label>
    <div data-bs-toggle="modal" onClick={props.HideAddPlusPopup} data-bs-target="#loginModal" className=" m-2 login-popup-button text-white text-center  align-self-center cursor_pointer" style={{ fontSize: 14 }} >
      {props.t('login')}
    </div>
  </>

  )
}


