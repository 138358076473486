import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { auth, signInWithGoogle, signInWithApple } from "../../../apis/firebase_authentications";
import "react-phone-input-2/lib/bootstrap.css";
import { RecaptchaVerifier, signInWithPhoneNumber,  updateProfile  } from "firebase/auth";
import moment from 'moment-timezone';
import { message } from 'antd';
import LoginUI from "../Ui/LoginUi"
import {getAuthToken} from '../../../apis/PostData'
import $ from 'jquery';
import LoginImage from '../../../assets/images/LoginImg.svg'
import CrossBlack from '../../../assets/images/cross_black.svg'
import CrossWhite from '../../../assets/images/cross_white_modal.svg'

function Login(props) {
  const [user, setUser] = useState("");
  const [mynumber, setNumber] = useState("");
  const [countryCodePhone, setcountryCodePhone] = useState('');
  // const [verificationId, setVerificationId] = useState('');
  const [otp, setOtp] = useState('');
  const [final, setFinal] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [activeScreen, setActiveScreen] = useState('Screen1');
  const [imageLoaded, setImageLoaded] = useState(false);
  const userTheme = useSelector((state) => state.settings.userTheme);
  const crossIcon = userTheme === 'Dark' ? CrossWhite : CrossBlack;

  useEffect(() => {
    const image = new Image();
    image.src = LoginImage;
    image.onload = () => {
      setImageLoaded(true);
    };
    image.onerror = () => {
      setImageLoaded(false);
    };
  }, []);

  useEffect(() => {
    let countryCode = 'pk'
    try {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zone = moment.tz.zone(userTimeZone);
      countryCode = zone.countries()[0].toLowerCase(); // Get the first associated country
      // countryName = moment.tz.guess(true).split('/')[0]; // Get the first part of the guessed zone
    }
    catch (error) {
      console.log("error", error)
    }
    setcountryCodePhone(countryCode);
  }, [countryCodePhone])

  useEffect(() => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'myRecaptcha', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          
          handleScreenChange('Screen3');
        }
      });
    }
    catch (error) {
    }

  },
    // eslint-disable-next-line 
    []);

  // Generate an image URL from text (first letter of the username)
  const generateProfileImage = (text) => {
    const canvas = document.createElement("canvas");
    canvas.width = 50;
    canvas.height = 50;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#3f51b5"; // Background color
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.font = "24px Arial";
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    return canvas.toDataURL();
  }
  useEffect(() => {
    props.setIsModalHide(true);
  },
    // eslint-disable-next-line
    [props.user]);


  const handleScreenChange = (screen) => {
    setActiveScreen(screen);
    props.setIsModalHide(true);
  };

  const ShowPhoneModal = () => {
    handleScreenChange('Screen2');
  };

  const ShowSocialModal = () => {
    handleScreenChange('Screen1');

  };

  const HideModal = () => {
    setActiveScreen('Screen1');
    props.setIsModalHide(false);
  };


  const signin = () => {
    if (mynumber === "" || mynumber.length < 10) return;
    const updatedNumber = mynumber.startsWith("+") ? mynumber : "+" + mynumber;
    signInWithPhoneNumber(auth, updatedNumber, window.recaptchaVerifier)
      .then((result) => {
        setFinal(result);
        setFinal(result);
        // setVerificationId(result.verificationId);
        handleScreenChange('Screen3');
      })
  }


  // Validate OTP
  const ValidateOtp = async() => {
    if (otp === null || final === null)
      return;
      final.confirm(otp).then((result) => {
      setUser(result.user);
      setOtp('');
      setNumber('')
      if (result.user.displayName === null || result.user.displayName.trim() === "") {
        // Handle display name updation
        handleScreenChange('Screen4');
      }
      else if (result.user.displayName.length >= 1) {
        reloadUser();
        setTimeout(() => {
          getAuthToken(auth.currentUser)
        }, 5000);
      }

    }).catch((err) => {
      message.success(props.t('wrong-code'));
    })
  }
  
  const SaveDisplayName = async () => {
    const newProfileImage = generateProfileImage(displayName.charAt(0).toUpperCase());
    await updateProfile(user, {
      displayName: displayName,
      photoURL: newProfileImage,
    }).then(() => {
      // Profile updated!
      //Call sigup here for including the updated name as well
      setTimeout(() => {
        getAuthToken(user)
      }, 5000);
      reloadUser();
    }).catch((error) => {
      console.log("error in profile update", error)
    });
  }

  const reloadUser = async () => {
    try {
      await auth.currentUser.reload();
      props.setUser(auth.currentUser.displayName);
      HideModal();
      $('.modal-backdrop').remove(); // Remove the modal backdrop element
      message.success(props.t('logged-in-successfully'));
    } catch (error) {
    }
  };


  const GoogleAuth = async () => {
    const user = await signInWithGoogle(HideModal, props.t);
    if (!user) {
      props.setIsModalHide(true);
    }
    props.setIsModalHide(false);

  };

  const AppleAuth = async () => {
    const user = await signInWithApple();
    if (!user) {
      props.setIsModalHide(true);
    }
    props.setIsModalHide(false);

  };

  const onHideLoginModal = () => {
    props.onHideLoginModal()
    setOtp('');
    setNumber('')
  };
  

  return (
    <LoginUI t={props.t} mynumber={mynumber} setNumber={setNumber} countryCodePhone={countryCodePhone}
      otp={otp} setOtp={setOtp} setDisplayName={setDisplayName} activeScreen={activeScreen} isModalHide={props.isModalHide}
      ShowPhoneModal={ShowPhoneModal} ShowSocialModal={ShowSocialModal} signin={signin} ValidateOtp={ValidateOtp}
      SaveDisplayName={SaveDisplayName} GoogleAuth={GoogleAuth} imageLoaded={imageLoaded} LoginImage={LoginImage}
      setIsModalHide={props.setIsModalHide} onHideLoginModal={onHideLoginModal} crossIcon={crossIcon}
      AppleAuth={AppleAuth}
    />
  );
}

export default Login;


