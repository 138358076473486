import React from 'react'
import AppleStore from "../../../assets/images/applestore.svg"
import GoogleStore from "../../../assets/images/googleplay.svg"
import Logo from "../../../assets/images/LogoQuranApp.svg";

export default function FooterUi(props) {
    return (
        // <footer className={`border-grey3-footer mb27px bg-color-gray `}>
        <footer className={`footerrr border-grey3-footer bg-color-gray `}>

            <div className="container ">
                <div className="row data-in-center">
                    <div className="col-md-5 col-sm-12 py-3 footer-responsivness-col-md-5 ">
                        <div className="col-md-9 col-sm-12 px-0 mx_md_custom aboutus-info-div">
                            <a href="/"><img src={Logo} alt={"Logo"} /></a>
                            <p className={`aboutus-info  mt-2 footer-links-color px-0 mx_md_custom footer-text line-height-15-51 mb-1`} >
                                Unlock spiritual wisdom with our Quran app.
                                Access verses, translations, and guidance at your
                                fingertips. Explore the Holy Quran anytime, anywhere,
                                for a deeper connection to faith.
                            </p>
                            <div className=' mt-4'>
                                <a href="/"><img src={props.facebookIcon} alt={"facebook"} /></a>
                                <a className='px-4' href="/"><img src={props.instagramIcon} alt={"instagram"} /></a>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 py-3 footer-responsivness-col-md-4 px-0015 align-items-center">
                        <div className="col-12 px-0 mx_md_custom">
                            <p className={`footer-headings mt-2 mb-2 `} >{props.t('popular-links')}</p>
                            <div className='mb-1' ><a className='footer-links footer-links-color text-decoration-none' href="/2/255">  Ayatul Kursi</a></div>
                            <div className='mb-1'><a className='footer-links footer-links-color text-decoration-none' href="/36/1">  Yaseen</a></div>
                            <div className='mb-1' ><a className='footer-links footer-links-color text-decoration-none' href="/67/1">  Al Mulk</a></div>
                            <div className='mb-1' ><a className='footer-links footer-links-color text-decoration-none' href="/55/1">  Ar Rahman </a></div>
                            <div className='mb-1' ><a className='footer-links footer-links-color text-decoration-none' href="/18/1">  Al Kahaf </a></div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 py-3 px-0 footer-responsivness-col-md-3  ">
                        <div className="col-12 px-0 mx_md_custom ">
                            <p className={`footer-headings mt-2 mb-2 `} >{props.t('contact-us')}</p>
                            <div className='mb-1' ><a className='footer-links text-blue2-footer-pink text-decoration-none' href="mailto:support+quranapp@whizpool.com">support+quranapp@whizpool.com</a></div>
                            <p className={`footer-headings mt-4 mb-2 `} >{props.t('download-app')}</p>
                            <div className="flex-row  px-0 mx_md_custom place-content-center ">
                                <a rel="noreferrer" target='_blank' href='https://play.google.com/store/apps/details?id=the.explorer.quran.app'>
                                    <img
                                        className="playstore-size my-2 cursor_pointer "
                                        alt="Quran App"
                                        src={GoogleStore}
                                    />
                                </a>
                                <a rel="noreferrer" target='_blank' href='https://apps.apple.com/us/app/quran-app-read-listen-search/id1447074474?ls=1'>
                                    <img
                                        className="playstore-size mx-1 my-2 cursor_pointer"
                                        alt="Quran App"
                                        src={AppleStore}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className='hr-border-top' />
            <div className="footer-container text-center">
                <span className="copyright-info font-size-13--400 text-blue-footer-pink">
                    © The-Quran.app {new Date().getFullYear()}  Product by Whizpool - Mobile App Development Services
                </span>
                <a href='/' className='footer-link-c font-product-sans text-blue2-footer-pink separator-footer text-decoration-none px-3' target="_blank">Privacy policy</a>
                <a href='/' className='footer-link-c font-product-sans text-blue2-footer-pink text-decoration-none'>Terms & Condition</a>
            </div>

        </footer>

    )
}

