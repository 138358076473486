export function getTimeAgo(pastTime) {
    const currentTime = new Date();
    const pastDate = new Date(pastTime);
  
    const timeDifference = currentTime - pastDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(weeks / 4.35); // Approximate months
  
    if (months >= 2) {
      return `${months} months ago`;
    } else if (weeks >= 2) {
      return `${weeks} weeks ago`;
    } else if (days >= 2) {
      return `${days} days ago`;
    } else if (hours >= 2) {
      return `${hours} hours ago`;
    } else if (minutes >= 2) {
      return `${minutes} minutes ago`;
    } else if (seconds >= 1) {
      return "1 minute ago";
    } else {
      return "just now";
    }
  }
  