// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/SFUIText-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/SFUIText-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/SFUIText-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/me_quran.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/TradionalArabic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/noorehuda.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/product-sans.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/ProductSans-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../fonts/SelectedOne/AllQalam.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "SF UI REGULAR";
  src: local("SF_UI_REGULAR"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
}
@font-face {
  font-family: "SF UI BOLD";
  src: local("SF_UI_BOLD"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}
@font-face {
  font-family: "SF UI Medium";
  src: local("SF_UI_MEDIUM"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
}
@font-face {
  font-family: "simple_script_font";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: "uthmani_script_font";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
@font-face {
  font-family: "indopak_script_font";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}

@font-face {
  font-family: 'Product Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2');
}
@font-face {
  font-family: 'Product Sans Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}

@font-face {
  font-family: 'Khebrat-Musamim-Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}
.Khebrat_Musamim_Bold {
  font-family: "Khebrat-Musamim-Bold" !important;
}
.SF_UI_font_regular {
  font-family: "SF UI REGULAR" !important;
}
.SF_UI_font_bold {
  font-family: "SF UI BOLD" !important;
}
.SF_UI_font_medium {
  font-family: "SF UI Medium" !important;
}
.simple-font-family {
  font-family: "simple_script_font";
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B;0DACsD;AACxD;AACA;EACE,yBAAyB;EACzB,gFAA2E;AAC7E;AACA;EACE,2BAA2B;EAC3B;0DACqD;AACvD;AACA;EACE,iCAAiC;EACjC,4CAAiC;AACnC;;AAEA;EACE,kCAAkC;EAClC,4CAAwC;AAC1C;AACA;EACE,kCAAkC;EAClC,4CAAkC;AACpC;;AAEA;EACE,2BAA2B;EAC3B,4DAAuD;AACzD;AACA;EACE,iCAAiC;EACjC,4CAA0C;AAC5C;;AAEA;EACE,mCAAmC;EACnC,4CAA6C;AAC/C;AACA;EACE,8CAA8C;AAChD;AACA;EACE,uCAAuC;AACzC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,sCAAsC;AACxC;AACA;EACE,iCAAiC;AACnC","sourcesContent":["@font-face {\n  font-family: \"SF UI REGULAR\";\n  src: local(\"SF_UI_REGULAR\"),\n    url(\"../fonts/SFUIText-Regular.woff\") format(\"woff\");\n}\n@font-face {\n  font-family: \"SF UI BOLD\";\n  src: local(\"SF_UI_BOLD\"), url(\"../fonts/SFUIText-Bold.woff\") format(\"woff\");\n}\n@font-face {\n  font-family: \"SF UI Medium\";\n  src: local(\"SF_UI_MEDIUM\"),\n    url(\"../fonts/SFUIText-Medium.woff\") format(\"woff\");\n}\n@font-face {\n  font-family: \"simple_script_font\";\n  src: url(\"../fonts/me_quran.ttf\");\n}\n\n@font-face {\n  font-family: \"uthmani_script_font\";\n  src: url(\"../fonts/TradionalArabic.ttf\");\n}\n@font-face {\n  font-family: \"indopak_script_font\";\n  src: url(\"../fonts/noorehuda.ttf\");\n}\n\n@font-face {\n  font-family: 'Product Sans';\n  src: url(\"../fonts/product-sans.woff2\") format('woff2');\n}\n@font-face {\n  font-family: 'Product Sans Light';\n  src: url(\"../fonts/ProductSans-Light.ttf\");\n}\n\n@font-face {\n  font-family: 'Khebrat-Musamim-Bold';\n  src: url(\"../fonts/SelectedOne/AllQalam.ttf\");\n}\n.Khebrat_Musamim_Bold {\n  font-family: \"Khebrat-Musamim-Bold\" !important;\n}\n.SF_UI_font_regular {\n  font-family: \"SF UI REGULAR\" !important;\n}\n.SF_UI_font_bold {\n  font-family: \"SF UI BOLD\" !important;\n}\n.SF_UI_font_medium {\n  font-family: \"SF UI Medium\" !important;\n}\n.simple-font-family {\n  font-family: \"simple_script_font\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
