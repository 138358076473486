import React from 'react'
import CrossWhite from "../../../assets/images/cross_white.svg";

export default function NotesModal(props) {
    return (
        <div className="modal show" tabIndex="-1" onClick={(e) => e.target.classList.contains('modal') && props.onClose()} id="myModal" role="dialog" aria-labelledby="myModalLabel">
            <div className="dark__overlay" onClick={(e) => e.target.classList.contains('dark__overlay') && props.onClose()} />
            <div className="modal-dialog modal-dialog-centered  ">
                <div className="modal-content darkBackground dark-mode-modal-border ">
                    <div className="modal-header dark-mode-modal-header dark-mode-modal-border-bottom">
                        <div className=" d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '3rem' }}>
                            <h5 className=" modal-title model-title-text align-self-center">
                                {props.chapters ?
                                    props.chapters.chapters[props.NotesData.chapterNo - 1].alphaArabicName + ' (' + props.NotesData.chapterNo + ":" + props.NotesData.verseNo + ")"
                                    : props.NotesData.verse.chapter.alphaArabicName + ' (' + props.NotesData.verse.chapterNo + ":" + props.NotesData.verse.verseNo + ")"
                                }

                            </h5>
                            <img className='model-cross-icon align-self-center ml-2'
                                alt='' onClick={() => props.onClose()} src={CrossWhite} />
                        </div>
                    </div>
                    <div className="modal-body  " >
                        <label className={`text-white-black-theme font-size-14-400 mb-2`}>{props.notesText? props.t('edit-your-notes'): props.t('enter-your-notes')}:</label>
                        <textarea
                            className={`text-white-black-theme bg-dark-light dark-mode-modal-writing-area-border notes_detail_input font-size-14-400 px-2 w-100 `}

                            placeholder="Type here"
                            type="text"
                            value={props.notesText}
                            onChange={props.handleNotesChange}
                        />

                    </div>
                    <div className="modal-footer modal-footer-height  dark-mode-modal-border-top notes-modal-footer-margin">
                        <div className="flex-container custom-column-yes-no mb-3   justify-content-center align-items-center w-100 text-center" style={{ height: '0rem' }}>
                            <div className={`model_button_yes_no darkBackground model_button cursor_pointer px-4 py-3  `} title={(props.t('save-notes'))} onClick={() => props.onSaveNotes()}>
                                <label className={` text-white-blue-2-theme cursor_pointer`}>{(props.t('save-notes'))}</label>
                            </div>
                            {props.pages==='AllNotes' ?
                                <div className={`model_button_yes_no darkBackground model_button cursor_pointer px-4 mx-2 py-3 text-center`} title={(props.t('cancel'))} onClick={props.onClose}>
                                    <label className={` text-white-blue-2-theme cursor_pointer`} style={{ "color": "rgb(106, 106, 106)" ,"textTransform": "uppercase"  }}>{(props.t('cancel'))}</label>
                                </div>
                                :
                                <div className={`model_button_yes_no darkBackground model_button cursor_pointer px-4 mx-2 py-3 text-center `} title={(props.t('delete-notes'))} onClick={props.notesIdToUpdate ? props.onDeleteNotes : undefined}>
                                    <label className={` text-white-blue-2-theme cursor_pointer`} style={{ "color": "rgb(106, 106, 106)" }}>{(props.t('delete-notes'))}</label>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
