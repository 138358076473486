import axios from '../apis/AxiosInterceptors';

const FindChapterIndopak = (chapter) => {
  
  let url = process.env.REACT_APP_INDO_PAK_API +
    `quran/verses/indopak?chapter_number=${chapter}`;
    return axios.get(url);
};

const FindAllTranslations = (chapter, verse, reciter, selectedTranslations) => {
  let queryStringTranslation = '';
  if (selectedTranslations) {
    selectedTranslations.forEach(translationId => {
      queryStringTranslation += `&translatorId[]=${translationId}`;
    });
  }
  let url = process.env.REACT_APP_API_URL +
    `verses/find-all-with-translations?where[chapterNo]=${chapter}&where[fromVerse]=${verse}&reciter=${reciter}${queryStringTranslation}`;
    return axios.get(url);
};

const findAllTranslationsWithPagination = (token) => {
  let url =
    process.env.REACT_APP_API_URL +
    "verses/find-all-with-translations?nextPageToken=" +
    token;

  return axios.get(url);
};


const findAllChapters = () => {
  let url = process.env.REACT_APP_API_URL + "chapters";
  return axios.get(url);
};


const findAllTranslatorScriptsReciters = () => {
  let url =
    process.env.REACT_APP_API_URL + "translators/reciters-translators-scripts";
  return axios.get(url);
};


const fetchRecitationAudio = (reciterId, chapterNo, verseNo, limit) => {
  let url = process.env.REACT_APP_API_URL + 'recitations/url?reciterId=' + reciterId + "&chapNo=" + chapterNo + "&verseNo=" + verseNo + "&limit=" + limit;
  return axios.get(url);
};

const fetchSearchResult = (searchText) => {
  const encodedSearchText = encodeURIComponent(searchText);
  let url = process.env.REACT_APP_API_URL + 'verses/search?searchText='+encodedSearchText;
  return axios.get(url);
};

const fetchRootSearchResult = (searchText) => {
  const encodedSearchText = encodeURIComponent(searchText);
  let url = process.env.REACT_APP_API_URL + 'word-by-word-translations/root?rootWord='+encodedSearchText;
  return axios.get(url);
};

const findAllCollections = (sortType) => {
  const sortRelation=sortType?`?relations[]=collectionVerse&order[collectionName]=${sortType==='True' ? "DESC" : sortType==='False' && "ASC" }` :'';
  let url = process.env.REACT_APP_API_URL + `collections/${sortRelation}`;
  return axios.get(url);
};

const findCollectionDetail = (collectionId) => {
  let url = process.env.REACT_APP_API_URL+ `collections/${collectionId}?`;
  return axios.get(url);
};

const findAllBookmarks = () => {
  let url = process.env.REACT_APP_API_URL + `bookmarks?relations[]=verse.chapter`;
  return axios.get(url);
};
const findAllNotes = () => {
  let url = process.env.REACT_APP_API_URL + `notes?relations[]=verse.chapter`;
  return axios.get(url);
};

export {
  FindAllTranslations,
  findAllTranslationsWithPagination,
  findAllChapters,
  findAllTranslatorScriptsReciters,
  fetchRecitationAudio,
  fetchSearchResult,
  fetchRootSearchResult,
  findAllCollections,
  findCollectionDetail,
  findAllBookmarks,
  findAllNotes,
  FindChapterIndopak
};

