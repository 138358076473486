import { React,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SettingsReducer } from "../../../redux/Setting"
import { useEffect } from "react";
import ApperanceUi from '../UI/ApperanceUi'
const SettingActions = SettingsReducer.actions;



function Apperance(props) {
  const dispatch = useDispatch()
  const selectedScript = useSelector((state) => state.settings.script);
  const arabicFontSize = useSelector((state) => state.settings.arabicFontSize);
  const arabicFontText = useSelector((state) => state.settings.arabicFontText);
  const translationFontSize = useSelector((state) => state.settings.translationFontSize);
  const translationFontText = useSelector((state) => state.settings.translationFontText);
  const selectedTheme = useSelector((state) => state.settings.userTheme);
  const [arabicFontValue,setArabicFontValue]= useState(arabicFontSize);
  const [translationFontValue,setTranslationFontValue]= useState(translationFontSize);

  const min = 12; 
  const max = 64; 
  const percentage = ((arabicFontValue - min) / (max - min)) * 100;
  const percentageTranslation = ((translationFontValue - min) / (max - min)) * 100;

  const trackStyleArabic = {
    background: `linear-gradient(90deg, #006B9E ${percentage}%, #D9D9D9 ${percentage}%)`,
  };
  const trackStyleTranslation = {
    background: `linear-gradient(90deg, #006B9E ${percentageTranslation}%, #D9D9D9 ${percentageTranslation}%)`,
  };
  
  const handleThemeChange = (theme) => {
    dispatch(SettingActions.updateSettings({ userTheme: theme }));
  }
  const handleChangeArabic = (e) => {
    const newValue = e.target.value;
    
    setArabicFontValue(newValue)
    if (newValue>= 12 && newValue<= 24  )
    {
      dispatch(SettingActions.updateSettings({ arabicFontText: "Small" }));
      dispatch(SettingActions.updateSettings({ arabicFontSize: 1.62500 })); //convert from px to 26/16 rem
    }
    else if (newValue>= 25 && newValue<= 36  )
    {
      dispatch(SettingActions.updateSettings({ arabicFontText: "Medium" }));
      dispatch(SettingActions.updateSettings({ arabicFontSize: 2.37500 }));//38/16
    }
    else if (newValue>= 27 && newValue<= 48  )
    {
      dispatch(SettingActions.updateSettings({ arabicFontText: "Large" }));
      dispatch(SettingActions.updateSettings({ arabicFontSize: 3.12500 }));//50/16
    }
    else if (newValue>= 49 && newValue<= 64  )
    {
      dispatch(SettingActions.updateSettings({ arabicFontText: "Huge" }));
      dispatch(SettingActions.updateSettings({ arabicFontSize: 4 }));//64/16
    }
  };

  
  const handleChangeTranslation = (e) => {
    const newValue = e.target.value;
    setTranslationFontValue(newValue)
    if (newValue>= 12 && newValue<= 24  )
    {
      dispatch(SettingActions.updateSettings({ translationFontText: "Small" }));
      dispatch(SettingActions.updateSettings({ translationFontSize: 1.25 }));// 20/16
    }
    else if (newValue>= 25 && newValue<= 36  )
    {
      dispatch(SettingActions.updateSettings({ translationFontText: "Medium" }));
      dispatch(SettingActions.updateSettings({ translationFontSize: 2 }));//32/16
    }
    else if (newValue>= 27 && newValue<= 48  )
    {
      dispatch(SettingActions.updateSettings({ translationFontText: "Large" }));
      dispatch(SettingActions.updateSettings({ translationFontSize: 2.75 }));//44/16
    }
    else if (newValue>= 49 && newValue<= 64  )
    {
      dispatch(SettingActions.updateSettings({ translationFontText: "Huge" }));
      dispatch(SettingActions.updateSettings({ translationFontSize:  3.625 }));//58/16
    }
  };


  useEffect(() => {
  }, [selectedScript]);

  const handleScriptChange = (script) => {
    dispatch(SettingActions.updateSettings({ script: script }));
  };
  const onOverlayClick = () => {
    props.handleClickAway_CloseMenu()
  }

  return (
    <ApperanceUi t={props.t} closeSettings={props.closeSettings} selectedMenu={props.selectedMenu} 
    handleScriptChange={handleScriptChange}
    selectedScript={selectedScript}
     onOverlayClick={onOverlayClick}
     arabicFontValue={arabicFontValue}
     arabicFontSize={arabicFontSize}
     arabicFontText={arabicFontText}
     translationFontValue={translationFontValue}
     translationFontSize={translationFontSize}
     translationFontText={translationFontText}
     handleChangeArabic={handleChangeArabic}
     handleChangeTranslation={handleChangeTranslation}
     trackStyleArabic={trackStyleArabic}
     trackStyleTranslation={trackStyleTranslation}
     handleThemeChange={handleThemeChange}
     selectedTheme={selectedTheme}
    />
  )
}

export default Apperance
