import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import AddTooltipUi from "../../../Ui/Home/AddSection/AddToolTipUi";
import AddIcon from "../../../../../assets/images/Add.svg";
import AddGray from "../../../../../assets/images/Add-Gray.svg";


const AddTooltip = (props) => {
  const [deleteNotesId, setDeleteNotesId] = useState(null)
  const [isDeleteConfirmationsModalOpen, setIsDeleteConfirmationsModalOpen] = useState(false);
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  const [isAddNewCollectionModalOpen, setIsAddNewCollectionModalOpen] = useState(false);
  const [isBookmarkConfirmationsModalOpen, setIsBookmarkConfirmationsModalOpen] = useState(false);
  const userTheme = useSelector((state) => state.settings.userTheme);
  const Add = userTheme === 'Dark' ? AddGray : AddIcon;

  const bookmarkDeleteConfirmation = (verseId) => {
    setIsBookmarkConfirmationsModalOpen(true)
    setDeleteNotesId(verseId)
  }

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationsModalOpen(false);
  };

  const closeBookmarkDeleteConfirmationModal = () => {
    setIsBookmarkConfirmationsModalOpen(false);
  };

  const HideAddPlusPopup = () => {
    props.setShowTooltip(false);
    props.setActiveImageIndex(null);
  };

  const closeCollectionModal = () => {
    setIsCollectionModalOpen(false);
  };

  const closeAddNewCollectionModal = () => {
    setIsAddNewCollectionModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if ( props.showTooltip && props.activeImageIndex !== null &&!e.target.closest('.tooltip-container')) {
        props.setShowTooltip(false);
        props.setActiveImageIndex(null);
      }
    };
  document.addEventListener('click', handleClickOutside);
  document.addEventListener('keydown', handleClickOutside);
  return () => {
    document.removeEventListener('click', handleClickOutside);
    document.removeEventListener('keydown', handleClickOutside);

  };
  },
      // eslint-disable-next-line
  [props.showTooltip, props.activeImageIndex]);


  return (
    <AddTooltipUi
      loading={props.loading}
      setLoading={props.setLoading}
      t={props.t}
      onDeleteNotesBadge={props.onDeleteNotesBadge}
      bookmarkDeleteConfirmation={bookmarkDeleteConfirmation}
      chapters={props.chapters}
      isNotesModalOpen={props.isNotesModalOpen}
      setIsNotesModalOpen={props.setIsNotesModalOpen}
      NotesData={props.NotesData}
      setNotesData={props.setNotesData}
      deleteNotesId={deleteNotesId}
      setDeleteNotesId={setDeleteNotesId}
      isDeleteConfirmationsModalOpen={isDeleteConfirmationsModalOpen}
      setIsDeleteConfirmationsModalOpen={setIsDeleteConfirmationsModalOpen}
      isCollectionModalOpen={isCollectionModalOpen}
      setIsAddNewCollectionModalOpen={setIsAddNewCollectionModalOpen}
      isAddNewCollectionModalOpen={isAddNewCollectionModalOpen}
      isBookmarkConfirmationsModalOpen={isBookmarkConfirmationsModalOpen}
      setIsBookmarkConfirmationsModalOpen={setIsBookmarkConfirmationsModalOpen}
      closeNotesModal={props.closeNotesModal}
      closeDeleteConfirmationModal={closeDeleteConfirmationModal}
      closeBookmarkDeleteConfirmationModal={closeBookmarkDeleteConfirmationModal}
      closeGrammerModal={props.closeGrammerModal}
      updateGrammerVerseId={props.updateGrammerVerseId}
      closeCollectionModal={closeCollectionModal}
      closeAddNewCollectionModal={closeAddNewCollectionModal}
      setIsCollectionModalOpen={setIsCollectionModalOpen}
      HideAddPlusPopup={HideAddPlusPopup}
      handlePlusClick={props.handlePlusClick}
      showTooltip={props.showTooltip}
      index={props.index}
      activeImageIndex={props.activeImageIndex}
      user={props.user}
      singleVerse={props.singleVerse}
      allVerses={props.allVerses}
      Add={Add}
    />
  );
};

export default AddTooltip;
