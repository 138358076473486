import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import 'reactjs-popup/dist/index.css'; // Import the CSS for reactjs-popup
import {
  FindAllTranslations,
  findAllTranslationsWithPagination,
  // FindChapterIndopak
} from "../../../../apis/FetchData";
import { addToBookmark, deleteBookmark, getAuthTokenWithOutSignUp } from "../../../../apis/PostData";
import firebase from "firebase/compat/app";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message } from 'antd';
import { auth } from "../../../../apis/firebase_authentications";
import CopyVerseGray from "../../../../assets/images/CopyVerseGray.svg";
import CopyVerseWhite from "../../../../assets/images/CopyVerse.svg";
import Home from "../../Ui/Home/HomeUi";
import { convertToSearchableChapters } from '.././SearchableChapters';
import { SettingsReducer } from "../../../../redux/Setting"
const SettingActions = SettingsReducer.actions;

const HomeContainer = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const chapters = useSelector((state) => state.chapters);
  const reciterNames = useSelector((state) => state.chapters.reciters);
  const selectedReciterId = useSelector((state) => state.settings.selectedReciterId);
  const selectedReciterName = useSelector((state) => state.settings.selectedReciterName);
  const showTranslation = useSelector((state) => state.settings.showTranslation);
  const selectedTranslations = useSelector((state) => state.settings.selectedTranslations);
  const jumpToVerse = useSelector((state) => state.settings.jumpToVerse);
  const playingVerse = useSelector((state) => state.settings.playingVerse);
  const playingSurah = useSelector((state) => state.settings.playingSurah);
  const playingVerseRef = useRef(null);
  const [allVerses, setAllVerses] = useState([]);
  // const [indoPakVerses, setIndoPakVerses] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  const [loading, setLoading] = useState(true);
  const childRef = useRef(null);
  const topOfPageRef = useRef(null);
  const playState = useSelector((state) => state.settings.playState);
  const [isGrammerModalOpen, setIsGrammerModalOpen] = useState(false);
  const [grammerModalVerse, setGrammerModalVerse] = useState('');
  const [grammerModalVerseId, setGrammerModalVerseId] = useState('');
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [deleteNotesId, setDeleteNotesId] = useState(null)
  const [isDeleteConfirmationsModalOpen, setIsDeleteConfirmationsModalOpen] = useState(false);
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  const [isAddNewCollectionModalOpen, setIsAddNewCollectionModalOpen] = useState(false);
  const [isBookmarkConfirmationsModalOpen, setIsBookmarkConfirmationsModalOpen] = useState(false);
  const [user, setUser] = useState(auth.currentUser);
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [showVerseHoverPopup, setShowVerseHoverPopup] = useState(false);
  const [activeVerseWordIndex, setActiveVerseWordIndex] = useState(false);
  const [activeVerseIndex, setActiveVerseIndex] = useState(false);
  const arabicFontSize = useSelector((state) => state.settings.arabicFontSize);
  const translationFontSize = useSelector((state) => state.settings.translationFontSize);
  const scriptStyle = useSelector((state) => state.settings.script);
  const historyList = useSelector((state) => state.settings.historyList);
  const ChapterOptions = convertToSearchableChapters(chapters);
  const [FirstVerse, setFirstVerse] = useState(null);
  const userTheme = useSelector((state) => state.settings.userTheme);
  const CopyVerse = userTheme === 'Dark' ? CopyVerseGray : CopyVerseWhite;
  const [cursorInsidePopupDiv, setCursorInsidePopupDiv] = useState(false);

  
  useEffect(() => {
    getAuthTokenWithOutSignUp(user)
      // .then(idToken => {
      //   dispatch(SettingActions.updateSettings({ firebaseToken: idToken }));
      // })
      // .catch(error => {
      //   // console.error("Error:", error);
      // });
  }, [dispatch, user])

  useEffect(() => {
    if (selectedReciterName === '') {
      const initialReciterId = reciterNames.length > 0 ? reciterNames[0].id : 0;
      const initialReciterName = reciterNames.length > 0 ? reciterNames[0].reciter : '';
      dispatch(SettingActions.updateSettings({ selectedReciterId: initialReciterId }));
      dispatch(SettingActions.updateSettings({ selectedReciterName: initialReciterName }));
    }
  });


  const arabicFontText = useSelector((state) => state.settings.arabicFontText);
 
  const isVariablePresent = arabicFontText !== undefined && arabicFontText !== null && arabicFontText !== 'small' && arabicFontText !== '';

  const appendToHistoryList = (originalHistoryList, newHistory) => {
    if (!originalHistoryList.some(arr => JSON.stringify(arr) === JSON.stringify(newHistory))) {
      // Check if newHistory is not already in originalHistoryList
      return [...originalHistoryList, newHistory];
    }
    return originalHistoryList;
  };

  const getAllTranslations = useCallback(async () => {
    setLoading(true);//make this true to loading
    let locationParts = location.pathname.split("/");
    let surah = 1;
    let verse = 1;

    if (locationParts.length > 2) {
      surah = locationParts[1];
      verse = locationParts[2];
    }
    // Append to history 
    const surahName = ChapterOptions.find(option => option.value === parseInt(surah))
    try {
      const updatedHistoryList = appendToHistoryList(historyList, [surahName.label, surah, verse]);
      dispatch(SettingActions.updateSettings({ historyList: updatedHistoryList }));
    }
    catch {
    }
    if (parseInt(surah)>1 && parseInt(verse)===1)
    {
      let result = await FindAllTranslations(1, 1, selectedReciterId, selectedTranslations);
      if (result && result.status===200) {
        setFirstVerse(result.data.verse && result.data.verse[0]);
      }
    }
    let result = await FindAllTranslations(surah, verse, selectedReciterId, selectedTranslations);
    // let resultIndoPak = await FindChapterIndopak(surah);

    if (result && result.status) {
      // console.log("resultIndoPak.verses:",resultIndoPak.data.verses)

      // setIndoPakVerses(resultIndoPak && resultIndoPak.status===200 && resultIndoPak.data.verses);
      setAllVerses(result.data.verse);
      setNextPageToken(result.data.nextPageToken);
      setLoading(false); // Set loading to false once authentication state is checked.
    }
    // console.log("indoPakVerses:",indoPakVerses)
  },
    // eslint-disable-next-line 
    [location, selectedTranslations, selectedReciterId, historyList]);

  const findAllTranslationsWithPaginationList = async () => {
    if (nextPageToken) {
      let result = await findAllTranslationsWithPagination(nextPageToken);
      if (result &&  result.status) {
        setAllVerses([...allVerses, ...result.data.verse]);
        setNextPageToken(result.data.nextPageToken);
        setLoading(false);
      }
    }
    else {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Send request to get verses and its translations
    getAllTranslations()
  },
    [getAllTranslations])

    const scrollToTopOfPage=()=>{
      topOfPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  useEffect(() => {
    scrollToTopOfPage()
  }, [props.selectedVerse]);

  const handleScroll = (event) => {
    if (showTooltip && activeImageIndex !== null) {
      // Scrolled, hide the tooltip of Add Collection etc 
      setShowTooltip(false);
      setActiveImageIndex(null);
    }
    const target = event.target;
    const threshold = 1;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight + threshold) {
      setLoading(true);
      findAllTranslationsWithPaginationList();
    }
  };

  useEffect(() => {
    if (playingVerseRef.current && jumpToVerse) {
      playingVerseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else if(!playingVerseRef.current && jumpToVerse) {
      // scroll to top of the page
      scrollToTopOfPage()
    }
  }, [playingVerse, jumpToVerse]);


  //Calling child function in Parent component
  const callPlayFunction = (playingSurah,playingVerse) => {
    if (childRef.current) {
      childRef.current.playVerse(playingSurah,playingVerse, !playState,true);
      dispatch(SettingActions.updateSettings({ playState: !playState }));
      dispatch(SettingActions.updateSettings({ playingSurah: playingSurah }));

    }
  };

  const copyVerse = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy).then(() => {
        message.success(t('verse-coppied'));
      }).catch(() => {
        message.error(t('failed-to-copy-verse'));
      });
    } catch (error) {
      console.error('Failed to copy verse:', error);
    }
  };

  const openGrammerModal = (verseGrammer, verseid) => {
    setIsGrammerModalOpen(true);
    setGrammerModalVerse(verseGrammer);
    setGrammerModalVerseId(verseid);
  };

  const closeGrammerModal = () => {
    setIsGrammerModalOpen(false);
  };

  const updateGrammerVerseId = (newId) => {
    setGrammerModalVerseId(newId);
  };
  const AddToLastRead = (chap_No, verse_No) => {
    dispatch(SettingActions.updateSettings({ lastRead: chap_No + ':' + verse_No }));
    message.success(t('set-last-read'));
    HideAddPlusPopup()
  };

  const AddToBookMark = async (verseId, verseNo, chapterNo, chapterName) => {
    HideAddPlusPopup()
    setLoading(true);
    let result = await addToBookmark(verseId, verseNo, chapterNo, chapterName);
    if (result &&  result.status === 201) {
      const verseToUpdate = allVerses.find((verse) => verse.id === verseId);
      if (verseToUpdate) {
        // Push the bookmark data into the 'bookmark' list of that verse        
        verseToUpdate.bookmark.push({ 'chapterName': chapterName, 'verseId': verseId, 'chapterNo': chapterNo, 'verseNo': verseNo });
      }
      message.success(t('bookmark-success'));
    }
    setLoading(false);
  };

  const RemoveBookmark = async (verseId) => {
    HideAddPlusPopup()
    if (isBookmarkConfirmationsModalOpen) {
      setIsBookmarkConfirmationsModalOpen(false)
    }
    setLoading(true);
    let result = await deleteBookmark(verseId);
    if (result && result.status === 200) {
      const verseToUpdate = allVerses.find((verse) => verse.id === verseId);
      if (verseToUpdate) {
        // Clear the 'bookmark' list of that verse
        verseToUpdate.bookmark = [];
      }
      message.success(t('remove-bookmark-success-msg'));
    }
    setLoading(false);
  };
  const bookmarkDeleteConfirmation = (verseId) => {
    setIsBookmarkConfirmationsModalOpen(true)
    setDeleteNotesId(verseId)
  }

  const AddToNotes = (singleVerse) => {
    setNotesData(singleVerse)
    setIsNotesModalOpen(true);
    HideAddPlusPopup()

  };

  const closeNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationsModalOpen(false);
  };

  const closeBookmarkDeleteConfirmationModal = () => {
    setIsBookmarkConfirmationsModalOpen(false);
  };

  const HideAddPlusPopup = () => {
    setShowTooltip(false);
    setActiveImageIndex(null);
  };

  const AddToCollections = (verseId) => {
    HideAddPlusPopup()
    setNotesData([verseId])
    setIsCollectionModalOpen(true);
  };

  const closeCollectionModal = () => {
    setIsCollectionModalOpen(false);
  };

  const closeAddNewCollectionModal = () => {
    setIsAddNewCollectionModalOpen(false);
  };
  const onDeleteNotesBadge = (singleVerse) => {
    setNotesData(singleVerse)
    setIsDeleteConfirmationsModalOpen(true);
    setIsNotesModalOpen(false);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user]);

  const SearchWord = (word, rootWord) => {
    if (rootWord !== null && rootWord !== undefined && rootWord.length > 0) {
      navigate('/search/rootword:' + rootWord);
    }
    else {
      navigate('/search/' + word);

    }
  }

  const removeQuranicSymbols = (verse) => {
   // Define a regular expression pattern to match specific Arabic Unicode characters
   const specificArabicCharsRegex = /[\u0600\u0610\u0640\u0650\u0660\u0670\u06E0\u06F0\u0601\u0611\u0651\u06E1\u0602\u0612\u0652\u06E2\u0603\u0613\u0653\u06E3\u0604\u0614\u0654\u06D4\u06E4\u0605\u0615\u0655\u06E5\u0616\u0656\u06D6\u0617\u0657\u06D7\u06E7\u0618\u0658\u06D8\u06E8\u0619\u0659\u06D9\u06E9\u061A\u065A\u06DA\u06EA\u061B\u064B\u065B\u06DB\u06EB\u064C\u065C\u06DC\u06EC\u061D\u064D\u065D\u066D\u06DD\u06ED\u061E\u064E\u065E\u06DE\u061F\u064F\u065F\u06DF\u06D6\u06D9\u06DA]/g;
  // Replace matched characters with an empty string
  const cleanedString = verse.replace(specificArabicCharsRegex, '')
  return cleanedString;
  };  
  

  const togglePopupOverVerse = (index1, index) => {
    setCursorInsidePopupDiv(true)
    setActiveVerseWordIndex(activeVerseWordIndex === index1 ? null : index1);
    setActiveVerseIndex(index)
    setShowVerseHoverPopup(!showVerseHoverPopup);
  };
  const handleMouseLeave = () => {
      setActiveVerseWordIndex(null);
      setActiveVerseIndex(null);
  };

  const handleMouseEnterPopup = () => {
    setCursorInsidePopupDiv(true)

  };
  
  const handleMouseLeavePopup = () => {
    setCursorInsidePopupDiv(false)
  };
 
  const WordPoup = (word, versewordbywordTranslation, index,complete_verse) => {
    try
    {

    const clean_word=removeQuranicSymbols(word)
    const cleaned_complete_verse=removeQuranicSymbols(complete_verse).replace("  "," ").split(" ");
    const filtered_cleaned_complete_verse = cleaned_complete_verse.filter(item => item !== '');
    const selectedWord = filtered_cleaned_complete_verse.indexOf(clean_word);
    let filterString = versewordbywordTranslation[selectedWord].translation

    if (/-/.test(filterString)) {
      filterString = filterString.replace('-', '');
    }
    if (/,/.test(filterString)) {

      filterString = filterString.replace(',', '');
    }
    if (/\./.test(filterString)) {
      filterString = filterString.replace('.', '');
    }

    if (versewordbywordTranslation[selectedWord] === null || versewordbywordTranslation[selectedWord] === undefined)
      return null;
    else
      return (
        <div className="word_popup_outside_div popuppp ">
        {cursorInsidePopupDiv && (
        <div className={`darkBackground word_popup `}
        onClick={(e) => {
          e.stopPropagation();
          console.log("Popup clicked");
        }}
        onMouseEnter={() => handleMouseEnterPopup()} onMouseLeave={() => handleMouseLeavePopup()} >
          {/* {versewordbywordTranslation[selectedWord].root && versewordbywordTranslation[selectedWord].root.length>0 && */}
          <div className={` w-auto align-self-center pl-2 mr-4 verse-popup-text themePopup `} nowrap="true">
            {/* {versewordbywordTranslation[selectedWord].root} */}
            {word}
          </div>
          {/* } */}
          <div
            className={`w-auto align-self-center pl-wordpopup-4 verse-popup-text themePopup nowrapword`}
            nowrap="true">
            {filterString}
          </div>
          <div
            className='  w-auto verse_button text-white align-self-center ml-buuton-4 cursor_pointer search-button-verse-popup'
            onClick={() => SearchWord(word, versewordbywordTranslation[selectedWord].root)}
            nowrap="true">
           {(t('search'))}
          </div>
        </div>
          )}
          </div>
      );
    }
    catch(e){
      console.log("erros is e:",e)
    }
  };


  const handlePlusClick = (index) => {
    setShowTooltip(!showTooltip);
    setActiveImageIndex(activeImageIndex === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (showTooltip && activeImageIndex !== null && !e.target.closest('.tooltip-container')) {
        // Clicked outside the tooltip container, hide the tooltip
        setShowTooltip(false);
        setActiveImageIndex(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);

    };
  }, [showTooltip, activeImageIndex]);

 
  const wordRefs = useRef([]);
  return (
    <Home
      loading={loading}
      setLoading={setLoading}
      selectedSurah={props.selectedSurah}
      selectedVerse={props.selectedVerse}
      t={t}
      allVerses={allVerses}
      playingVerse={playingVerse}
      playingSurah={playingSurah}
      copyVerse={copyVerse}
      openGrammerModal={openGrammerModal}
      user={user}
      setUser={setUser}
      AddToCollections={AddToCollections}
      AddToNotes={AddToNotes}
      RemoveBookmark={RemoveBookmark}
      AddToBookMark={AddToBookMark}
      AddToLastRead={AddToLastRead}
      onDeleteNotesBadge={onDeleteNotesBadge}
      bookmarkDeleteConfirmation={bookmarkDeleteConfirmation}
      showTranslation={showTranslation}
      childRef={childRef}
      handleScroll={handleScroll}
      location={location}
      chapters={chapters}
      reciterNames={reciterNames}
      playingVerseRef={playingVerseRef}
      callPlayFunction={callPlayFunction}
      playState={playState}
      isVariablePresent={isVariablePresent}
      selectedReciterId={selectedReciterId}
      selectedReciterName={selectedReciterName}
      isGrammerModalOpen={isGrammerModalOpen}
      setIsGrammerModalOpen={setIsGrammerModalOpen}
      grammerModalVerse={grammerModalVerse}
      setGrammerModalVerseId={setGrammerModalVerseId}
      grammerModalVerseId={grammerModalVerseId}
      isNotesModalOpen={isNotesModalOpen}
      setIsNotesModalOpen={setIsNotesModalOpen}
      notesData={notesData}
      setNotesData={setNotesData}
      deleteNotesId={deleteNotesId}
      setDeleteNotesId={setDeleteNotesId}
      isDeleteConfirmationsModalOpen={isDeleteConfirmationsModalOpen}
      setIsDeleteConfirmationsModalOpen={setIsDeleteConfirmationsModalOpen}
      isCollectionModalOpen={isCollectionModalOpen}
      setIsAddNewCollectionModalOpen={setIsAddNewCollectionModalOpen}
      isAddNewCollectionModalOpen={isAddNewCollectionModalOpen}
      isBookmarkConfirmationsModalOpen={isBookmarkConfirmationsModalOpen}
      setIsBookmarkConfirmationsModalOpen={setIsBookmarkConfirmationsModalOpen}
      closeNotesModal={closeNotesModal}
      closeDeleteConfirmationModal={closeDeleteConfirmationModal}
      closeBookmarkDeleteConfirmationModal={closeBookmarkDeleteConfirmationModal}
      closeGrammerModal={closeGrammerModal}
      updateGrammerVerseId={updateGrammerVerseId}
      closeCollectionModal={closeCollectionModal}
      closeAddNewCollectionModal={closeAddNewCollectionModal}
      setIsCollectionModalOpen={setIsCollectionModalOpen}
      HideAddPlusPopup={HideAddPlusPopup}
      WordPoup={WordPoup}
      handlePlusClick={handlePlusClick}
      showTooltip={showTooltip}
      activeImageIndex={activeImageIndex}
      setActiveImageIndex={setActiveImageIndex}
      togglePopupOverVerse={togglePopupOverVerse}
      showVerseHoverPopup={showVerseHoverPopup}
      activeVerseWordIndex={activeVerseWordIndex}
      activeVerseIndex={activeVerseIndex}
      handleMouseLeave={handleMouseLeave}
      wordRefs={wordRefs}
      translationFontSize={translationFontSize}
      arabicFontSize={arabicFontSize}
      scriptStyle={scriptStyle}
      setShowTooltip={setShowTooltip}
      topOfPageRef={topOfPageRef}
      FirstVerse={FirstVerse}
      CopyVerse={CopyVerse}
      // indoPakVerses={indoPakVerses}
    />
  );
};

export default HomeContainer;
