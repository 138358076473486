import { combineReducers } from "redux"; //To Combine all reducers in a single reducer
import { configureStore } from "@reduxjs/toolkit"; //To create the redux store
import { ChaptersReducer } from "../AllChapter";
import {SettingsReducer} from "../Setting"

import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";//redux-persist is used with Redux to persist the state of your application across page reloads, allowing you to maintain the state even after the browser is refreshed or closed.
  
import storage from "redux-persist/lib/storage";//to interact with the browser's local storage or session storage


const rootPersistConfig = {
    key: "whizpoolreactappfull" + process.env.REACT_APP_ENVIRONMENT,
    storage: storage,
  };


  const reducers = combineReducers({
    chapters: ChaptersReducer.reducer,
    settings: SettingsReducer.reducer,
  });

  const persistedReducer = persistReducer(rootPersistConfig, reducers); //creating a new "enhanced" reducer that automatically handles the state persistence and rehydration.
//This persistedReducer is then will be used when creating the Redux store.

export default configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],//ignoredActions: This means that actions with these types will not be subject to the default serialization check, and no warning will be raised if they contain non-serializable data. Ignoring these specific action types is appropriate because they are internal actions used by redux-persist during the state persistence and rehydration processes  
        },
      }),
  });

