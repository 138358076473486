import React, { useState,useEffect } from 'react'
import {  useSelector } from "react-redux";
import { message } from 'antd';
import { addToCollection } from '../../../apis/PostData'
import CollectionModal from '../Ui/CollectionsUi'
import CrossBlack from "../../../assets/images/cross_black_modal.svg";
import CrossWhite from "../../../assets/images/cross_white_collectionmodal.svg";
import CollectionPopup from "../../../assets/images/collection_popup_icon.33d1a107.svg";
import CollectionPopupWhite from "../../../assets/images/collection_popup_iconWhite.svg";
import Book from "../../../assets/images/collection/colletion-book.svg"
import BookWhite from "../../../assets/images/collection/colletion-book-white.svg"
import { deleteVerseFromCollection } from '../../../apis/PostData'
import {
    findAllCollections,
  } from "../../../apis/FetchData";


export default function CollectiontModals(props) {
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [collectionData, setCollectionData] = useState(null);
    const userTheme = useSelector((state) => state.settings.userTheme);
    const crossIcon = userTheme === 'Dark' ? CrossWhite : CrossBlack;
    const collectionPopup = userTheme === 'Dark' ? CollectionPopupWhite : CollectionPopup;
    const BookIcon = props.userTheme === 'Dark' ? BookWhite : Book;

    const handleCollectionClick = (id) => {
        setSelectedCollection(id);
    };

    const AddNewCollection = () => {
        props.setIsAddNewCollectionModalOpen(true);
        props.setIsCollectionModalOpen(false);
    };

    const onSaveInCollections = async () => {
        if(selectedCollection==null)
        {
            message.error(props.t('select-a-collection-or-add-one'));
            return
        }
        props.setLoading(true);
        console.log("props.NotesData:",props.NotesData)
        let result = await addToCollection(props.NotesData[0],selectedCollection);
        if (result && result.status===201) {
            if(props.NotesData[2]==='Delete')
            {
                await deleteVerseFromCollection(props.NotesData[1]);
                props.setDeleted(true)
            }
            props.setIsCollectionModalOpen(false)
            message.success(props.t('add-collection-success'));
            
        }
        else if (result && result.status===400){
            message.error(props.t('cannot-add-already-existing'));
        }
        props.setLoading(false);        
    };

    const fetchCollections = async() => {
            props.setLoading(true);
            let result = await findAllCollections();
            if (result && result.status===200){
                setCollectionData(result.data)
            }
            props.setLoading(false);
    };

    useEffect(()=>{
        fetchCollections()
    },
    // eslint-disable-next-line
    [])
    return (
      <CollectionModal loading={props.loading} BookIcon={BookIcon} title={props.title} t={props.t} crossIcon={crossIcon} collectionPopup={collectionPopup} collectionData={collectionData} selectedCollection={selectedCollection} NotesData={props.NotesData} onClose={props.onClose} handleCollectionClick={handleCollectionClick} AddNewCollection={AddNewCollection} onSaveInCollections={onSaveInCollections} />
    )
}
