import React from "react";

import { Link } from "react-router-dom";



function History(props) {
  return (
    <aside className={`side-menu   ${props.selectedMenu === 'history' ? "side-menu--active" : ""}`} >
      <div className="side-menu__overlay" onClick={() => props.onOverlayClick()} />

      <div className={` darkBackground side-menu__content d-flex flex-column ${props.selectedMenu === 'history' ? "side-menu__content--active" : ""}`}>
        <div className={`darkLightBackground side-menu-header`}>
          <span className={`text-white-black-theme `}>{props.t('history')}</span>
          <img className="" alt="" src={props.crossIcon}
            onClick={() => props.onOverlayClick()}
          />
        </div>

        <div  className=" scroll-it-history px-2 ">
          <div>
            {props.historyList && [...props.historyList].reverse().map((list, index) => {
              return (
                <div key={"list" + index}  onClick={() => { props.onOverlayClick(); }}>

                  <Link to={'/' + list[1] + '/' + list[2]} className="link-style">

                    <div className="p-2 py-3 d-flex flex-row border-bottom-theme cursor_pointer">
                      <img
                        className="size-16-16 mt-1 cursor_pointer"
                        alt="..."
                        src={props.historyIcon}
                      />
                      <div className="d-flex flex-column cursor_pointer px-3">
                        <label className={` text-white-black-theme font-size-16-400  m-0 ml-2 px-0 cursor_pointer`}>
                          {list[0]}
                        </label>
                        <label className={`text-white-black-theme font-size-14-400 txt-color-gray3 m-0 ml-2 px-0 cursor_pointer`} >
                          Chapter {list[1]}, Verse {list[2]}
                        </label>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}

          </div>
        </div>
      </div>
    </aside>

  )
}

export default History
