import React from "react";
import DisplayNotesUi from "../../../Ui/Home/AddSection/DisplayNotesUi";


const DisplayNotes = (props) => {
  const AddToNotes = (singleVerse) => {
    props.setNotesData(singleVerse)
    props.setIsNotesModalOpen(true);
    props.HideAddPlusPopup()
  };

  return (
    <DisplayNotesUi
      singleVerse={props.singleVerse}
      AddToNotes={AddToNotes}
      NotesData={props.NotesData} 
      t={props.t}
    />
  );
};

export default DisplayNotes;
