import React from "react";
import LastReadUi from "../../../Ui/Home/AddSection/DisplayLastReadUi";
import { message } from 'antd';
import { useDispatch } from "react-redux";
import { SettingsReducer } from "../../../../../redux/Setting"
const SettingActions = SettingsReducer.actions;

const LastRead = (props) => {
  const dispatch = useDispatch()
  const AddToLastRead = (chap_No, verse_No) => {
    dispatch(SettingActions.updateSettings({ lastRead: chap_No + ':' + verse_No }));
    message.success(props.t('set-last-read'));
    props.HideAddPlusPopup()
  };

  return (
    <LastReadUi
      singleVerse={props.singleVerse}
      AddToLastRead={AddToLastRead}
      t={props.t}
    />
  );
};

export default LastRead;
