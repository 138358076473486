import React from "react";

function Settings(props) {
  return (
    <aside className={`side-menu   ${props.selectedMenu === 'setting' ? "side-menu--active" : ""}`} >
          {/* // <aside className={`side-menu   ${props.shouldSetSideMenuContentActive ? "side-menu__content--active" : ""}`} > */}

      <div className="side-menu__overlay" onClick={() => props.onOverlayClick()} />
      <aside >
        {/* <div className={`darkBackground d-flex flex-column child_side-menu__content ${props.selectedMenu === 'setting' ? props.isReciterMenuVisible ? "child_side-menu__content--active" : "child_side-menu__content" : ""}`}> */}
        <div className={`darkBackground d-flex flex-column child_side-menu__content ${props.shouldChildSetSideMenuContentActive === "true" ? "side-menu__content--active": props.shouldChildSetSideMenuContentActive === "true-normal" ? "child_side-menu__content--active" : "child_side-menu__content" }`}>

          <div className=" d-flex flex-column">
            <div className={`reciter-header-background side-menu-title border-bottom-theme px-2 justify-content-between`}>
              <span className={`reciter-heading-text`}>{props.t(('select-reciter'))} </span>
              <img className="" alt="" src={props.rightArrow} onClick={props.handleReciterMenuToggle} />
            </div>
            <div className="search-icon search text-center mt-2 ">
              <input placeholder={props.t(('search_here'))} value={props.searchReciterQuery} onChange={props.handleReciterSearch} className="search-reciter-and-translation px-3 search-reciter-text" type="text" />
            </div>
            <div id="ReciterScroll" className="reciters_div px-3 py-2">
              {props.filteredReciters.length > 0 ? (
                props.filteredReciters.map((reciter) => (
                  <div key={reciter.id + "R1"} className="py-1  mb-2">
                    <input
                      className="radio-button"
                      type="radio"
                      id={`reciters${reciter.id}`}
                      value={reciter.id}
                      checked={parseInt(props.selectedReciterId) === parseInt(reciter.id)}
                      onChange={() => props.handleReciterChange(reciter.id, reciter.reciter)}
                    />
                    <label className="px-2 radio-label radio-label-sidebar" htmlFor={`reciter${reciter.id}`} onClick={() => props.handleReciterChange(reciter.id, reciter.reciter)}>
                      {reciter.reciter}
                    </label>
                  </div>
                ))
              ) : (
                <p>{props.t(('no-reciter-available'))} </p>
              )}
            </div>
          </div>
        </div>
      </aside>
      {/* <div className={`darkBackground side-menu__content d-flex flex-column ${props.selectedMenu === 'setting' && props.windowWidth <= 500 ? "side-menu__content--active" : ""}`}> */}
      <div className={`darkBackground side-menu__content d-flex flex-column ${(props.shouldSetSideMenuContentActive ==='true'  && !props.shouldChildSetSideMenuContentActive) ? "side-menu__content--active" : (props.shouldSetSideMenuContentActive ==="true-normal") ? "side-menu__content--active": ""}`}>

        <div className={`darkLightBackground side-menu-header`} >
          <span className={`text-white-black-theme `}>{props.t('settings')}</span>
          <img className="" alt="" src={props.crossIcon}
            onClick={() => props.onOverlayClick()}
          />
        </div>
        <div className="d-flex flex-column  mx-2 jutify-content-center">
          <div className="side-menu-title d-flex  justify-content-between">
            <div className="">
              <img
                className=""
                alt=""
                src={props.AIcon}
              />
              <span className="">{props.t(('show-translations'))}</span>
            </div>
            <input type="checkbox" id="translationSwitch" checked={props.showTranslation} onChange={props.TranslationToggle} />
            <label id="label-translationSwitch" htmlFor="translationSwitch">Toggle</label>
          </div>
          <div id="translateScroll" className="translation_div px-1">
            {props.tanslatorNames ? (
              props.tanslatorNames.map((translator) => (
                <div className="mb-2" key={translator.id + "T1"}>
                  <input
                    id={`tanslator${translator.id}`}
                    name="tanslator-names"
                    type="checkbox"
                    //Check if the current translator.id is present in the selectedTranslations array. If it is, it will select the translation.
                    checked={props.selectedTranslations && props.selectedTranslations.includes(translator.id)}
                    onChange={() => props.HandleTranslationCheckboxChange(translator.id)}
                  />
                  <label className="checkbox-font checkbox-font-sidebar" htmlFor={`tanslator${translator.id}`}>
                    {translator.translatorName} ({translator.language})
                  </label>
                </div>
              ))
            ) : (
              <p>{props.t(('no-translator-available'))}</p>
            )}
          </div>
        </div>
        <div className={`mt-3  px-2 d-flex justify-content-center  ${ props.isReciterMenuVisible ? "reciter-button-select-theme" : "reciter-button-theme"}`}>
          <div className=" width-90 d-flex flex-column py-2 border-top-theme border-bottom-theme align-self-center jutify-content-center">
            <div className="side-menu-title w-100 cursor_pointer justify-content-between" onClick={props.handleReciterMenuToggle}>
              <div>
                <img
                  className=""
                  alt=""
                  src={props.ReciterIcon}
                />
                <span className="">{props.t(('select-reciter'))}</span>
              </div>
              <img
                id="side-menu-arrow"
                className=""
                alt=""
                src={props.RightArrow}
              />
            </div>
            <div className="show-on-small m_l-reciter ">
              <span className="reciter_name mb-3 ">{props.selectedReciterName}</span>
            </div>
          </div>
        </div>
        <div className="height__100 width-90 d-flex flex-column  py-2  px-2   border-bottom-theme align-self-center jutify-content-center">
          <div className="side-menu-title d-flex w-100 justify-content-between">
            <div className="">
              <img className="" alt="" src={props.JumpTo} />
              <span className="">{props.t(('jump-to'))}</span>
            </div>
            <input type="checkbox" id="JumpToVerse" checked={props.jumpToVerse} onChange={props.JumpToVerseToggle} />
            <label id="label-JumpToVerse" htmlFor="JumpToVerse">Toggle</label>
          </div>
          <div className="span-container  m_l-jump">
            <span className="reciter_name mb-3">
              {props.t(('jump-to-hint'))}
            </span>
            </div>
        </div>

      </div>
    </aside>

  )
}

export default Settings
