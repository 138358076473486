import React from 'react'
import Loading from '../Loading';
import { Link } from "react-router-dom";
import DeleteVerseConfirmationForCollection from '../../../Modals/Logic/DeleteVerseCollectionConfirmation'
import CollectionsModal from '../../../Modals/Logic/Collections'
import AddNewCollectionModal from '../../../Modals/Logic/AddNewCollection'
import FooterCode from "../../Logic/FooterLogic"


export default function CollectionDetailsUi(props) {
    return (
        <div className="width100s">
            <div className='height-custom py-5 page-wrap'>
                <div className="d-flex flex-column p-4">
                    {props.loading && <Loading />}
                    <div className="collection-detail-container  w-100 mt-3">
                        <div className="collection-detail-header  px-4 w-100">
                            <div className="d-flex  align-items-center ">
                                <img
                                    className="icon_9_15"
                                    alt="..."
                                    src={props.CollectionPopup}
                                />
                                <label
                                    className="font-size-16-500 px-3 p-0 m-0"
                                    style={{ color: props.collectionDetail.collectionColor }}
                                >
                                    {props.collectionDetail.collectionName}
                                </label>
                            </div>
                            {props.collectionDetail.collectionVerse && Array.isArray(props.collectionDetail.collectionVerse) && (
                                <label className="font-size-14-400-dark-light mb-0 px-4 ml-1">{props.collectionDetail.collectionVerse.length} verses</label>
                            )}
                        </div>
                        <div className="collection-detail-controls px-2 w-100 darkBackground">
                            <div className="row  cursor_pointer ">
                                <div className="mb-2" >
                                    <input
                                        className='collection-details-checkbox py-3'
                                        id={`Showtranslation`}
                                        name="ShowtranslationName"
                                        type="checkbox"
                                        disabled={props.selectedTranslations.length === 0} checked={props.selectedShowTranslation === true}
                                        onChange={() => props.ShowTranslation()}
                                    />
                                    <label className="checkbox-font-detail-collection " htmlFor={`Showtranslation`}>
                                        {props.t('show-translations')}
                                    </label>
                                </div>

                            </div>
                            <div className="row m-01 p-1 cursor_pointer ">
                                <div className="mb-2" >
                                    <input
                                        className='collection-details-checkbox'
                                        id={`Shownotes`}
                                        name="ShownotesName"
                                        type="checkbox"
                                        checked={props.selectedShowNotes === true}
                                        onChange={() => props.ShowNotes()}
                                    />
                                    <label className="checkbox-font-detail-collection " htmlFor={`Shownotes`}>
                                        {props.t('show-notes')}
                                    </label>
                                </div>

                            </div>
                            <div className="row m-02 p-1  cursor_pointer ">
                                <div className="mb-2">
                                    <input
                                        className="radio-button-collection-details"
                                        type="radio"
                                        id={`before-verse`}
                                        value={'1'}
                                        checked={props.selectedBeforAfter === '1'}
                                        onChange={() => props.HandleBeforAfter('1')}
                                    />
                                    <label className="px-2 radio-label " htmlFor={`before-verse`} onClick={() => props.HandleBeforAfter('1')}>
                                        {props.t('before-verse')}
                                    </label>
                                </div>

                            </div>
                            <div className="row m-03 p-1  cursor_pointer">
                                <div className="mb-2">
                                    <input
                                        className="radio-button-collection-details"
                                        type="radio"
                                        id={`after-verse`}
                                        value={'2'}
                                        checked={props.selectedBeforAfter === '2'}
                                        onChange={() => props.HandleBeforAfter('2')}
                                    />
                                    <label className="px-2 radio-label" htmlFor={`after-verse`} onClick={() => props.HandleBeforAfter('2')}>
                                        {props.t('after-verse')}
                                    </label>
                                </div>

                            </div>
                        </div>
                        {props.collectionDetail.collectionVerse && props.collectionDetail.collectionVerse.map((singleCollection, index) => {
                            return (
                                <div key={index + 'detailCollection'} className="py-2">
                                    <div className="d-felx flex-column">
                                        <div className="collection-detail-verse-title   px-4 w-100">
                                            <label className="font-size-14-400 m-0">{singleCollection.verse.chapter.alphaArabicName} ({singleCollection.verse.chapterNo}:{singleCollection.verse.verseNo})</label>

                                            <div className="actions-section-collection">
                                                <Link to={`/${singleCollection.verse.chapterNo}/${singleCollection.verse.verseNo}`}>
                                                    <img
                                                        className="size-20-25 mr-3"
                                                        alt="..."
                                                        data-bs-toggle="tooltip"
                                                        title="Jump to verse"
                                                        src={props.Jumpto}
                                                    />
                                                </Link>
                                                <img
                                                    className="size-20-25 mr-3"
                                                    alt="..."
                                                    onClick={() => props.MoveToCollection(singleCollection.verse.id, singleCollection.id)}
                                                    data-bs-toggle="tooltip"
                                                    title="Move to other collection"
                                                    src={props.MoveTo}
                                                />
                                                <img
                                                    className="size-25-20 "
                                                    onClick={() => props.DeleteCollection(singleCollection.id)}
                                                    alt="..."
                                                    data-bs-toggle="tooltip"
                                                    title="Delete"
                                                    src={props.Bin}
                                                />
                                            </div>
                                        </div>
                                        <div className="px-4 py-2 ">
                                            {props.selectedBeforAfter === '2' &&
                                                <div className="w-100 py-4 text-right text-white-black-theme mb-4"
                                                    style={{ fontSize: props.arabicFontSize + "rem", }}
                                                >
                                                    {singleCollection.verse[props.scriptStyle]}
                                                </div>
                                            }
                                            {props.selectedShowTranslation && singleCollection.verse.translations[0] && singleCollection.verse.translations[0].length >= 1 &&
                                                <div className={`w-100  d-flex flex-column   ${props.selectedBeforAfter === '2' && 'border-top-line'}  py-5`}>
                                                    {singleCollection.verse.translations.map((collectionTranslations, index2) => (
                                                        <div key={index2}>
                                                            {collectionTranslations.map((singlecollectionTranslations, index3) => (
                                                                <div key={index3}>
                                                                    <label className="translation-heading-collection-details" style={{ fontSize: props.translationFontSize + "rem" }}>{singlecollectionTranslations.translator.translatorName}</label>
                                                                    <div className="w-100 d-flex flex-column">
                                                                        <label className="m-0 w-100 py-2" style={{ fontSize: props.translationFontSize + "rem" }}>
                                                                            {singlecollectionTranslations.translationText}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}

                                                </div>
                                            }
                                            {props.selectedShowNotes && singleCollection.verse.notes.length>0 &&
                                                <div className={`w-100  d-flex flex-column  ${((props.selectedShowTranslation && props.selectedTranslations.length > 0) || props.selectedBeforAfter === '2') && 'border-top-line'}  py-4`}>
                                                    <label className="notes-heading-collection-details">{props.t('notes')}:</label>
                                                    <div className=" w-100  d-flex flex-column   ">
                                                        <span
                                                            className="m-0  w-100 text-white-black-2-theme translation_text py-2 "
                                                            style={{ fontSize: 20 }}
                                                        >
                                                            {singleCollection.verse.notes[0].note}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            {props.selectedBeforAfter === '1' &&
                                                <div className={`w-100 py-4 text-right text-white-black-theme   mb-4 ${((props.selectedShowTranslation && props.selectedTranslations.length > 0) || props.selectedShowNotes) ? 'border-top-line' : ''}  py-4`}
                                                    style={{ fontSize: props.arabicFontSize + "rem", }}
                                                >


                                                    {singleCollection.verse[props.scriptStyle]}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            );
                        })
                        }
                    </div>
                    {props.isDeleteCollectionVerseModalOpen && <DeleteVerseConfirmationForCollection t={props.t} setDeleted={props.setDeleted} deleteVerseIdofCollection={props.deleteVerseIdofCollection} isDeleteCollectionModalOpen={props.isDeleteCollectionModalOpen} setIsDeleteCollectionModalOpen={props.setIsDeleteCollectionModalOpen} onCloseDelete={props.onCloseDelete} setLoading={props.setLoading} />}
                    {props.isCollectionModalOpen && <CollectionsModal title={props.t('move-to-collection')} t={props.t} setDeleted={props.setDeleted} NotesData={[props.deleteVerseIdofCollection, props.deleteVerseCollectionId, 'Delete']} setIsCollectionModalOpen={props.setIsCollectionModalOpen} isCollectionModalOpen={props.isCollectionModalOpen} onClose={props.onCloseMoveToCollection} setLoading={props.setLoading} setIsAddNewCollectionModalOpen={props.setIsAddNewCollectionModalOpen} />}
                    {props.isAddNewCollectionModalOpen && <AddNewCollectionModal t={props.t} NotesData={props.notesData} setIsAddNewCollectionModalOpen={props.setIsAddNewCollectionModalOpen} onClose={props.closeAddNewCollectionModal} setLoading={props.setLoading} clickedCollection={[]} />}

                </div>
            </div>
            <FooterCode />

        </div>
    );
}