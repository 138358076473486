import React from 'react'
import SearchLogo from "../../../../assets/images/search.svg"
import PlusWhite from "../../../../assets/images/collection/plus_white.svg"
import Loading from '../Loading';
import AddNewCollectionModal from '../../../../components/Modals/Logic/AddNewCollection'
import ThreeDotsTooltip from '../../Logic/AllCollections/ToolTip/ThreeDotsToolTip'
import DeleteConfirmationForCollection from '../../../../components/Modals/Logic/DeleteCollectionConfirmation'
import { Link } from "react-router-dom";
import FooterCode from "../../Logic/FooterLogic"

export default function AllMyCollectionsUi(props) {
    return (
        <div className="width100s">
        <div className='height-custom padding-top-35 page-wrap'>
            <div className="d-flex flex-column " onScroll={props.handleScroll}>

                {props.loading && <Loading />}
                <div className="d-flex row-for-small mb-2 justify-content-end-or-center p-l-r"> {/* align-items-center*/}
                    <div className="d-flex col-lg-3 col-md-3 col-sm-12 justify-content-end-or-center align-items-end margin-left-2">
                        <div className=" navbar_search d-flex height_2_point_1_rem">
                            <input
                                className="search-input placehlder-margin-left bg-transparent border-0 py-1 px-2 w-100 font-size-14-400 SF_UI_Text"
                                value={props.searchKeyword}
                                onChange={(event) => props.setSearchKeyword(event.target.value)}
                                placeholder={props.t('search')}
                                type="text"
                            >
                            </input>
                            <img className="search24x24 mx-2 p-1"
                                alt="..."
                                src={SearchLogo}
                            />

                        </div>
                    </div>
                    <div className="d-flex-for-small col-lg-2 col-md-3 col-sm-12 justify-content-end-or-center pading-left-2 w-18">
                        <button type="button" className="bg-color-green_add_collection align-self-center SF_UI_Text" onClick={() => props.AddNewCollection()}>
                            <img alt='' className=" align-self-center mr-2 mb-1" src={PlusWhite} /> {props.t('add-new-collection')} </button>
                    </div>
                </div>

               
                {props.filteredCollections && props.filteredCollections.length > 0 ?
                    <div className='table-bssss'>
                     <table id="dtBasicExample" cellSpacing={0} className="margin-collection-left-right rounded-corners table-responsive overflow-scroll mt-2">
                        <tr>
                            <th className='' >{props.t('name')} <img onClick={() => props.SortCollection(props.sortType==='True'?'False':'True')} alt='' className='float-end cursor-pointer' src={props.ArrowUpDown} /></th>
                            <th className=''>{props.t('no-of-vere')}</th>
                            <th></th>
                        </tr>

                        {props.filteredCollections && props.filteredCollections.slice(
                            (props.currentPage - 1) * props.recordsPerPage,
                            props.currentPage * props.recordsPerPage
                        ).map((singleCollection, index) => {

                            return (
                                <tr 
                                draggable="true"
                                onDragStart={(e) => props.handleDragStart(e,index)}
                                onDragOver={(e) => props.handleDragOver(e,index)}
                                className="" key={index + 'col'}>

                                    <td className="collection-table collection-title-name w-33 align-items-center border-0" style={{ color: singleCollection.collectionColor }} >
                                        <img className="mr-3 ml-2 py-2 cursor-grab" alt="..." src={props.CollectionPopup} />
                                        {singleCollection.collectionName}
                                    </td>

                                    <td className="collection-table font-size-14-400 w-33 align-items-center border-0">
                                        {singleCollection.verseCount} Verse
                                    </td>

                                    <td className="collection-table  justify-content-end align-items-center ">
                                        <img className="icon_15_17 mr-2 float_right ThreeDotImgClick-container cursor-pointer" alt="..." src={props.ThreeDots} onClick={() => props.ThreeDotsClick(index, singleCollection)} aria-describedby="popup-271" />
                                        <Link to={singleCollection.verseCount >= 1 ? `/collection-detail/${singleCollection.id}/` : '#'}
                                            onClick={() => props.handleClick(singleCollection.verseCount)}
                                        >
                                            <img className="icon_24_15 mr-3 float_right cursor-pointer" alt="..." src={props.HalfEye} data-bs-toggle="tooltip" title="View Collection" />
                                        </Link>
                                    </td>
                                    {props.isThreeDotOpen && index === props.activeImageIndex && <ThreeDotsTooltip index={props.activeImageIndex} loading={props.loading} setLoading={props.setLoading} t={props.t}
                                        isThreeDotOpen={props.isThreeDotOpen} setIsThreeDotOpen={props.setIsThreeDotOpen}
                                        activeImageIndex={props.activeImageIndex} setActiveImageIndex={props.setActiveImageIndex}
                                        clickedCollection={props.clickedCollection} setIsAddNewCollectionModalOpen={props.setIsAddNewCollectionModalOpen}
                                        isAddNewCollectionModalOpen={props.isAddNewCollectionModalOpen} onClose={props.onClose} isDeleteCollectionModalOpen={props.isDeleteCollectionModalOpen} setIsDeleteCollectionModalOpen={props.setIsDeleteCollectionModalOpen}
                                    />}
                                </tr>
                            );
                        })

                        }
                        {props.pageNumbers.length > 1 &&
                            <tr className="darkBackground "  ><td className="darkBackground"></td>
                                <td className="darkBackground"></td>
                                <td className="darkBackground px-4" align="right">
                                    {props.pageNumbers.map((pageNumber) => {
                                        const totalPages = props.pageNumbers.length;
                                        const currentPage = props.currentPage;
                                        const pagesToShow = 2;
                                        if (
                                            pageNumber <= 4 ||
                                            pageNumber === totalPages ||
                                            (pageNumber >= currentPage - pagesToShow &&
                                                pageNumber <= currentPage + pagesToShow)
                                        ) {
                                            return (
                                                <span
                                                    key={pageNumber}
                                                    onClick={() => props.handlePageChange(pageNumber)}
                                                    className={`m-1 table-pagination cursor-pointer ${pageNumber === currentPage ? 'active-page' : ''
                                                        }`}
                                                >
                                                    {pageNumber}
                                                </span>
                                            );
                                        }
                                        if (
                                            pageNumber === 5 ||
                                            pageNumber === totalPages - 1 ||
                                            pageNumber === currentPage + pagesToShow + 1
                                        ) {
                                            return <span key={pageNumber}>...</span>;
                                        }
                                        return null;
                                    })}
                                </td>
                            </tr>
                        }

                    </table>
                    </div>
                    : props.loading===false &&
                    <div className=" padding-collection-top padding-collection-left-right" >
                        <div className="collectionsContainer darkBackground-collection d-flex flex-column align-items-center justify-content-center">
                            <img alt='' className='mt-7' src={props.BookIcon} />
                            <label className="font-size-16-600 text-uppercase text-white-black-theme cursor_pointer py-0 mt-4">
                                {props.collections ? (props.t('no-collection-found')) : ''}
                            </label>
                            <label className="font-size-12-400 text-white-black-2-theme cursor_pointer py-0 my-0 ml-2 mr-2 text-center">
                                {props.collections ? (props.t('no-collection-found_msg')) : ''}
                            </label>

                            <div className="justify-content-center align-items-center  py-3">
                                <button type="button" className="bg-color-green_add_collection-small align-self-center SF_UI_Text" onClick={() => props.AddNewCollection()}>
                                    <img alt='' className=" align-self-center mr-2 mb-1" src={PlusWhite} /> {props.t('add-new-collection')} </button>
                            </div>
                        </div>

                    </div>
                }
               

                {props.isAddNewCollectionModalOpen && <AddNewCollectionModal clickedCollection={props.clickedCollection} setClickedCollection={props.setClickedCollection} t={props.t} setIsAddNewCollectionModalOpen={props.setIsAddNewCollectionModalOpen} onClose={props.onClose} setLoading={props.setLoading} />}
                {props.isDeleteCollectionModalOpen && <DeleteConfirmationForCollection t={props.t} clickedCollection={props.clickedCollection} isDeleteCollectionModalOpen={props.isDeleteCollectionModalOpen} setIsDeleteCollectionModalOpen={props.setIsDeleteCollectionModalOpen} onCloseDelete={props.onCloseDelete} setLoading={props.setLoading} />}

            </div>
        </div>
        <FooterCode />
        </div>
    );
}