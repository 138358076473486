import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SettingsReducer } from "../../../redux/Setting"
import { useEffect } from "react";
import { auth } from "../../../apis/firebase_authentications";
import { message } from 'antd';
import CrossIcon from '../../../assets/images/cross_black.svg'
import CrossIconWhite from '../../../assets/images/cross_white.svg'
import RightArrowFull from '../../../assets/images/right_arrow_full.svg'
import RightArrowFullWhite from '../../../assets/images/right_arrow_full_white.svg'
import AIconLight from '../../../assets/images/AIcon.svg'
import ReciterIconLight from '../../../assets/images/reciter.svg'
import RightArrowLight from '../../../assets/images/right_arrow.svg'
import JumpToLight from '../../../assets/images/jump_to.svg'
import AIconDark from '../../../assets/images/AIconPink.svg'
import ReciterIconDark from '../../../assets/images/reciterPink.svg'
import RightArrowDark from '../../../assets/images/right_arrowPink.svg'
import JumpToDark from '../../../assets/images/jump_toPink.svg'

import SettingsUi from '../UI/SettingsUi'
const SettingActions = SettingsReducer.actions;

function Settings(props) {
  const dispatch = useDispatch()
  const [searchReciterQuery, setSearchReciterQuery] = useState('');
  const [isReciterMenuVisible, setIsReciterMenuVisible] = useState(false);
  const tanslatorNames = useSelector((state) => state.chapters.translators);
  const reciterNames = useSelector((state) => state.chapters.reciters);
  const jumpToVerse = useSelector((state) => state.settings.jumpToVerse);
  const showTranslation = useSelector((state) => state.settings.showTranslation);
  const selectedTranslations = useSelector((state) => state.settings.selectedTranslations);
  const selectedReciterId = useSelector((state) => state.settings.selectedReciterId);
  const selectedReciterName = useSelector((state) => state.settings.selectedReciterName);
  const userTheme = useSelector((state) => state.settings.userTheme);
  const crossIcon = userTheme === 'Dark' ? CrossIconWhite : CrossIcon;
  const rightArrow = userTheme === 'Dark' ? RightArrowFullWhite : RightArrowFull;
  const AIcon = userTheme === 'Dark' ? AIconDark : AIconLight;
  const ReciterIcon = userTheme === 'Dark' ? ReciterIconDark : ReciterIconLight;
  const RightArrow = userTheme === 'Dark' ? RightArrowDark : RightArrowLight;
  const JumpTo = userTheme === 'Dark' ? JumpToDark : JumpToLight;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs once on mount



  useEffect(() => {
  }, [selectedReciterId, selectedReciterName, jumpToVerse, showTranslation, selectedTranslations]);


  const handleReciterMenuToggle = () => {
    setIsReciterMenuVisible(!isReciterMenuVisible);
  };

  const handleReciterChange = (reciterid, recitername) => {
    dispatch(SettingActions.updateSettings({ selectedReciterId: reciterid }));
    dispatch(SettingActions.updateSettings({ selectedReciterName: recitername }));
  };

  const handleReciterSearch = (event) => {
    setSearchReciterQuery(event.target.value);
  };

  const filteredReciters = reciterNames.filter(
    (reciter) =>
      reciter.reciter.toLowerCase().includes(searchReciterQuery.toLowerCase())
  );

  const TranslationToggle = () => {
    dispatch(SettingActions.updateSettings({ showTranslation: !showTranslation }));
  }

  const HandleTranslationCheckboxChange = (translatorId) => {
    //Check if the current translator.id is present in the selectedTranslations array. If it is , then remove the id from array ( Because this time checbox is clicked for unchecking this id)
    if (selectedTranslations.includes(translatorId)) {
      //  Creates a new array updatedSelectedTranslations by filtering out the translatorId that needs to be removed as already selected.
      const updatedSelectedTranslations = selectedTranslations.filter(id => id !== translatorId);
      dispatch(SettingActions.updateSettings({ selectedTranslations: updatedSelectedTranslations }));
    } else {
      if (!auth.currentUser && selectedTranslations.length === 2) {
        message.warning(props.t('more-than-2-translations'));
        return
      }
      //Creates a new array updatedSelectedTranslations by spreading the existing selectedTranslations array and adding the translatorId at the end.
      const updatedSelectedTranslations = [...selectedTranslations, translatorId];
      dispatch(SettingActions.updateSettings({ selectedTranslations: updatedSelectedTranslations }));
    }
  };

  const JumpToVerseToggle = () => {
    dispatch(SettingActions.updateSettings({ jumpToVerse: !jumpToVerse }));
  }
  
  const onOverlayClick = () => {
    props.handleClickAway_CloseMenu()
    setIsReciterMenuVisible(false)
  }
  console.log("props.selectedMenu:",props.selectedMenu)
  console.log("props.windowWidth:",windowWidth)
  console.log("props.isReciterMenuVisible:",isReciterMenuVisible)



  const shouldSetSideMenuContentActive = (props.selectedMenu === 'setting' && windowWidth <= 400 && !isReciterMenuVisible) ? 'true' : (props.selectedMenu === 'setting' && windowWidth > 400 && !isReciterMenuVisible)? 'true-normal'  : (props.selectedMenu === 'setting' && windowWidth > 400 && isReciterMenuVisible)? 'true-normal' : false;
  const shouldChildSetSideMenuContentActive = (props.selectedMenu === 'setting'  && isReciterMenuVisible && windowWidth <= 400) ? 'true' : (props.selectedMenu === 'setting'  && isReciterMenuVisible && windowWidth >= 400) ? "true-normal":false;

  console.log("shouldSetSideMenuContentActive:",shouldSetSideMenuContentActive)
  return (
    <SettingsUi t={props.t} closeSettings={props.closeSettings} selectedMenu={props.selectedMenu} searchReciterQuery={searchReciterQuery} isReciterMenuVisible={isReciterMenuVisible}
    tanslatorNames={tanslatorNames} jumpToVerse={jumpToVerse} showTranslation={showTranslation} selectedTranslations={selectedTranslations}
    selectedReciterId={selectedReciterId} selectedReciterName={selectedReciterName} handleReciterMenuToggle={handleReciterMenuToggle}
    handleReciterChange={handleReciterChange} handleReciterSearch={handleReciterSearch} filteredReciters={filteredReciters}
    TranslationToggle={TranslationToggle} HandleTranslationCheckboxChange={HandleTranslationCheckboxChange}
    JumpToVerseToggle={JumpToVerseToggle} onOverlayClick={onOverlayClick} crossIcon={crossIcon} rightArrow={rightArrow}
    AIcon={AIcon} ReciterIcon={ReciterIcon} RightArrow={RightArrow} JumpTo={JumpTo} windowWidth={windowWidth}
    shouldSetSideMenuContentActive={shouldSetSideMenuContentActive} shouldChildSetSideMenuContentActive={shouldChildSetSideMenuContentActive}

    />
  )
}

export default Settings
