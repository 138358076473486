import React, { useState,useEffect } from 'react'
import { message } from 'antd';
import { addToNotes } from '../../../apis/PostData'
import {
    FindAllTranslations,
  } from "../../../apis/FetchData";

import Notes from "../Ui/NotesUi";

export default function NotesModal(props) {   
    const [notesText, setNotesText] = useState(props.NotesData?.notes?.[0]?.note || props.NotesData?.note || '' );     
    const [notesIdToUpdate, setNotesIdToUpdate] = useState((notesText) && ((props.NotesData?.notes?.[0]?.id || props.NotesData?.id) || '') );


    const fetchNotesData = async() => {
        // if (!notesText && !notesIdToUpdate)
        if (!notesText && !notesIdToUpdate)
        {
            props.setLoading(true);
            let result = await FindAllTranslations(props.NotesData.chapterNo, props.NotesData.verseNo, props.selectedReciterId, props.selectedTranslations);
            result=result.data.verse[0].notes
            if (result.length>0){
                console.log("result result result:",result)
                setNotesIdToUpdate(result[0].id)
                setNotesText(result[0].note)
            }
            props.setLoading(false);
        }
    };

    useEffect(()=>{
        fetchNotesData()
    },
    // eslint-disable-next-line
    [])

    const onDeleteNotes = () => {
        props.setDeleteNotesId(notesIdToUpdate) // set here the delete id
        props.setIsDeleteConfirmationsModalOpen(true);
        props.setIsNotesModalOpen(false);
    };       



    const onSaveNotes = async () => {
        if (!notesText) {
            message.error(props.t('enter-your-notes'));
            return
        }
        props.setLoading(true);
        let notesId;
        if (props.NotesData.notes?.length > 0) {
            console.log("in if")
            notesId = props.NotesData.notes[0].id
        }
        else if(notesIdToUpdate)
        {
            console.log("in else if:",notesIdToUpdate)
            notesId = notesIdToUpdate
        }
        else
        {
            console.log("in else")
            notesId = false
        }
        let result = await addToNotes(props.NotesData.id, props.NotesData.chapterNo, notesText, notesId);
        props.setLoading(false);
        if (props.previousPage==='AllNotes' && result && result.status === 200 ){
            message.success(props.t('update-note-msg'))
            props.setDeletedSuccess(true)
        }
        else{
        const verseToUpdate = props.allVerses.find((verse) => verse.id === props.NotesData.id); 
        if (result && result.status === 201 && verseToUpdate) {
            //add the logic of adding the notes badge infront of verse here when adding a new note and page is note refreshed. Add a temporary list in the allverses array which will be removed when page is refreshed or data is fetched again.
            verseToUpdate.temporaryNotes =[{ 'note': notesText, 'verseId': props.NotesData.id}];
            message.success(props.t('add-note-msg'));
        }
        else if (result && result.status === 200 && verseToUpdate){
            if(notesId && verseToUpdate.notes.length>0){
                verseToUpdate.notes[0].note= notesText
            }  
            else if(notesId && verseToUpdate.notes.length===0)
            {
                verseToUpdate.notes.push({ 'id': notesId, 'note': notesText, 'verseId': props.NotesData.id});

            }
            message.success(props.t('update-note-msg'))
            }
        }
        props.setIsNotesModalOpen(false)
        props.setLoading(false);

    };


    const handleNotesChange = (event) => {
        setNotesText(event.target.value);
    };
   
    return (
        <Notes  
        t={props.t} NotesData={props.NotesData}  chapters={props.chapters}
        onSaveNotes={onSaveNotes} onDeleteNotes={onDeleteNotes} 
        onClose={props.closeNotesModal} notesText={notesText} 
        handleNotesChange={handleNotesChange}  notesIdToUpdate={notesIdToUpdate}   
        pages={props.previousPage}
         />

    )
}
