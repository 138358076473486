import React from 'react'
import CrossWhite from "../../../assets/images/cross_white.svg";


export default function BookmarkDeleteModal(props) {

    return (
        <div className="modal show" onClick={(e) => e.target.classList.contains('modal') && props.onClose()} tabIndex="-1">
            <div className="dark__overlay" onClick={(e) => e.target.classList.contains('dark__overlay') && props.onClose()} />
            <div className="modal-dialog modal-sm modal-dialog-centered ">
                <div className={`darkBackground modal-content ModalConfirmationWidth dark-mode-modal-border  `}>
                    <div className="modal-header  dark-mode-modal-border-bottom ">
                        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '3rem' }}>
                            <img className=' model-cross-icon align-self-center '
                                alt='' onClick={() => props.onClose()} src={CrossWhite} />
                        </div>
                    </div>
                    <div className="modal-body " >
                        <div className="d-flex flex-column">
                            <label className={`text-white-black-theme text-center font-size-18-600 `} >
                                {props.t('remove-bookmark-msg')}
                            </label>
                            
                            <div className=" flex-container custom-column-yes-no mt-4 justify-content-center align-items-center w-100 h-100 ">
                                <div onClick={() => props.RemoveBookmark(props.DeleteBookmarkId)} className={`model_button_yes_no darkBackground model_button cursor_pointer text-uppercase`} >
                                    <label className={`text-white-black-theme`} >{props.t('yes')}</label>
                                </div>
                                <div onClick={() => props.onClose()} className={`model_button_yes_no darkBackground model_button cursor_pointer text-uppercase mx-2  `}>
                                    <label className={`text-white-black-theme`} style={{ color: "rgb(106, 106, 106)" }}>{props.t('no')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
