import  React  from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import google from '../../../assets/images/googleColored.svg'
import apple from '../../../assets/images/apple.svg'
import telephone from '../../../assets/images/phoneColored.svg'


import Loading from './Loading';

function Login(props) {
  return (
    <>
      <div id="myRecaptcha"></div>
      {!props.imageLoaded && <Loading />}
      { props.isModalHide ? (
        <>               
         <div  onClick={(e) => e.target.classList.contains('modal') && props.onHideLoginModal()} id="loginModal" aria-labelledby="login Modal" className={`modal fade ${props.isModalHide ? '' : 'show'}`} tabIndex="-1" role="dialog" style={{ display: props.isModalHide  ? 'none' : 'block' }}>         
           <div className="modal-dialog modal-dialog-centered mw-50 modal-login ">
             <div className="modal-content bg-transparent border-0 modal-height">
              <div className="modal-body p-0 d-flex w-100">
                {/* <div className="rounded-modal-left login_image w-100 hide-on-mobile" /> */}
                <div className={`rounded-modal-left w-100 hide-on-mobile`}
                style={{
                  backgroundImage: `url(${props.LoginImage})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  width: '442px'
                }}
                />

                <div id="Login_Modal" className={`rounded-modal-right px-3 py-2 w-100  flex-column justify-content-center darkBackground `} >
                  <div className="" align="right" >
                    <img alt="" src={props.crossIcon } className="cursor-pointer "  data-bs-dismiss="modal" id="closeBtn" onClick={props.onHideLoginModal} aria-label="Close" />
                  </div>
                  <h3 className={`SF_UI_font_bold mx-4 text-uppercase login-heading text-white-black-theme `} >{props.t('login')}</h3>
                  <div className="mx-4">
                    <span className="logindetails"> {props.t('login-detail')}  </span>
                  </div>
                  {props.activeScreen === 'Screen1' && (
                    <div id="LoginScreen1" >
                      <div className="row my-3 extra-margin">
                        <div className="d-grid gap-2 col-10 mx-auto my-2 ">
                          <button onClick={props.GoogleAuth} className="btn  btn-primary social-btn-google  loginButtons social-button-text text-black" type="button" >
                            <span className="mr-point7">
                              <img alt={google} className="mx-2 icons googleLogo  " src={google} />

                            </span >
                            
                           <span className="social-button-text "> {props.t('sign-in-with-google')}</span>
                          </button>
                        </div>

                        <div className="d-grid gap-2 col-10 mx-auto my-2">
                          <button onClick={props.AppleAuth} className="btn btn-primary text-white social-btn-apple loginButtons social-button-text" type="button">
                            <span className="mr-point7 ">
                              <img alt={apple} className="mx-2 icons googleLogo" src={apple} />
                            </span>
                            {props.t('sign-in-with-apple')}
                          </button>
                        </div>
                        <div className="d-grid gap-2 col-10 mx-auto my-2">
                          <button onClick={props.ShowPhoneModal} className="btn btn-primary loginButtons text-white social-btn-phone social-button-text" type="button">
                            <span className="mr-point7">
                              <img alt={telephone} className="mx-2 icons phoneLogo" src={telephone} />
                            </span>
                            {props.t('sign-in-with-phone')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Screen2 */}
                  {props.activeScreen === 'Screen2' && (
                    <div id="LoginScreen2Phone">
                      <div className="row" style={{ "marginTop": "20px" }}>
                        <div id="captchaphoneid" >
                          <div className="d-grid gap-2 col-10 mx-auto my-2 mb-3">
                            <PhoneInput country={props.countryCodePhone} className="react-tel-input " enableSearch={true} value={props.mynumber}
                              onChange={(number) => props.setNumber(number)}
                            />
                          </div>

                          <div className="d-grid gap-2 col-10 mx-auto my-2">
                            <button className="btn btn-info text-white bbutton" type="button" id="sendOtpButton" onClick={props.signin}> {props.t('send-otp')}
                            </button>
                          </div>
                          <div className="d-grid gap-2 col-10 mx-auto my-3">
                            <button onClick={props.ShowSocialModal} className="btn btn-info text-white" type="button">
                              {props.t('cancel')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Screen3 */}
                  {props.activeScreen === 'Screen3' && (

                    <div id="LoginScreen3_OTP"  >
                      <div className="row" style={{ "marginTop": "20px" }}>
                        <div className="d-grid gap-2 col-10 mx-auto my-2"  >
                          <input type="text" className="form-control" required placeholder={props.t('enter-your-otp')}
                            onChange={(e) => { props.setOtp(e.target.value) }} value={props.otp}
                          ></input>
                          <div id="otp-error-msg" >
                          </div>

                        </div>
                        <div className="d-grid gap-2 col-10 mx-auto my-2">
                          <button className="btn btn-info text-white" type="button" onClick={props.ValidateOtp}>{props.t('verify')}
                          </button>
                        </div>
                        <div className="d-grid gap-2 col-10 mx-auto my-2 ">
                          <button onClick={props.ShowSocialModal} className="btn btn-info text-white" type="button">
                            {props.t('cancel')}
                          </button>
                        </div>

                      </div>
                    </div>
                  )}


                  {/* Screen4 */}
                  {props.activeScreen === 'Screen4' && (

                    <div id="LoginScreen4_DisplayName" >
                      <div className="row" style={{ "marginTop": "20px" }}>
                        <div className="d-grid gap-2 col-10 mx-auto my-2"  >
                          <input type="text" className="form-control" required placeholder={props.t('enter-your-display-name')}
                            onChange={(e) => { props.setDisplayName(e.target.value) }} ></input>
                        </div>
                        <div className="d-grid gap-2 col-10 mx-auto my-2">
                          <button className="btn btn-info text-white" type="button" onClick={props.SaveDisplayName}>{props.t('save')}
                          </button>
                        </div>
                        <div className="d-grid gap-2 col-10 mx-auto my-2">
                          <button onClick={props.ShowSocialModal} className="btn btn-info text-white" type="button">
                            {props.t('cancel')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
        </>
        ) : null} 
    </>
  );
}

export default Login;


