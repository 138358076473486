import React from 'react'
import { message } from 'antd';
import { deleteCollection } from '../../../apis/PostData'
import DeleteCollectionConfirmation from '../../Modals/Ui/DeleteCollectionConfirmationUi'

export default function DeleteConfiramtionOfCollection(props) {
    const idToDelete=props.clickedCollection.id
    const onDeleteCollection = async () => {
        props.onCloseDelete()
        let result = await deleteCollection(idToDelete);
        if (result && result.status===200) {
            message.success(props.t('deleted-collection-success'));
        }
    };
    return (
     <DeleteCollectionConfirmation t={props.t}  onDeleteCollection={onDeleteCollection} onCloseDelete={props.onCloseDelete}/>
    )
}
