import React from 'react'

export default function Collection_Modal(props) {
    return (
        <div className="modal show" onClick={(e) => e.target.classList.contains('modal') && props.onClose()} tabIndex="-1">
            <div className="dark__overlay" onClick={(e) => e.target.classList.contains('dark__overlay') && props.onClose()} />
            <div className={` modal-dialog modal-dialog-centered  `}  >
                <div className={` modal-content dark-mode-modal-border`}  >
                    <div className={`darkBackground modal-header dark-mode-modal-border-bottom-without-blue-background`} >
                        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{ height: '3rem' }}>
                            <label className={`text-white-black-theme model-title-text text-uppercase align-self-center mright-3 `} >
                                {props.title}
                            </label>
                            <img className=' model-cross-icon align-self-center ' alt='' onClick={() => props.onClose()} src={props.crossIcon} />
                        </div>
                    </div>
                    <div className={`darkBackground modal-body `} style={{ height: "50vh", overflowY: 'scroll' }}>
                        <div className="container  px-4 py-3  collection_grid ">
                            <div className="row one-collection-per-row">
                                {(props.collectionData && props.collectionData.length) > 0 ? props.collectionData.map((collection, index) => {
                                    return (
                                        <div key={index + 'Cl'} className="col-custom-collection-3 mb-2">
                                            <div className={` collection_pop_cell   ${props.selectedCollection === collection.id ? 'selected-collection-cell' : 'not-selected-collection-cell'}  `} onClick={() => props.handleCollectionClick(collection.id)}>
                                                <div className="d-flex p-0">
                                                    <img alt='' className="" src={props.collectionPopup} />
                                                    <label className="ml-2" style={{ color: collection.collectionColor }}>
                                                        {collection.collectionName}
                                                    </label>
                                                </div>
                                                <span className={`text-white-black-theme`}>{collection.verseCount} verse</span>
                                            </div>
                                        </div>
                                    )
                                })
                                    : props.loading === false &&  props.collectionData && props.collectionData.length===0 &&
                                        <div className=" darkBackground d-flex flex-column align-items-center justify-content-center">
                                           
                                            <img alt='' src={props.BookIcon} />
                                            <label className="font-size-16-600 text-uppercase text-white-black-theme  cursor_pointer py-0 mt-4">
                                                {(props.t('no-collection-found')) }
                                            </label>
                                            <label className="font-size-12-400 text-white-black-2-theme cursor_pointer py-0 my-0 ml-1">
                                                {(props.t('no-collection-found_msg'))}
                                            </label>
                                        </div>
                                    }
                            </div>

                        </div>
                    </div>

                    <div className={`darkBackground modal-footer modal-footer-height-collection dark-mode-modal-border-top `}>
                        <div className="flex-container custom-column-yes-no justify-content-center align-items-center w-100" style={{ height: '0rem' }}>
                            <div className={`model_button_yes_no collection-modal-buttons model_button cursor_pointer px-4 py-3 mb-2 `} title={(props.t('add-new'))} onClick={() => props.AddNewCollection()}>
                                <label className={`text-white-blue-2-theme cursor_pointer`} >{(props.t('add-new'))}</label>
                            </div>
                            <div className={`model_button_yes_no collection-modal-buttons model_button cursor_pointer px-4 py-3 mb-2  ml-no`} title={(props.t('done'))} onClick={() => props.onSaveInCollections()}>
                                <label className={`text-white-blue-2-theme cursor_pointer`} style={{ "color": "rgb(106, 106, 106)" }}>{(props.t('done'))}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
