import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import lang_en from "./translations/en/lang.json";
import lang_fr from "./translations/fr/lang.json";
import lang_ar from "./translations/ar/lang.json";
import lang_zh from "./translations/zh/lang.json";
import lang_zh_TW from "./translations/zh-TW/lang.json";
import lang_ru from "./translations/ru/lang.json";
import lang_hi from "./translations/hi/lang.json";
import lang_id from "./translations/id/lang.json";
import lang_fa from "./translations/fa/lang.json";
import lang_ms from "./translations/ms/lang.json";
import lang_ur from "./translations/ur/lang.json";
import lang_bn from "./translations/bn/lang.json";
import LanguageDetector from "i18next-browser-languagedetector";


// Get the stored language from local storage
const storedLanguage = localStorage.getItem('selectedLanguage');

// Fallback to browser's default language if stored language is not available
const initialLanguage = storedLanguage || navigator.language || 'en';


i18n

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  
  
  
  .init({
    ns: ["lang", "translation"],
    defaultNS: "lang",
    whitelist: [
      "en",
      "fr",
      "ar",
      "zh-CN",
      "zh-TW",
      "ru",
      "hi",
      "id",
      "fa",
      "ms",
      "ur",
      "bn",
    ],

    fallbackLng: "en",
    debug: false,
    interpolation: { escapeValue: false },
    resources: {
      en: {
        lang: lang_en,
      },
      fr: {
        lang: lang_fr,
      },
      ar: {
        lang: lang_ar,
      },
      zh: {
        lang: lang_zh,
      },
      zh_TW: {
        lang: lang_zh_TW,
      },
      ru: {
        lang: lang_ru,
      },
      hi: {
        lang: lang_hi,
      },
      id: {
        lang: lang_id,
      },
      fa: {
        lang: lang_fa,
      },
      ms: {
        lang: lang_ms,
      },
      ur: {
        lang: lang_ur,
      },
      bn: {
        lang: lang_bn,
      },
    },

    lng: initialLanguage,

            // first checks if the language is available in the persisted data stored in the browser's local storage. If not, it falls back to using the browser's default language setting obtained from navigator.language. The resulting language value is stored in the lng variable.
  });

  export default i18n;
