import React, {useState,useEffect} from 'react'
import {  useSelector } from "react-redux";
import { message } from 'antd';
import { createNewCollection,updateCollection } from '../../../apis/PostData'
import AddNewCollection from '../Ui/AddNewCollectionUi'
import CrossBlack from "../../../assets/images/cross_black_modal.svg";
import CrossWhite from "../../../assets/images/cross_white_collectionmodal.svg";

export default function AddNewCollectionmodal(props) {
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    const userTheme = useSelector((state) => state.settings.userTheme);
    const crossIcon = userTheme === 'Dark' ? CrossWhite : CrossBlack;
    const [inputValue, setInputValue] = useState('');
    const colorOptions = [
        '#E93C71',
        '#FCA500',
        '#B137E2',
        '#F467C7',
        '#FD6A03',
        '#08C17D',
        '#AFADAE',
        '#95B901',
        '#EA84D3',
        '#03CBCB',
    ];

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };

    const SaveNewCollection = async () => {
        if(inputValue===''){
            message.error(props.t('type-collection-name'));
            return
        }
        props.setLoading(true);
        props.setIsAddNewCollectionModalOpen(false)
        const data={"collectionColor":colorOptions[selectedColorIndex],"collectionName":inputValue}
        let result = await createNewCollection(data);
        if (result && result.status===201) {
            message.success(props.t('add-collection-msg'));
        }
        else{
            message.error(props.t('error'));

        }
        props.setLoading(false);

    };
    const UpdateCollection = async () => {
        if(inputValue===''){
            message.error(props.t('type-collection-name'));
            return
        }
        props.setLoading(true);
        props.setIsAddNewCollectionModalOpen(false)
        props.setClickedCollection([])
        const data={"collectionColor":colorOptions[selectedColorIndex],"collectionName":inputValue}
        let result = await updateCollection(props.clickedCollection.id,data);
        if (result && result.status===200) {
            message.success(props.t('update-collection-msg'));
        }
        else{
            message.error(props.t('error'));

        }
        props.setLoading(false);

    };

    useEffect(() => {
       if(Object.keys(props.clickedCollection).length===8)
       {
        setInputValue(props.clickedCollection.collectionName);
        const collectionColorindex = colorOptions.indexOf(props.clickedCollection.collectionColor);
        setSelectedColorIndex(collectionColorindex)

       }},
    // eslint-disable-next-line
      [props.clickedCollection]);

    return (
       <AddNewCollection UpdateCollection={UpdateCollection} clickedCollection={props.clickedCollection} t={props.t} crossIcon={crossIcon} colorOptions={colorOptions} handleInputChange={handleInputChange} SaveNewCollection={SaveNewCollection} inputValue={inputValue} setSelectedColorIndex={setSelectedColorIndex} selectedColorIndex={selectedColorIndex} onClose={props.onClose}/>
    )
}
