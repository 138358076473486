import React from 'react';
import CollectionsModal from '../../../../../components/Modals/Logic/Collections'
import AddNewCollectionModal from '../../../../../components/Modals/Logic/AddNewCollection'
import NotesModal from '../../../../../components/Modals/Logic/Notes'
import DisplayCollection from '../../../../Views/Logic/Home/AddSection/DisplayCollection'
import DisplayNotes from '../../../../Views/Logic/Home/AddSection/DisplayNotes'
import DeleteConfirmationModal from '../../../../Modals/Logic/DeleteConfirmationNotes'
import DisplayBookmarks from '../../../../Views/Logic/Home/AddSection/DisplayBookmarks'
import DisplayLastRead from '../../../../Views/Logic/Home/AddSection/DisplayLastRead'
import DisplayLogin from '../../../../Views/Ui/Home/AddSection/DisplayLoginUi'

export default function AddToolTipUi(props) {
  return (
    <>
    <div key={props.index + 'tooltip'} className="tooltip-container " onClick={() => props.handlePlusClick(props.index)}  >
      <img title={props.t('add-new')} className="  verse_option_icon  my-2 cursor_pointer my-2" src={props.Add} alt="add" />
      {props.showTooltip && (
        <div className={`tooltip ${props.activeImageIndex === props.index ? 'active' : ''}`}  >
          <div className={`darkBackground ${props.user ? 'triangleCollection' : 'triangleLogin'} triangle `} >
            <div style={{ width: "auto !important", paddingLeft: 20, paddingRight: 20 }} align="center">
              {props.user ? (
                <>
                    <DisplayCollection t={props.t} setLoading={props.setLoading} NotesData={props.NotesData} setNotesData={ props.setNotesData} singleVerse_id={props.singleVerse.id } setIsCollectionModalOpen={props.setIsCollectionModalOpen} isCollectionModalOpen={props.isCollectionModalOpen} HideAddPlusPopup={props.HideAddPlusPopup}/>
                    <DisplayNotes t={props.t} setLoading={props.setLoading} NotesData={props.NotesData} setNotesData={ props.setNotesData} singleVerse={props.singleVerse } setIsNotesModalOpen={props.setIsNotesModalOpen} HideAddPlusPopup={props.HideAddPlusPopup}/>
                    <DisplayBookmarks t={props.t} setLoading={props.setLoading} allVerses={props.allVerses} AddToBookMark={props.AddToBookMark} RemoveBookmark={props.RemoveBookmark} setNotesData={ props.setNotesData} singleVerse={props.singleVerse }  chapters={props.chapters} HideAddPlusPopup={props.HideAddPlusPopup}/>
                    <DisplayLastRead t={props.t} setLoading={props.setLoading} AddToLastRead={props.AddToLastRead}  singleVerse={props.singleVerse } HideAddPlusPopup={props.HideAddPlusPopup} />
                </>
              ) :
                (
                  <DisplayLogin t={props.t} HideAddPlusPopup={props.HideAddPlusPopup} />
                )}
            </div>
          </div>
        </div>
      )}
    </div>
    {props.isCollectionModalOpen && <CollectionsModal title={props.t('add-collection-title')} t={props.t} NotesData={props.NotesData} setIsAddNewCollectionModalOpen={props.setIsAddNewCollectionModalOpen} isCollectionModalOpen={props.isCollectionModalOpen} setIsCollectionModalOpen={props.setIsCollectionModalOpen} onClose={props.closeCollectionModal}  loading={props.loading} setLoading={props.setLoading} />}
    {props.isAddNewCollectionModalOpen && <AddNewCollectionModal clickedCollection={false}  t={props.t} NotesData={props.notesData} setIsAddNewCollectionModalOpen={props.setIsAddNewCollectionModalOpen} onClose={props.closeAddNewCollectionModal} setLoading={props.setLoading} />}
    {props.isNotesModalOpen && props.chapters && <NotesModal  t={props.t} selectedReciterId={props.selectedReciterId} selectedTranslations={props.selectedTranslations} allVerses={props.allVerses} chapters={props.chapters} NotesData={props.notesData} setNotesData={props.setNotesData} setIsDeleteConfirmationsModalOpen={props.setIsDeleteConfirmationsModalOpen} setDeleteNotesId={props.setDeleteNotesId} setLoading={props.setLoading} setIsNotesModalOpen={props.setIsNotesModalOpen} closeNotesModal={props.closeNotesModal} />}
    {props.isDeleteConfirmationsModalOpen && <DeleteConfirmationModal t={props.t} DeleteNotesId={props.deleteNotesId} setIsDeleteConfirmationsModalOpen={props.setIsDeleteConfirmationsModalOpen} allVerses={props.allVerses} NotesData={props.notesData} onClose={props.closeDeleteConfirmationModal} setLoading={props.setLoading} />}

    </>
  )
}


